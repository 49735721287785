import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { CircularProgress, Stack, Typography } from '@mui/material';
import useData from '../../services/hooks/useData';
import useUtility from '../../hooks/useUtility';
import DataTable from '../../components/DataTable';
import DialogForm from '../../components/DialogForm';
import Page from '../../components/Page';
import { IDeliveryAreas } from '../../models';
import Title from '../../components/Title';
import Form from './Form';
import ActionsDataTable from '../../components/ActionsDataTable';
import DialogX from '../../components/DialogX';
import { useCan } from '../../hooks/useCan';

const schema = yup
  .object()
  .shape({
    distance: yup.number().min(100),
    delivery_fee: yup.number().required().nullable(),
    delivery_time: yup.number().required().nullable(),
  })
  .required();

yup.setLocale(ptForm);

export default function DeliveryAreas() {
  const {
    data,
    isLoading,
    isFetch,
    isSubmitting,
    create,
    update,
    getData,
    fetchData,
    deleteObject,
  } = useData<IDeliveryAreas>('affiliate/delivery_areas');
  const dispatch = useDispatch();
  const { closeDialog, utilitySelector, setRefresh } = useUtility();
  const { control, handleSubmit, reset } = useForm<IDeliveryAreas>({
    resolver: yupResolver(schema),
  });

  const [isDialog, setIsDialog] = useState(false);
  const [deliveryAreas, setDeliveryAreas] = useState<IDeliveryAreas>(
    {} as IDeliveryAreas
  );

  const isCreate = useCan({ view: 'affiliateDeliveryArea.create' });
  const isUpdate = useCan({ view: 'affiliateDeliveryArea.update' });
  const isDelete = useCan({ view: 'affiliateDeliveryArea.delete' });

  useEffect(() => {
    if (utilitySelector.dialogOpen) {
      if (utilitySelector.dialogId) {
        fetchData(utilitySelector.dialogId).then(response => {
          reset(response);
        });
      } else {
        reset({});
      }
    }
  }, [utilitySelector.dialogOpen, utilitySelector.dialogId, fetchData, reset]);

  useEffect(() => {
    if (utilitySelector.refresh) {
      const refresh = async () => {
        await getData();
      };

      refresh();
      dispatch(setRefresh(false));
    }
  }, [utilitySelector.refresh, getData, dispatch, setRefresh]);

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const mapColumns = (deliveryAreasX: IDeliveryAreas): IDeliveryAreas => {
    const result: IDeliveryAreas = {
      distance: deliveryAreasX.distance,
      delivery_fee: deliveryAreasX.delivery_fee,
      delivery_time: deliveryAreasX.delivery_time,
    };

    return result;
  };

  const onSubmit = async (formData: IDeliveryAreas) => {
    if (utilitySelector.dialogId) {
      if (formData.id) await update(formData.id, mapColumns(formData));
    } else {
      await create(formData);
    }

    dispatch(closeDialog());
  };

  const numberTemplate = (value: string) => {
    return (
      <div>
        {parseFloat(value).toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </div>
    );
  };

  const deleteArea = async (deliveryAreasX: IDeliveryAreas) => {
    if (deliveryAreasX.id) {
      await deleteObject(deliveryAreasX.id).then(() => setIsDialog(false));
    }
  };

  const setDialogArea = (deliveryAreasX: IDeliveryAreas) => {
    setDeliveryAreas(deliveryAreasX);
    setIsDialog(true);
  };

  const onOk = async () => {
    await deleteArea(deliveryAreas);
  };

  const actions = ActionsDataTable(
    false,
    data,
    isDelete ? setDialogArea : undefined,
    undefined,
    !isUpdate
  );

  const columns = [
    {
      name: 'distance',
      label: 'Distância (metros)',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'delivery_fee',
      label: 'Taxa de Entrega',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'delivery_time',
      label: 'Tempo Adicional (minutos)',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  if (actions) {
    columns.push(actions);
  }

  const handleCloseAreaDelete = () => setIsDialog(false);
  return (
    <Page>
      <DataTable
        title={<Title description="Áreas de Entrega" isLoading={isLoading} />}
        data={data}
        columns={columns}
        isLoading={isLoading}
        refresh={getData}
        hideActionAddToolbar={!isCreate}
      />

      <DialogForm
        titleDiag="Área de Entrega"
        open={utilitySelector.dialogOpen}
        isLoading={isFetch}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form control={control} isSubmitting={isSubmitting} />
      </DialogForm>
      <DialogX
        titleDiag={`Área de Entrega ${deliveryAreas.distance} m - Distância (metros)`}
        open={isDialog}
        onOk={() => onOk()}
        captionOk="Confirmar"
        captionCancel="Fechar"
        handleClose={handleCloseAreaDelete}
        isLoading={isSubmitting}
        autoFocus
      >
        {isSubmitting ? (
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          <Typography variant="body2">Confirma a exclusão da área?</Typography>
        )}
      </DialogX>
    </Page>
  );
}
