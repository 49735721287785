import React from 'react';
import { Popover } from '@mui/material';
import { PopoverProps } from '@mui/material/Popover/Popover';
import { alpha, styled } from '@mui/material/styles';

interface Props {
  children: React.ReactNode;
  sx: object;
}

const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));

export default function MenuPopover({
  children,
  sx,
  ...other
}: Props & PopoverProps) {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          mt: 1.5,
          ml: 0.5,
          overflow: 'inherit',
          boxShadow: theme =>
            `0 0 2px 0 ${alpha(
              theme.palette.grey[500],
              0.24
            )}, 0 20px 40px -4px ${alpha(theme.palette.grey[500], 0.24)}`,
          border: `solid 1px ${alpha('#919EAB', 0.08)}`,
          width: 200,
          ...sx,
        },
      }}
      {...other}
    >
      <ArrowStyle className="arrow" />

      {children}
    </Popover>
  );
}
