import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  List,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Avatar,
} from '@mui/material';
import { Business } from '@mui/icons-material';
import moment from 'moment';
import DialogX from '../DialogX';
import useUtility from '../../hooks/useUtility';
import { IAffiliate } from '../../models';
import useLogin from '../../hooks/useLogin';
import snack from '../SnackbarUtilsConfigurator';

interface Props {
  data: IAffiliate[];
  isLogin?: boolean;
}

export default function AffiliateSelect({ data, isLogin }: Props) {
  const { closeAffiliateSel, utilitySelector, setRefresh } = useUtility();
  const { clearState, setAffiliate, userSelector } = useLogin();
  const [idAffiliate, setIdAffiliate] = useState<string>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userSelector.affiliate?.id) {
      setIdAffiliate(userSelector.affiliate.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOk = () => {
    if (idAffiliate) {
      const affiliate = data.find(x => x.id === idAffiliate);

      if (affiliate) {
        const licenceExp = moment(affiliate.licence_expiration_at);
        const currentMoment = moment();

        if (licenceExp.isBefore(currentMoment)) {
          snack.confirmationError(
            `Licença da filial "${affiliate.fantasy_name}" expirou em ${moment(
              licenceExp
            ).format('DD/MM/YYYY')}. Por favor entre em contato com o suporte`
          );

          return;
        }

        dispatch(setAffiliate(affiliate));

        if (data.length > 1) {
          localStorage.setItem('affiliateSelection', 'S');
        }

        if (isLogin) {
          navigate('/inicio');
        } else {
          dispatch(setRefresh(true));
        }

        dispatch(closeAffiliateSel());
      } else {
        snack.warning('Filial não localizada');
      }
    } else {
      snack.warning('Por favor selecione a filial para avançar');
    }
  };

  const handleClose = () => {
    if (isLogin) {
      localStorage.removeItem('token');
      localStorage.removeItem('access');
    }

    dispatch(closeAffiliateSel());
    dispatch(clearState());

    if (isLogin) {
      snack.warning('Por favor selecione a filial para avançar');
    }
  };

  const handleListItemClick = (affiliate: IAffiliate) => {
    setIdAffiliate(affiliate.id);
  };

  return (
    <DialogX
      titleDiag="Filiais"
      open={utilitySelector.affiliateSelOpen}
      onOk={onOk}
      captionOk="Confirmar"
      captionCancel="Fechar"
      handleClose={handleClose}
      autoFocus
    >
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {data.map((affiliate: IAffiliate, key: number) => {
          return (
            <ListItem
              button
              key={key}
              selected={affiliate.id === idAffiliate}
              onClick={() => handleListItemClick(affiliate)}
            >
              <ListItemAvatar>
                {affiliate.logo_image_url ? (
                  <Avatar alt="logo" src={affiliate.logo_image_url} />
                ) : (
                  <Avatar>
                    <Business />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={affiliate.fantasy_name}
                secondary={affiliate.corporate_name}
              />
            </ListItem>
          );
        })}
      </List>
    </DialogX>
  );
}

AffiliateSelect.defaultProps = {
  isLogin: false,
};
