import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  drawer: {
    width: 530,
    maxWidth: '100%',
    backgroundColor: '#fff',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actions: {
    padding: 13,
    '& > * + *': {
      marginTop: 13,
    },
  },
}));
