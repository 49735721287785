import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Skeleton,
  Stack,
  Avatar,
  LinearProgress,
  linearProgressClasses,
  styled,
} from '@mui/material';
import { CardProps } from '@mui/material/Card/Card';
import { Add, LocalMall, AllInclusive } from '@mui/icons-material';
import { IInvoices, IInvoiceQuantity } from '../../../models';
import { formatCurrency } from '../../../utils';

type IParams = CardProps & {
  data: IInvoices;
  loading?: boolean;
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#e1e6ea' : '#C4CDD5',
  },
}));

export default function CurrentInvoice({ data, loading, ...rest }: IParams) {
  const [value, setValue] = useState<IInvoiceQuantity>({
    startNumber: 0,
    finalNumber: 0,
    quantity: 0,
  } as IInvoiceQuantity);

  useEffect(() => {
    if (data.id) {
      if (data.quantity_orders) {
        if (data.quantity_orders > 100 && data.quantity_orders <= 300) {
          setValue({
            startNumber: 100,
            finalNumber: 300,
            quantity: ((data.quantity_orders || 0) - 100) / 2,
          });
        } else if (data.quantity_orders > 300) {
          setValue({ startNumber: 300, finalNumber: 99999, quantity: 15 });
        } else {
          setValue({
            startNumber: 0,
            finalNumber: 100,
            quantity: data.quantity_orders || 0,
          });
        }
      } else {
        setValue({
          startNumber: 0,
          finalNumber: 100,
          quantity: data.quantity_orders || 0,
        });
      }
    }
  }, [data]);

  const returnInfo = (invoice: IInvoices) => {
    let result = `${invoice.quantity_orders} pedido(s) - `;

    if (invoice.liquid_value === 0) {
      result += 'Grátis';
    } else {
      result += formatCurrency(data.liquid_value || 0);
    }

    return result;
  };

  return (
    <Card {...rest}>
      <CardHeader
        title={
          loading ? (
            <Skeleton
              animation="wave"
              height={18}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          ) : (
            <Stack direction="row" justifyContent="space-between">
              <Typography component="span" variant="body1">
                Fatura Atual
              </Typography>

              <Typography component="span" variant="body1">
                {returnInfo(data)}
              </Typography>
            </Stack>
          )
        }
        titleTypographyProps={{ variant: 'body1' }}
      />
      <Divider />
      <CardContent>
        <Box height={40} position="relative">
          {loading && (
            <Skeleton
              sx={{ height: 40 }}
              animation="wave"
              variant="rectangular"
            />
          )}

          {!loading &&
            (data ? (
              <Stack>
                <Box display="flex" width="100%" flexDirection="row">
                  <Stack width="100%">
                    <Stack direction="row">
                      <Avatar
                        sx={{ bgcolor: 'primary.main', width: 32, height: 32 }}
                      >
                        <LocalMall fontSize="small" />
                      </Avatar>

                      <BorderLinearProgress
                        variant="determinate"
                        value={value.quantity}
                        sx={{ width: '100%', mt: 1.7 }}
                      />
                    </Stack>
                    <Typography
                      component="span"
                      variant="body2"
                      fontWeight="bold"
                      sx={{ mt: 0.5, ml: value.startNumber === 0 ? 1.5 : 1 }}
                    >
                      {value.startNumber}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack direction="row">
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                          bgcolor:
                            (data.quantity_orders || 0) >= value.finalNumber
                              ? 'primary.main'
                              : undefined,
                        }}
                      >
                        {value.finalNumber > 300 ? (
                          <AllInclusive fontSize="small" />
                        ) : (
                          <Add fontSize="small" />
                        )}
                      </Avatar>
                    </Stack>
                    <Typography
                      component="span"
                      variant="caption"
                      fontWeight="bold"
                      sx={{ mt: 0.5, ml: 0.5 }}
                    >
                      {value.finalNumber <= 300 ? value.finalNumber : undefined}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            ) : (
              <Box sx={{ flexGrow: 1, p: 3 }}>
                <Stack
                  sx={{
                    maring: 0,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    color="textSecondary"
                  >
                    Sem Informações
                  </Typography>
                </Stack>
              </Box>
            ))}
        </Box>
      </CardContent>
    </Card>
  );
}

CurrentInvoice.defaultProps = {
  loading: false,
};
