/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../../services/api';
import snack from '../SnackbarUtilsConfigurator';

interface Props {
  title?: string;
  url: string;
  variant?: string;
  shrink?: boolean;
  getLabel: (option: any) => string;
  onValidate?: () => string;
  beforeChange?: (obj: any) => void;
  onVerify?: (obj: any) => boolean;
  disabled?: boolean;
}

export default function AutocompleteAsync({
  name,
  control,
  rules,
  defaultValue,
  title,
  url,
  variant,
  shrink,
  getLabel,
  onValidate,
  beforeChange,
  onVerify,
  disabled,
  ...textFieldProps
}: Props & UseControllerProps<any> & TextFieldProps) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly any[]>([]);
  const loading = open && options.length === 0;

  const get = useCallback(async () => {
    try {
      const { data: response } = await api.get(`/${url}`);

      return response;
    } catch (error) {
      return undefined;
    }
  }, [url]);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const data = await get();

      if (active) {
        setOptions(data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, get]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          id={title}
          open={open}
          clearOnEscape
          {...(disabled && { disableClearable: true })}
          onOpen={() => {
            if (disabled) {
              return;
            }

            if (onValidate) {
              const valid = onValidate();

              if (valid !== '') {
                snack.warning(valid);
                return;
              }
            }

            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          options={options}
          loading={loading}
          value={field.value || null}
          loadingText="Carregando..."
          noOptionsText="Sem opções"
          openText="Abrir"
          clearText="Limpar"
          closeText="Fechar"
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={getLabel}
          onChange={(_, obj) => {
            if (onVerify) {
              if (!onVerify(obj)) {
                return;
              }
            }

            field.onChange(obj);

            if (beforeChange) {
              beforeChange(obj);
            }
          }}
          ref={field.ref}
          renderInput={params => (
            <TextField
              {...params}
              {...textFieldProps}
              margin="normal"
              variant={variant}
              {...(title && { label: title })}
              disabled={disabled}
              error={!!error}
              autoComplete="new-password"
              helperText={error ? error.message : null}
              InputLabelProps={{ shrink }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}

AutocompleteAsync.defaultProps = {
  title: undefined,
  variant: 'standard',
  shrink: true,
  onValidate: undefined,
  beforeChange: undefined,
  onVerify: undefined,
  disabled: false,
};
