import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import AutocompleteAsync from '../../components/AutocompleteAsync';
import DateX from '../../components/DateX';
import NumberField from '../../components/NumberField';
import { IAffiliate } from '../../models';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  isSubmitting?: boolean;
  onChange: (obj: IAffiliate) => void;
}

export default function Form({ control, isSubmitting, onChange }: Props) {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12} sx={{ mb: 1 }}>
        <AutocompleteAsync
          name="affiliate"
          control={control}
          title="Filial"
          required
          url="affiliates/all"
          getLabel={(option: IAffiliate) => option.fantasy_name}
          beforeChange={onChange}
        />
      </Grid>

      <Grid item md={4} xs={12} sx={{ mb: 2 }}>
        <DateX
          title="Data Inicial"
          variant="standard"
          name="start_at"
          control={control}
          required
        />
      </Grid>

      <Grid item md={4} xs={12} sx={{ mb: 2 }}>
        <DateX
          title="Data Final"
          variant="standard"
          name="end_at"
          control={control}
          required
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <DateX
          title="Data de Vencimento"
          variant="standard"
          name="maturity_at"
          control={control}
          required
        />
      </Grid>

      <Grid item md={12} xs={12}>
        <NumberField
          id="free_days"
          name="free_days"
          control={control}
          defaultValue={0}
          variant="standard"
          fullWidth
          label="Dias Grátis"
          InputLabelProps={{ shrink: true }}
          disabled={isSubmitting}
          required
        />
      </Grid>

      <Grid item md={6} xs={6}>
        <FormControlLabel
          sx={{ width: '100%' }}
          control={
            <Controller
              name="local_menu_module_enabled"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="local_menu_module_enabled"
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                  disabled={isSubmitting}
                />
              )}
            />
          }
          label="Cardápio Local"
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <FormControlLabel
          sx={{ width: '100%' }}
          control={
            <Controller
              name="cashback_module_enabled"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="cashback_module_enabled"
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                  disabled={isSubmitting}
                />
              )}
            />
          }
          label="Cashback"
        />
      </Grid>
    </Grid>
  );
}

Form.defaultProps = {
  isSubmitting: false,
};
