import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Skeleton,
  Stack,
} from '@mui/material';
import { CardProps } from '@mui/material/Card/Card';
import { ResponsiveBar } from '@nivo/bar';

type IParams = CardProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  loading?: boolean;
};

export default function BillingDayOfWeek({ data, loading, ...rest }: IParams) {
  return (
    <Card {...rest}>
      <CardHeader
        title={
          loading ? (
            <Skeleton
              animation="wave"
              height={18}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          ) : (
            <Stack direction="row" justifyContent="space-between">
              <Typography component="span" variant="body1">
                Faturamento por Dia da Semana
              </Typography>

              <Typography component="span" variant="body2">
                Últimos 7 dias
              </Typography>
            </Stack>
          )
        }
        titleTypographyProps={{ variant: 'body1' }}
      />
      <Divider />
      <CardContent>
        <Box height={300} position="relative">
          {loading && (
            <Skeleton
              sx={{ height: 300 }}
              animation="wave"
              variant="rectangular"
            />
          )}

          {!loading &&
            (data.length > 0 ? (
              <ResponsiveBar
                data={data}
                keys={['Período atual', 'Período anterior']}
                indexBy="day"
                enableLabel={false}
                margin={{ top: 30, right: 20, bottom: 30, left: 60 }}
                padding={0.3}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                valueFormat=" >-.2f"
                colors={{ scheme: 'pastel2' }}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Valor',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -35,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Faturamento por dia da semana"
                barAriaLabel={e => {
                  return `${e.id}: ${e.formattedValue}: ${e.indexValue}`;
                }}
              />
            ) : (
              <Box sx={{ flexGrow: 1, p: 3 }}>
                <Stack
                  sx={{
                    maring: 0,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    color="textSecondary"
                  >
                    Sem Informações
                  </Typography>
                </Stack>
              </Box>
            ))}
        </Box>
      </CardContent>
    </Card>
  );
}

BillingDayOfWeek.defaultProps = {
  loading: false,
};
