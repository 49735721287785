import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import moment from 'moment';
import {
  CircularProgress,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Lock,
  DoneAll,
  PictureAsPdf,
  Pix,
  Visibility,
  Clear,
} from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ActionsDataTable from '../../components/ActionsDataTable';
import useUtility from '../../hooks/useUtility';
import DataTable from '../../components/DataTable';
import DialogForm from '../../components/DialogForm';
import Page from '../../components/Page';
import { IAffiliate, IInvoices } from '../../models';
import Form from './Form';
import Title from '../../components/Title';
import api from '../../services/api';
import { queryClient } from '../../services/queryClient';
import snack from '../../components/SnackbarUtilsConfigurator';
import {
  getInvoice,
  getInvoices,
  useInvoices,
} from '../../services/hooks/useInvoice';
import Label from '../../components/Label';
import { formatDate, formatDateInfo } from '../../utils';
import DialogX from '../../components/DialogX';
import useLogin from '../../hooks/useLogin';

const schema = yup
  .object()
  .shape({
    affiliate: yup.object().required().nullable(),
    start_at: yup.string().required().nullable(),
    end_at: yup
      .string()
      .required()
      // eslint-disable-next-line func-names
      .test('endAtAfterStartAt', 'Inválido', function (endAt) {
        // eslint-disable-next-line camelcase
        const { start_at } = this.parent;
        return moment(endAt).isSameOrAfter(moment(start_at));
      }),
    maturity_at: yup
      .string()
      .required()
      // eslint-disable-next-line func-names
      .test('maturityAtAfterEndAt', 'Inválido', function (maturityAt) {
        // eslint-disable-next-line camelcase
        const { end_at } = this.parent;
        return moment(maturityAt).isSameOrAfter(moment(end_at));
      }),
    free_days: yup.number().min(0),
  })
  .required();

yup.setLocale(ptForm);
yup.setLocale({ mixed: { notType: 'Valor inválido.' } });

export default function Invoice() {
  const { userSelector } = useLogin();
  const { data, isLoading, isFetching } = useInvoices(
    userSelector.access.type === 'admin'
  );
  const [idInvoice, setIdInvoice] = useState<string>();
  const [invoices, setInvoices] = useState<IInvoices[]>();
  const [invoice, setInvoice] = useState<IInvoices>();
  const [paidAt, setPaidAt] = useState<Date | null>(new Date());
  const [isDialog, setIsDialog] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isLoadingX, setIsLoadingX] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { closeDialog, utilitySelector } = useUtility();
  const { control, handleSubmit, reset, setValue } = useForm<IInvoices>({
    resolver: yupResolver(schema),
  });

  const formatResponse = (response: IInvoices) => {
    return response;
  };

  useEffect(() => {
    if (utilitySelector.dialogOpen) {
      if (utilitySelector.dialogId) {
        setIsLoadingX(true);
        getInvoice(utilitySelector.dialogId)
          .then(response => {
            const result = formatResponse(response);
            reset(result);
          })
          .finally(() => setIsLoadingX(false));
      } else {
        const end = moment().endOf('month');
        const startOfMonth = moment().startOf('month').toDate();
        const endOfMonth = end.toDate();
        const maturityOfMonth = end.add(5, 'days').toDate();

        reset({
          start_at: startOfMonth,
          end_at: endOfMonth,
          maturity_at: maturityOfMonth,
        });
      }
    }
  }, [utilitySelector.dialogOpen, utilitySelector.dialogId, reset]);

  useEffect(() => {
    setInvoices(data);
  }, [data]);

  const refresh = useCallback(async () => {
    setIsLoadingX(true);
    return getInvoices(userSelector.access.type === 'admin')
      .then(result => setInvoices(result))
      .finally(() => setIsLoadingX(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const mapColumns = (invoiceX: IInvoices) => {
    return {
      affiliate_id: invoiceX.affiliate?.id || '',
      affiliate_started_at: invoiceX.affiliate?.created_at,
      start_at: formatDate(invoiceX.start_at.toString()),
      end_at: formatDate(invoiceX.end_at.toString()),
      free_days: invoiceX.free_days,
      maturity_at: formatDate(invoiceX.maturity_at.toString()),
      local_menu_module_enabled: invoiceX.local_menu_module_enabled || false,
      cashback_module_enabled: invoiceX.cashback_module_enabled || false,
    };
  };

  const onChange = (affiliate: IAffiliate) => {
    setValue(
      'local_menu_module_enabled',
      affiliate.local_menu_module_enabled || false
    );
    setValue(
      'cashback_module_enabled',
      affiliate.cashback_module_enabled || false
    );
  };

  const createOrUpdateInvoice = useMutation(
    async (invoiceX: IInvoices) => {
      const obj = mapColumns(invoiceX);

      if (invoiceX.id) {
        const responseX = await api.put<IInvoices>(
          `invoices/${invoiceX.id}`,
          obj
        );

        return responseX.data;
      }

      const response = await api.post<IInvoices>('invoices', obj);

      return response.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries('invoices');
        dispatch(closeDialog());
      },
    }
  );

  const patchSituationInvoice = useMutation(
    async () => {
      setIsStatusLoading(true);

      const objClosed = {
        situation: canceled ? 'canceled' : 'closed',
      };

      const objPaid = {
        situation: 'paid',
        paid_at: formatDate(paidAt?.toString()),
      };

      const paidExec = invoice?.situation === 'closed' && !canceled;

      const response = await api.patch(
        `invoice/situation/${invoice?.id}`,
        !paidExec ? objClosed : objPaid
      );

      return response.data;
    },
    {
      onSuccess() {
        snack.success('Processo concluído com sucesso!');
        queryClient.invalidateQueries(['invoices']);

        setIsDialog(false);
        setIsStatusLoading(false);
      },
      onError() {
        setIsStatusLoading(false);
      },
    }
  );

  const handlePaidAt = (date: Date | null) => {
    setPaidAt(date);
  };

  const onOk = () => {
    if (invoice?.situation === 'closed' && paidAt === null) {
      snack.warning('Data da baixa é inválida');
      return;
    }

    patchSituationInvoice.mutateAsync();
  };

  const request = async (obj: IInvoices) => {
    setIsSubmitting(true);
    await createOrUpdateInvoice
      .mutateAsync(obj)
      .finally(() => setIsSubmitting(false));
  };

  const onSubmit = async (formData: IInvoices) => {
    await request(formData);
  };

  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const _ = e.target.files && e.target.files[0];

    if (_) {
      const ext = _.name.substring(_.name.lastIndexOf('.') + 1);

      if (ext !== 'pdf') {
        snack.warning(`Não é permitido enviar a image nesse formato (.${ext})`);
        return;
      }

      const fileX = e.target.files?.item(0);

      if (fileX) {
        const objFile = new FormData();

        objFile.append('file', fileX);

        if (!idInvoice) {
          snack.warning('Arquivo não localizado');
          return;
        }

        await api
          .patch(`invoice/file/${idInvoice}`, objFile, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            snack.success('Arquivo enviado com sucesso!');
            queryClient.invalidateQueries('invoices');
          });
      } else {
        snack.warning('Por favor selecione um arquivo para ser enviado');
      }
    }
  };

  const handleAttach = (id: string) => {
    if (!isSubmitting) {
      setIdInvoice(id);
      fileInputRef.current?.click();
    }
  };

  const numberTemplate = (value: string) => {
    return (
      <div>
        {parseFloat(value).toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </div>
    );
  };

  const dateTemplate = (value: string) => {
    return value && <div>{formatDateInfo(value, 'DD/MM/YYYY')}</div>;
  };

  const situationTemplate = (value: string) => {
    let color:
      | 'warning'
      | 'error'
      | 'success'
      | 'default'
      | 'info'
      | 'primary'
      | 'secondary'
      | 'white' = 'warning';

    if (value === 'Em Aberto') {
      color = 'success';
    } else if (value === 'Fechada') {
      color = 'warning';
    } else if (value === 'Paga') {
      color = 'info';
    } else if (value === 'Cancelada') {
      color = 'error';
    }

    return (
      <Label variant="ghost" color={color}>
        {value}
      </Label>
    );
  };

  const invoiceTemplate = (dataIndex: number) => {
    if (!invoices) {
      return undefined;
    }

    const visibilityInvoice =
      invoices[dataIndex].invoice_file_url &&
      (invoices[dataIndex].situation === 'closed' ||
        invoices[dataIndex].situation === 'paid');

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
        }}
      >
        {userSelector.access.type === 'admin' && (
          <Tooltip title="Enviar Nota Fiscal" placement="top-end">
            <span>
              <IconButton
                size="small"
                aria-label="paid data"
                disabled={
                  invoices[dataIndex].situation === 'canceled' ||
                  invoices[dataIndex].situation === 'opened'
                }
                onClick={() => handleAttach(invoices[dataIndex].id || '')}
              >
                <PictureAsPdf fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {visibilityInvoice ? (
          <Tooltip title="Visualizar Nota Fiscal" placement="top-end">
            <Link
              target="_blank"
              sx={{ color: '#637381' }}
              href={invoices[dataIndex].invoice_file_url}
            >
              {userSelector.access.type === 'admin' ? (
                <Visibility fontSize="small" sx={{ mt: 0.7 }} />
              ) : (
                <PictureAsPdf fontSize="small" sx={{ mt: 0.7 }} />
              )}
            </Link>
          </Tooltip>
        ) : (
          <Tooltip title="Visualizar Nota Fiscal" placement="top-end">
            <Visibility fontSize="small" sx={{ color: '#cccccc' }} />
          </Tooltip>
        )}

        <Tooltip title="Copiar Código" placement="top-end">
          <span>
            <IconButton
              size="small"
              aria-label="closed data"
              disabled={invoices[dataIndex].situation !== 'closed'}
              onClick={() =>
                navigator.clipboard.writeText(
                  invoices[dataIndex].pix_copy_paste || ''
                )
              }
            >
              <Pix fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  };

  const getDescriptionSituation = (invoiceX?: IInvoices) => {
    if (invoiceX?.situation === 'opened') {
      return 'Fechar';
    }

    if (invoiceX?.situation === 'closed') {
      return 'Efetuar Baixa';
    }

    return '';
  };

  const getConfirmationSituation = (situation: string) => {
    if (canceled) {
      return 'Cancelada';
    }

    if (situation === 'opened') {
      return 'Fechada';
    }

    if (situation === 'closed') {
      return 'Paga';
    }

    return '';
  };

  const getIconSituation = (invoiceX?: IInvoices) => {
    if (invoiceX?.situation === 'opened') {
      return <Lock fontSize="small" />;
    }

    if (invoiceX?.situation === 'closed') {
      return <DoneAll fontSize="small" />;
    }

    return undefined;
  };

  const setDialogOrder = (invoiceX: IInvoices, canceledX: boolean) => {
    setCanceled(canceledX);
    setInvoice(invoiceX);
    setIsDialog(true);
  };

  const handleCloseInvoice = () => {
    setIsDialog(false);
    setCanceled(false);
  };

  const actionDetail = (dataIndex: number) => {
    const invoiceX = invoices ? invoices[dataIndex] : undefined;

    if (!invoiceX) {
      return undefined;
    }

    return (
      <>
        <Tooltip title={getDescriptionSituation(invoiceX)} placement="top-end">
          <IconButton
            size="small"
            aria-label="action data"
            onClick={() => setDialogOrder(invoiceX, false)}
          >
            {getIconSituation(invoiceX)}
          </IconButton>
        </Tooltip>
        {invoiceX.situation !== 'canceled' && (
          <Tooltip title="Cancelar" placement="top-end">
            <IconButton
              size="small"
              aria-label="cancel data"
              onClick={() => setDialogOrder(invoiceX, true)}
            >
              <Clear fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  const disableEdit = (obj: IInvoices) => {
    return obj.situation !== 'opened';
  };

  const activeTemplate = (value: string) => {
    return (
      <Label variant="ghost" color={(value === 'Não' && 'error') || 'success'}>
        {value}
      </Label>
    );
  };

  const actions = ActionsDataTable(
    true,
    invoices || [],
    undefined,
    actionDetail,
    undefined,
    undefined,
    undefined,
    disableEdit
  );

  const columns = [
    {
      name: 'affiliate_fantasy_name',
      label: 'Filial',
      options: {
        filter: true,
        sort: true,
        display: userSelector.access.type === 'admin',
      },
    },
    {
      name: 'number',
      label: 'Número',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'start_at',
      label: 'Inicial',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => dateTemplate(value),
      },
    },
    {
      name: 'end_at',
      label: 'Final',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => dateTemplate(value),
      },
    },
    {
      name: 'maturity_at',
      label: 'Vencimento',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => dateTemplate(value),
      },
    },
    {
      name: 'paid_at',
      label: 'Pagamento',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => dateTemplate(value),
      },
    },
    {
      name: 'quantity_orders',
      label: 'N° Pedidos',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'local_menu_module_enabled',
      label: 'Cardápio',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: boolean) =>
          activeTemplate(value ? 'Sim' : 'Não'),
      },
    },
    {
      name: 'cashback_module_enabled',
      label: 'Cashback',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: boolean) =>
          activeTemplate(value ? 'Sim' : 'Não'),
      },
    },
    {
      name: 'liquid_value',
      label: 'Valor',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'id',
      label: 'Opções',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number) => invoiceTemplate(dataIndex),
        setCellProps: () =>
          window.innerWidth < 900
            ? { style: {} }
            : { style: { whiteSpace: 'nowrap', width: '3%' } },
      },
    },
    {
      name: 'situation_description',
      label: 'Situação',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => situationTemplate(value),
      },
    },
  ];

  if (userSelector.access.type === 'admin') {
    if (actions) {
      columns.push(actions);
    }
  }

  const bodyDialogConfirm = (situation: string) => {
    if (situation === 'closed' && !canceled) {
      return (
        <>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Para confirmar o processo informe a data da baixa
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Data da Baixa"
              value={paidAt}
              inputFormat="DD/MM/YYYY"
              onChange={date => handlePaidAt(date)}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required
                />
              )}
            />
          </LocalizationProvider>
        </>
      );
    }

    return (
      <Typography variant="body2">
        {`Confirma a alteração do status da fatura para "${getConfirmationSituation(
          situation
        )}" ?`}
      </Typography>
    );
  };

  return (
    <Page>
      <DataTable
        title={
          <Title
            description="Faturas"
            isLoading={isLoading || isFetching || isLoadingX}
          />
        }
        data={invoices || []}
        columns={columns}
        isLoading={isLoading || isFetching}
        refresh={refresh}
        hideActionAddToolbar={userSelector.access.type !== 'admin'}
      />
      <DialogForm
        titleDiag="Fatura"
        open={utilitySelector.dialogOpen}
        isLoading={isLoadingX}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form
          control={control}
          isSubmitting={isSubmitting}
          onChange={onChange}
        />
      </DialogForm>
      <input
        style={{ display: 'none' }}
        ref={fileInputRef}
        type="file"
        onChange={handleFileInputChange}
        accept="application/pdf"
      />
      <DialogX
        titleDiag={`${invoice?.affiliate_fantasy_name} - Fatura ${invoice?.number}`}
        open={isDialog}
        onOk={() => onOk()}
        captionOk="Confirmar"
        captionCancel="Fechar"
        handleClose={handleCloseInvoice}
        isLoading={isStatusLoading}
        autoFocus
      >
        {isStatusLoading ? (
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          bodyDialogConfirm(invoice?.situation || '')
        )}
      </DialogX>
    </Page>
  );
}
