import React, { useCallback, useEffect } from 'react';
import Link from '@mui/material/Link';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Breadcrumbs,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { NavigateNext, ArrowForwardIos } from '@mui/icons-material';
import Page from '../../components/Page';
import Subtitle from './Components/Subtitle';
import Item from './Components/Item';
import { useCan } from '../../hooks/useCan';
import api from '../../services/api';
import useLogin from '../../hooks/useLogin';
import { setChangeLog } from '../../store/userLogin';
import Info from './Components/Info';

const AccordionX = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummaryX = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    sx={{ ml: 0.5 }}
    expandIcon={<ArrowForwardIos sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetailsX = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function News() {
  const isView = useCan({ view: 'affiliate.view' });
  const isViewProduct = useCan({ view: 'product.view' });
  const isViewAreas = useCan({ view: 'affiliateDeliveryArea.view' });
  const isViewOrdersPerDay = useCan({ view: 'dashboard.ordersPerDay' });
  const isViewTopSellingProducts = useCan({
    view: 'dashboard.topSellingProducts',
  });
  const { userSelector } = useLogin();
  const [expanded, setExpanded] = React.useState<string | false>('23082024');

  const onReadChangeLog = useCallback(async () => {
    if (userSelector.user.show_changelog) {
      await api.patch('user/read-changelog').then(() => setChangeLog());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => onReadChangeLog())();
  }, [onReadChangeLog]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const breadcrumbsCatalogProducts = [
    isViewProduct ? (
      <Link underline="hover" key="1" color="inherit" href="/catalogos">
        Catálogo
      </Link>
    ) : (
      <Typography key="1" color="text.primary">
        Catálogo
      </Typography>
    ),
    <Typography key="2" color="text.primary">
      Novo/Editar
    </Typography>,
    <Typography key="3" color="text.primary">
      Permitir Retirada/Entrega
    </Typography>,
  ];

  const breadcrumbsLocalMenu = [
    isViewProduct ? (
      <Link underline="hover" key="1" color="inherit" href="/catalogos">
        Catálogo
      </Link>
    ) : (
      <Typography key="1" color="text.primary">
        Catálogo
      </Typography>
    ),
    <Typography key="2" color="text.primary">
      Novo/Editar
    </Typography>,
    <Typography key="3" color="text.primary">
      Permitir Consumo no Local
    </Typography>,
  ];

  const breadcrumbsTopSellingProducts = [
    <Typography key="1" color="text.primary">
      Dashboard
    </Typography>,
    isViewTopSellingProducts ? (
      <Link
        underline="hover"
        key="2"
        color="inherit"
        href="/produtosmaisvendidos"
      >
        Prod. Mais Vendidos
      </Link>
    ) : (
      <Typography key="2" color="text.primary">
        Prod. Mais Vendidos
      </Typography>
    ),
  ];

  const breadcrumbsOrdersPerDay = [
    <Typography key="1" color="text.primary">
      Dashboard
    </Typography>,
    isViewOrdersPerDay ? (
      <Link underline="hover" key="2" color="inherit" href="/pedidospordia">
        Pedidos por Dia
      </Link>
    ) : (
      <Typography key="2" color="text.primary">
        Pedidos por Dia
      </Typography>
    ),
  ];

  const breadcrumbs14012023 = [
    isViewAreas ? (
      <Link underline="hover" key="1" color="inherit" href="/areasentrega">
        Áreas de Entrega
      </Link>
    ) : (
      <Typography key="1" color="text.primary">
        Áreas de Entrega
      </Typography>
    ),
    <Typography key="2" color="text.primary">
      Novo/Editar
    </Typography>,
    <Typography key="3" color="text.primary">
      Tempo Adicional (minutos)
    </Typography>,
  ];

  const breadcrumbs01012023 = [
    isView ? (
      <Link underline="hover" key="1" color="inherit" href="/filiais">
        Filiais
      </Link>
    ) : (
      <Typography key="1" color="text.primary">
        Filiais
      </Typography>
    ),
    <Typography key="2" color="text.primary">
      Editar
    </Typography>,
    <Typography key="3" color="text.primary">
      Pedido
    </Typography>,
    <Typography key="4" color="text.primary">
      Chave Pix
    </Typography>,
  ];

  const breadcrumbs = [
    isView ? (
      <Link underline="hover" key="1" color="inherit" href="/filiais">
        Filial
      </Link>
    ) : (
      <Typography key="1" color="text.primary">
        Filial
      </Typography>
    ),
    <Typography key="2" color="text.primary">
      Editar
    </Typography>,
    <Typography key="3" color="text.primary">
      Pedido
    </Typography>,
    <Typography key="4" color="text.primary">
      Notifique-me de Novos Pedidos pelo WhatsApp
    </Typography>,
  ];

  return (
    <Page hideInfoCompany>
      <Info text="Novidades do Sistema" />

      <Stack sx={{ mt: 2 }}>
        <AccordionX
          expanded={expanded === '23082024'}
          onChange={handleChange('23082024')}
        >
          <AccordionSummaryX aria-controls="23082024-content" id="23082024">
            <Typography variant="h6" sx={{ ml: 0.7 }}>
              23/08/2024
            </Typography>
          </AccordionSummaryX>

          <AccordionDetailsX sx={{ pl: 3, pr: 3, pb: 3 }}>
            <Subtitle text="Portal" />

            <Item
              nivel={1}
              text="Módulo de Cashback (Programa de fidelidade)"
            />

            <Item nivel={2}>
              <Typography variant="body1" sx={{ ml: 2 }}>
                Agora, você pode oferecer cashback aos seus clientes diretamente
                pela nossa plataforma. Incentive novos pedidos e fidelize
                clientes com essa vantagem competitiva.
              </Typography>
            </Item>

            <Item nivel={2}>
              <Typography variant="body1" sx={{ ml: 2 }}>
                <b>Quer saber mais?</b> Nos envie uma mensagem para saber mais:{' '}
                <Link
                  underline="hover"
                  target="_blank"
                  key="1"
                  color="#25d366"
                  href="https://wa.me/+5566996757603"
                >
                  Chame no WhatsApp
                </Link>
              </Typography>
            </Item>

            <Item
              nivel={1}
              text="Corrigimos alguns erros e fizemos melhorias para que você aproveite ao máximo a nossa plataforma."
            />

            <Subtitle text="Delivery" />

            <Item nivel={1} text="Cadastro de Endereço" />

            <Item nivel={2}>
              <Typography variant="body1" sx={{ ml: 2 }}>
                Adicionamos a opção de buscar a localização atual do
                dispositivo, facilitando o preenchimento do endereço.
              </Typography>
            </Item>
          </AccordionDetailsX>
        </AccordionX>
      </Stack>

      {/* <Stack sx={{ mt: 2 }}>
        <AccordionX
          expanded={expanded === '11032023'}
          onChange={handleChange('11032023')}
        >
          <AccordionSummaryX aria-controls="11032023-content" id="11032023">
            <Typography variant="h6" sx={{ ml: 0.7 }}>
              11/03/2023
            </Typography>
          </AccordionSummaryX>

          <AccordionDetailsX sx={{ pl: 3, pr: 3, pb: 3 }}>
            <Subtitle text="Catálogo de Produtos" />

            <Item
              nivel={1}
              text="Adicionado a opção “Permitir Retirada” e “Permitir Entrega”, com isso você consegue criar/editar um produto do tipo Simples/Composto para que ele fique disponivel apenas para Retirada no Local, apenas para Entrega ou ambos."
            />

            <Item nivel={2}>
              <Breadcrumbs
                separator={<NavigateNext fontSize="small" />}
                aria-label="breadcrumb-catalog-prod"
                sx={{ ml: 2 }}
              >
                {breadcrumbsCatalogProducts}
              </Breadcrumbs>
            </Item>

            <Subtitle text="Módulo de Cardápio Local" />

            <Item
              nivel={1}
              text="Adicionado a opção “Permitir Consumo no Local” no cadastro de produtos, é possível também informar um valor diferente para o consumo no local"
            />

            <Item nivel={1}>
              <Breadcrumbs
                separator={<NavigateNext fontSize="small" />}
                aria-label="breadcrumb-local-menu"
                sx={{ ml: 2 }}
              >
                {breadcrumbsLocalMenu}
              </Breadcrumbs>
            </Item>

            <Item nivel={1}>
              <Typography variant="body1" sx={{ ml: 2 }}>
                Nos envie uma mensagem para saber mais:{' '}
                <Link
                  underline="hover"
                  target="_blank"
                  key="1"
                  color="#25d366"
                  href="https://wa.me/+5566996757603"
                >
                  Chame no WhatsApp
                </Link>
              </Typography>
            </Item>
          </AccordionDetailsX>
        </AccordionX>
      </Stack>

      <Stack sx={{ mt: 2 }}>
        <AccordionX
          expanded={expanded === '01022023'}
          onChange={handleChange('01022023')}
        >
          <AccordionSummaryX aria-controls="01022023-content" id="01022023">
            <Typography variant="h6" sx={{ ml: 0.7 }}>
              01/02/2023
            </Typography>
          </AccordionSummaryX>

          <AccordionDetailsX sx={{ pl: 3, pr: 3, pb: 3 }}>
            <Subtitle text="Novos Relatórios" />

            <Item nivel={1} text="Relatório de Produtos Mais Vendidos" />

            <Item nivel={2}>
              <Breadcrumbs
                separator={<NavigateNext fontSize="small" />}
                aria-label="breadcrumb-top-selling-prod"
                sx={{ ml: 2 }}
              >
                {breadcrumbsTopSellingProducts}
              </Breadcrumbs>
            </Item>

            <Item nivel={1} text="Relatório de Pedidos por Dia" />

            <Item nivel={2}>
              <Breadcrumbs
                separator={<NavigateNext fontSize="small" />}
                aria-label="breadcrumb-orders-per-day"
                sx={{ ml: 2 }}
              >
                {breadcrumbsOrdersPerDay}
              </Breadcrumbs>
            </Item>
          </AccordionDetailsX>
        </AccordionX>
      </Stack>

      <Stack sx={{ mt: 2 }}>
        <AccordionX
          expanded={expanded === '14012023'}
          onChange={handleChange('14012023')}
        >
          <AccordionSummaryX aria-controls="14012023-content" id="14012023">
            <Typography variant="h6" sx={{ ml: 0.7 }}>
              14/01/2023
            </Typography>
          </AccordionSummaryX>

          <AccordionDetailsX sx={{ pl: 3, pr: 3, pb: 3 }}>
            <Subtitle text="Tempo Adicional para Área de Entrega" />

            <Item
              nivel={1}
              text="Esses minutos extras serão incrementados ao prazo de entrega
                inicial e final do pedido."
            />

            <Item nivel={1}>
              <Breadcrumbs
                separator={<NavigateNext fontSize="small" />}
                aria-label="breadcrumb-14012023"
                sx={{ ml: 2 }}
              >
                {breadcrumbs14012023}
              </Breadcrumbs>
            </Item>

            <Subtitle text="Faturas" />

            <Item
              text="Novo menu de faturas (apenas para supervisor) com a listagem de mensalidades do sistema com a opção de:"
              nivel={1}
            />

            <Item text="Visualizar NFS-e" nivel={2} />

            <Item text="Copiar - Pix Copia e Cola" nivel={2} />
          </AccordionDetailsX>
        </AccordionX>
      </Stack>

      <Stack sx={{ mt: 2 }}>
        <AccordionX
          expanded={expanded === '01012023'}
          onChange={handleChange('01012023')}
        >
          <AccordionSummaryX aria-controls="01012023-content" id="01012023">
            <Typography variant="h6" sx={{ ml: 0.7 }}>
              01/01/2023
            </Typography>
          </AccordionSummaryX>

          <AccordionDetailsX sx={{ pl: 3, pr: 3, pb: 3 }}>
            <Subtitle text="QRCode e Pix Copia e Cola" />

            <Item nivel={1}>
              <Typography variant="body1" sx={{ ml: 2 }}>
                Quando um novo pedido com a forma de pagamento <b>Pix</b> for{' '}
                <b>aceito</b> pelo estabelecimento, o cliente irá receber uma
                mensagem no WhatsApp com o código <b>Pix Copia e Cola.</b>
              </Typography>
            </Item>

            <Item
              text="Para isso acontecer é necessário que você informe a sua chave Pix em:"
              nivel={2}
            />

            <Item nivel={3}>
              <Breadcrumbs
                separator={<NavigateNext fontSize="small" />}
                aria-label="breadcrumb-01012023"
                sx={{ ml: 2 }}
              >
                {breadcrumbs01012023}
              </Breadcrumbs>
            </Item>

            <Subtitle text="Flag “Gelado(a)” no Produto" />

            <Item
              text="Adicionado ao cadastro de produto a flag “Gelado(a)”. Caso essa flag esteja marcada, irá aparecer uma indicação no catálogo do Delivery que o produto é um item gelado(a)."
              nivel={1}
            />
          </AccordionDetailsX>
        </AccordionX>
      </Stack>

      <Stack sx={{ mt: 2 }}>
        <AccordionX
          expanded={expanded === '24122022'}
          onChange={handleChange('24122022')}
        >
          <AccordionSummaryX aria-controls="24122022-content" id="24122022">
            <Typography variant="h6" sx={{ ml: 0.7 }}>
              24/12/2022
            </Typography>
          </AccordionSummaryX>

          <AccordionDetailsX sx={{ pl: 3, pr: 3, pb: 3 }}>
            <Subtitle text="Login sem Senha no Delivery" />

            <Item
              text="Ao acessar o link exclusivo para realizar o pedido não será mais necessário informar a senha para efetuar o login."
              nivel={1}
            />
            <Item
              text="Seu cliente poderá escolher entre fazer login utilizando o número de celular ou o endereço de e-mail informado no cadastro."
              nivel={1}
            />
            <Item nivel={2}>
              <Typography variant="body1" sx={{ ml: 2 }}>
                Caso ele opte por fazer o <b>login por e-mail</b>, será enviado
                um código de 6 dígitos para o endereço de e-mail informado.
              </Typography>
            </Item>
            <Item nivel={2}>
              <Typography variant="body1" sx={{ ml: 2 }}>
                Caso ele opte por fazer o <b>login por celular</b>, será enviado
                um código de 6 dígitos por WhatsApp ou SMS para o número de
                celular informado.
              </Typography>
            </Item>
          </AccordionDetailsX>
        </AccordionX>
      </Stack>

      <Stack sx={{ mt: 2 }}>
        <AccordionX
          expanded={expanded === '17122022'}
          onChange={handleChange('17122022')}
        >
          <AccordionSummaryX aria-controls="17122022-content" id="17122022">
            <Typography variant="h6" sx={{ ml: 0.7 }}>
              17/12/2022
            </Typography>
          </AccordionSummaryX>

          <AccordionDetailsX sx={{ pl: 3, pr: 3, pb: 3 }}>
            <Subtitle text="Notificações via WhatsApp" />

            <Item text="Notificações relacionadas ao pedido" nivel={1} />
            <Item
              text="Seu cliente é notificado através do WhatsApp sempre que houver uma alteração no status do pedido."
              nivel={2}
            />
            <Item
              text="Notificação de novos pedidos no seu WhatsApp"
              nivel={1}
            />
            <Item
              text="Você pode ser notificado de novos pedidos através do seu WhatsApp. Sempre que um novo pedido for realizado na plataforma, você recebe uma mensagem no WhatsApp com os dados básicos do pedido e um link para acessar o Portal."
              nivel={2}
            />
            <Item
              text="Gostou da ideia? Só habilitar essa funcionalidade em:"
              nivel={2}
            />

            <Item nivel={3}>
              <Breadcrumbs
                separator={<NavigateNext fontSize="small" />}
                aria-label="breadcrumb-17122022"
                sx={{ ml: 2 }}
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Item>
          </AccordionDetailsX>
        </AccordionX>
      </Stack> */}
    </Page>
  );
}
