import React from 'react';
import { Typography } from '@mui/material';
import PaperInfo from '../../../components/PaperInfo';

interface Props {
  text: string;
}

export default function Info({ text }: Props) {
  return (
    <PaperInfo>
      <Typography component="span" variant="h5" sx={{ color: 'black' }}>
        {text}
      </Typography>
    </PaperInfo>
  );
}
