/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
/* eslint-disable no-empty */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useCallback, useEffect, useState } from 'react';
import { IBase } from '../../models';
import api from '../api';

const useData = <T extends IBase>(url: string, queryParams?: string, noGet?: boolean) => {
  const [data, setData] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [erro, setErro] = useState('');

  const create = async (
    obj: T,
    execute?: (id: string) => Promise<void>,
    afterExecute?: (response: T) => void
  ) => {
    setIsSubmitting(true);
    try {
      await api.post<T>(url, obj).then(async (result) => {
        setData([...data, result.data]);

        if (execute) {
          if (result.data.id) {
            try {
              await execute(result.data.id);
            } catch { }
          }
        }

        if (afterExecute) {
          afterExecute(result.data);
        }
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const createList = async (obj: any) => {
    setIsSubmitting(true);
    try {
      await api.post(url, obj);
    } finally {
      setIsSubmitting(false);
    }
  };

  const update = async (id: string, obj: T, execute?: (id: string) => Promise<void>, afterExecute?: (response: T) => void) => {
    setIsSubmitting(true);
    try {
      await api.put<T>(`${url}/${id}`, obj).then(async (result) => {
        const updateTable = [...data];
        const index = updateTable.findIndex(x => x.id === id);
        updateTable[index] = result.data;
        setData(updateTable);

        if (execute) {
          if (result.data.id) {
            try {
              await execute(result.data.id);
            } catch { }
          }
        }

        if (afterExecute) {
          afterExecute(result.data);
        }
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteObject = async (id: string, query?: string) => {
    setIsSubmitting(true);
    try {
      const urlDel = query ? `${url}/${id}${query}` : `${url}/${id}`;

      await api.delete<T>(urlDel).then(async (result) => {
        if (result.status === 204) {
          const updateTable = [...data];
          const index = updateTable.findIndex(x => x.id === id);
          updateTable.splice(index, 1);
          setData(updateTable);
        }
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const get = async (urlX: string) => {
    setIsLoading(true);
    try {
      return await api.get(urlX);
    } finally {
      setIsLoading(false);
    }
  };

  const patch = async (urlX: string, obj: FormData) => {
    setIsSubmitting(true);
    try {
      return await api.patch<T>(urlX, obj, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const patchJson = async (urlX: string, obj: any) => {
    setIsSubmitting(true);
    try {
      return await api.patch<T>(urlX, obj);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchData = useCallback(
    async (id: string) => {
      try {
        setIsFetch(true);
        try {
          const { data: response } = await api.get<T>(`/${url}/${id}`);
          return response;
        } finally {
          setIsFetch(false);
        }
      } catch (error) {
        if (error instanceof Error) {
          setErro(error.message);
        }

        return undefined;
      }
    },
    [url]
  );

  const onGet = useCallback(async () => {
    setErro('');

    try {
      setIsLoading(true);
      try {
        const urlGet = queryParams ? url.concat(queryParams) : url;
        const { data: response } = await api.get<T[]>(`/${urlGet}`);

        setData(response);
      } finally {
        setIsLoading(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        setErro(error.message);
      }
    }
  }, [url, queryParams]);

  const getData = useCallback(async () => {
    await onGet();
  }, [onGet]);

  useEffect(() => {
    if (!noGet) {
      onGet();
    }
  }, [onGet, noGet]);

  return {
    data,
    setData,
    erro,
    isLoading,
    isFetch,
    isSubmitting,
    create,
    createList,
    update,
    deleteObject,
    get,
    patch,
    patchJson,
    getData,
    fetchData,
    onGet,
  };
};

export default useData;
