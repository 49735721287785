import { useQuery, QueryOptions } from 'react-query';
import { IBalance, ICashbackInfo } from '../../models';
import api from '../api';
import { queryClient } from '../queryClient';

function formatCashback(cashback: ICashbackInfo): ICashbackInfo {
  return {
    ...cashback,
    customer_name: cashback.customer.name,
    customer_cellphone: cashback.customer.cellphone,
    customer_cpf_cnpj: cashback.customer.cpf_cnpj,
    order_number: cashback.order?.number,
  };
}

export async function getBalance(customerId: string): Promise<IBalance> {
  const { data } = await api.get<IBalance>(
    `cashbacks/balance?customer_id=${customerId}`
  );

  return data;
}

export async function getCashbacks(): Promise<ICashbackInfo[]> {
  const { data } = await api.get<ICashbackInfo[]>('cashbacks');

  const formattedData: ICashbackInfo[] = [];

  data.forEach(cashback => {
    formattedData.push(formatCashback(cashback));
  });

  return formattedData;
}

export function useCashbacks(options?: QueryOptions<ICashbackInfo[]>) {
  return useQuery(['cashbacks'], () => getCashbacks(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...options,
  });
}

export async function fetchCashbacks() {
  return queryClient.fetchQuery(['cashbacks'], () => getCashbacks());
}
