export const states = [
  {
    id: 'AC',
    description: 'Acre',
  },
  {
    id: 'AL',
    description: 'Alagoas',
  },
  {
    id: 'AM',
    description: 'Amazonas',
  },
  {
    id: 'AP',
    description: 'Amapá',
  },
  {
    id: 'BA',
    description: 'Bahia',
  },
  {
    id: 'CE',
    description: 'Ceará',
  },
  {
    id: 'DF',
    description: 'Distrito Federal',
  },
  {
    id: 'ES',
    description: 'Espírito Santo',
  },
  {
    id: 'GO',
    description: 'Goiás',
  },
  {
    id: 'MA',
    description: 'Maranhão',
  },
  {
    id: 'MG',
    description: 'Minas Gerais',
  },
  {
    id: 'MS',
    description: 'Mato Grosso do Sul',
  },
  {
    id: 'MT',
    description: 'Mato Grosso',
  },
  {
    id: 'PA',
    description: 'Pará',
  },
  {
    id: 'PB',
    description: 'Paraíba',
  },
  {
    id: 'PE',
    description: 'Pernambuco',
  },
  {
    id: 'PI',
    description: 'Piauí',
  },
  {
    id: 'PR',
    description: 'Paraná',
  },
  {
    id: 'RJ',
    description: 'Rio de Janeiro',
  },
  {
    id: 'RN',
    description: 'Rio Grande do Norte',
  },
  {
    id: 'RO',
    description: 'Rondônia',
  },
  {
    id: 'RR',
    description: 'Roraima',
  },
  {
    id: 'RS',
    description: 'Rio Grande do Sul',
  },
  {
    id: 'SC',
    description: 'Santa Catarina',
  },
  {
    id: 'SE',
    description: 'Sergipe',
  },
  {
    id: 'SP',
    description: 'São Paulo',
  },
  {
    id: 'TO',
    description: 'Tocantins',
  },
];

export const orderCategory = [
  { id: 'asc_name', description: 'Nome de A-Z' },
  { id: 'desc_name', description: 'Nome de Z-A' },
  { id: 'lower_value', description: 'Menor Valor' },
  { id: 'highest_value', description: 'Maior Valor' },
];

export const ruleComplement = [
  { id: 'sum', description: 'Somar Valor dos Itens' },
  { id: 'highest_value', description: 'Item de Maior Valor' },
];

export const typeCloneCompProduct = [
  { id: 'keep_and_upsert', description: 'Inserir e/ou atualizar registros' },
  {
    id: 'delete_and_insert',
    description: 'Deletar registros atuais e inserir os novos',
  },
];

export const modelPrintOrder = [
  {
    id: 'model1_80mm',
    description: 'Modelo 01 - Papel Térmico de 80mm',
    page_width: '80mm',
  },
  {
    id: 'model2_58mm',
    description: 'Modelo 02 - Papel Térmico de 58mm',
    page_width: '58mm',
  },
];
