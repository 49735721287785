import React, { useState, useEffect } from 'react';
import { format, toDate } from 'date-fns-tz';
import { IconButton, Tooltip } from '@mui/material';
import { ShareLocation } from '@mui/icons-material';
import DataTable from '../../components/DataTable';
import Page from '../../components/Page';
import { ICustomer } from '../../models';
import api from '../../services/api';
import { maskCPFCNPJ, maskPhone } from '../../utils';
import Title from '../../components/Title';
import ActionsDataTable from '../../components/ActionsDataTable';
import Addresses from './Addresses';

export default function Customer() {
  const [data, setData] = useState<ICustomer[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [customer, setCustomer] = useState<ICustomer | null>();

  const getCustomers = async () => {
    setIsLoading(true);

    await api
      .get('/customers')
      .then(result => {
        setData(result.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const mask = (value: string) => {
    return <div>{maskCPFCNPJ(value)}</div>;
  };

  const phoneMask = (value: string) => {
    return <div>{maskPhone(value)}</div>;
  };

  const dateFormat = (value: string) => {
    return value && <div>{format(toDate(value), 'dd/MM/yyyy')}</div>;
  };

  const openAddresses = (dataIndex: number) => {
    setCustomer(data.at(dataIndex));
    setOpenAddress(true);
  };

  const methods = (dataIndex: number) => {
    return (
      <Tooltip title="Endereços" placement="top-start">
        <IconButton
          size="small"
          aria-label="customer addresses"
          onClick={() => openAddresses(dataIndex)}
        >
          <ShareLocation fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  };

  const actions = ActionsDataTable(false, data || [], undefined, methods, true);

  const columns = [
    {
      name: 'code',
      label: 'Código',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'cpf_cnpj',
      label: 'CPF/CNPJ',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => mask(value),
      },
    },
    {
      name: 'cellphone',
      label: 'Celular',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => phoneMask(value),
      },
    },
    {
      name: 'date_birth',
      label: 'Data de Nascimento',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => dateFormat(value),
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  if (actions) {
    columns.push(actions);
  }

  const closeAddressMethods = () => {
    setCustomer(null);
    setOpenAddress(false);
  };

  return (
    <Page>
      <DataTable
        title={<Title description="Clientes" isLoading={isLoading} />}
        data={data}
        columns={columns}
        isLoading={isLoading}
        refresh={getCustomers}
        hideActionAddToolbar
      />
      <Addresses
        customer={customer || ({} as ICustomer)}
        open={openAddress}
        close={closeAddressMethods}
      />
    </Page>
  );
}
