/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller, UseControllerProps } from 'react-hook-form';
import 'moment/locale/pt-br';

interface Props {
  title?: string;
  disableFuture?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  required?: boolean;
}

export default function DateX({
  title,
  disableFuture,
  variant,
  required,
  name,
  control,
  rules,
  defaultValue,
}: Props & UseControllerProps<any>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            {...field}
            {...(title && { label: title })}
            value={field.value}
            inputFormat="DD/MM/YYYY"
            onChange={field.onChange}
            disableFuture={disableFuture}
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                variant={variant}
                error={!!error}
                helperText={error ? error.message : null}
                InputLabelProps={{ shrink: !!title }}
                fullWidth
                required={required}
              />
            )}
            ref={field.ref}
          />
        </LocalizationProvider>
      )}
    />
  );
}

DateX.defaultProps = {
  title: undefined,
  disableFuture: false,
  variant: 'outlined',
  required: false,
};
