/* eslint-disable no-restricted-globals */
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation } from 'react-query';
import { Difference } from '@mui/icons-material';
import ActionsDataTable from '../../components/ActionsDataTable';
import DataTable from '../../components/DataTable';
import DialogForm from '../../components/DialogForm';
import {
  IAffiliateProductComplements,
  IAffiliateProducts,
  IComplementClone,
  IComplementItems,
  IProduct,
} from '../../models';
import AutocompleteAsync from '../../components/AutocompleteAsync';
import NumberField from '../../components/NumberField';
import Button from '../../components/Button';
import snack from '../../components/SnackbarUtilsConfigurator';
import {
  getAffiliateComplements,
  useAffiliateComplements,
} from '../../services/hooks/useAffiliateComplement';
import api from '../../services/api';
import { queryClient } from '../../services/queryClient';
import Title from '../../components/Title';
import { typeCloneCompProduct } from '../../statics';
import DialogX from '../../components/DialogX';

interface Props {
  affiliateProducts: IAffiliateProducts;
  open: boolean;
  onClose: () => void;
  isUpdate: boolean;
}

const schema = yup
  .object()
  .shape({
    complement_item: yup.object().required().nullable(),
    additional_value: yup.number().required().nullable(),
  })
  .required();

const schemaClone = yup
  .object()
  .shape({
    product: yup.object().required().nullable(),
    type: yup.string().required().nullable(),
  })
  .required();

yup.setLocale(ptForm);
yup.setLocale({ mixed: { notType: 'Valor inválido.' } });

export default function Item({
  affiliateProducts,
  open,
  onClose,
  isUpdate,
}: Props) {
  const { data, isLoading, isFetching } = useAffiliateComplements(
    affiliateProducts.id || ''
  );
  const [complements, setComplements] =
    useState<IAffiliateProductComplements[]>();
  const [isLoadingX, setIsLoadingX] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  const [isDialogX, setIsDialogX] = useState(false);
  const [edit, setEdit] = useState(false);
  const [affiliateProductComplements, setAffiliateProductComplements] =
    useState<IAffiliateProductComplements>({} as IAffiliateProductComplements);

  const refresh = useCallback(async () => {
    setIsLoadingX(true);
    return getAffiliateComplements(affiliateProducts.id || '')
      .then(result => setComplements(result))
      .finally(() => setIsLoadingX(false));
  }, [affiliateProducts.id]);

  useEffect(() => {
    if (open) {
      refresh();
    }
  }, [open, refresh]);

  useEffect(() => {
    setComplements(data);
  }, [data]);

  const { control, handleSubmit, reset } =
    useForm<IAffiliateProductComplements>({
      resolver: yupResolver(schema),
    });

  const {
    control: controlClone,
    handleSubmit: handleSubmitClone,
    reset: resetClone,
  } = useForm<IComplementClone>({
    resolver: yupResolver(schemaClone),
  });

  const resetForm = () => {
    reset({
      affiliate_product_id: undefined,
      complement_item_id: undefined,
      additional_value: undefined,
      complement_item: undefined,
    });
  };

  const handleClose = () => {
    queryClient.invalidateQueries(['affiliate_products']);
    setEdit(false);
    setComplements([]);
    onClose();
    resetForm();
  };

  const mapColumns = (
    affiliateComplementItems: IAffiliateProductComplements
  ): IAffiliateProductComplements => {
    const result: IAffiliateProductComplements = {
      affiliate_product_id: affiliateProducts.id || null,
      complement_item_id: affiliateComplementItems.complement_item?.id || null,
      additional_value: affiliateComplementItems.additional_value,
    };

    return result;
  };

  const createOrUpdateComplement = useMutation(
    async (complement: IAffiliateProductComplements) => {
      const obj = mapColumns(complement);

      if (edit && complement.id) {
        const responseX = await api.put<IAffiliateProductComplements>(
          `affiliate/product/complements/${complement.id}`,
          obj
        );

        return responseX.data;
      }

      const response = await api.post<IAffiliateProductComplements>(
        `affiliate/product/complements`,
        obj
      );

      return response.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries('affiliate_complements');
        resetForm();
        setEdit(false);
      },
    }
  );

  const deleteComplement = useMutation(
    async (id: string) => {
      await api.delete(
        `affiliate/product/complements/${id}?affiliate_product_id=${affiliateProducts.id}`
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['affiliate_complements']);
        setEdit(false);
        resetForm();
      },
    }
  );

  const onSubmit = async (formData: IAffiliateProductComplements) => {
    setIsSubmitting(true);
    await createOrUpdateComplement
      .mutateAsync(formData)
      .finally(() => setIsSubmitting(false));
  };

  const onSubmitClone = async (formData: IComplementClone) => {
    setIsSubmitting(true);

    const obj = {
      origin_product_id: formData.product?.id || '',
      affiliate_product_id: affiliateProducts.id || '',
      type: formData.type,
    } as IComplementClone;

    await api
      .patch<IComplementClone>(`affiliate/product/complements/clone`, obj)
      .then(result => {
        if (result.status === 204) {
          snack.success('Importação realizada com sucesso');
          queryClient.invalidateQueries(['affiliate_complements']);
          setIsDialog(false);
          resetClone({});
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const deleteComplementItems = async (
    complement: IAffiliateProductComplements
  ) => {
    if (complement.id) {
      setIsSubmitting(true);
      await deleteComplement
        .mutateAsync(complement.id)
        .then(() => setIsDialogX(false))
        .finally(() => setIsSubmitting(false));
    }
  };

  const numberTemplate = (value: string) => {
    return (
      <div>
        {parseFloat(value).toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </div>
    );
  };

  const onEdit = (obj: IAffiliateProductComplements) => {
    setEdit(true);
    reset(obj);
  };

  const handleCloseClone = () => setIsDialog(false);

  const openClone = () => {
    resetClone({});
    setIsDialog(true);
  };

  const cloneProduct = () => {
    return (
      <Tooltip title="Importar Itens" sx={{ marginLeft: 1 }}>
        <IconButton onClick={() => openClone()} size="small">
          <Difference fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  };

  const handleCloseAffiliateProductComp = () => setIsDialogX(false);

  const setDialogAffiliateProductComp = (
    affiliateProductComplementsX: IAffiliateProductComplements
  ) => {
    setAffiliateProductComplements(affiliateProductComplementsX);
    setIsDialogX(true);
  };

  const onOk = async () => {
    await deleteComplementItems(affiliateProductComplements);
  };

  const actions = ActionsDataTable(
    false,
    complements || [],
    setDialogAffiliateProductComp,
    undefined,
    !isUpdate,
    onEdit,
    !isUpdate
  );

  const columns = [
    {
      name: 'product_name',
      label: 'Produto',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'category_description',
      label: 'Categoria',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'complement',
      label: 'Complemento',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'additional_value',
      label: 'Valor Adicional',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
  ];

  if (actions) {
    columns.push(actions);
  }

  return (
    <>
      <DialogForm
        titleDiag={affiliateProducts.product?.name || 'Itens'}
        open={open}
        maxForm="lg"
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
        cancelText="Fechar"
        hideSubmit
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ marginBottom: 3 }}>
          {isUpdate && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={edit ? 5 : 7}>
                <AutocompleteAsync
                  name="complement_item"
                  control={control}
                  title="Complemento"
                  required
                  url="complement/Items/all"
                  autoFocus
                  {...(edit ? { disabled: true } : { autoFocus: true })}
                  getLabel={(option: IComplementItems) =>
                    option.full_name || ''
                  }
                  disabled={edit || isSubmitting}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <NumberField
                  id="additional_value"
                  name="additional_value"
                  control={control}
                  defaultValue={0}
                  variant="standard"
                  fullWidth
                  label="Valor Adicional"
                  required
                  decimalScale={2}
                  InputLabelProps={{ shrink: true }}
                  disabled={isSubmitting}
                  startAdornment={
                    <InputAdornment position="start">
                      <Typography component="span" variant="caption">
                        R$
                      </Typography>
                    </InputAdornment>
                  }
                />
              </Grid>

              <Grid item xs={edit ? 6 : 12} md={2}>
                <Button
                  id="submit"
                  sx={{ mt: 3.6 }}
                  caption={edit ? 'Salvar' : 'Adicionar'}
                  type="submit"
                  fullWidth
                  loading={isSubmitting}
                />
              </Grid>

              {edit && (
                <Grid item xs={6} md={2}>
                  <Button
                    id="cancel"
                    sx={{ mt: 3.6 }}
                    caption="Cancelar"
                    fullWidth
                    disabled={isSubmitting}
                    onClick={() => {
                      resetForm();
                      setEdit(false);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
          <DataTable
            title={
              <Title
                description="Itens"
                isLoading={isLoading || isFetching || isLoadingX}
              />
            }
            data={complements || []}
            columns={columns}
            isLoading={isLoading}
            refresh={refresh}
            action={isUpdate ? cloneProduct : undefined}
            hideActionAddToolbar
          />
        </Box>
      </DialogForm>
      <DialogForm
        titleDiag="Importar Itens"
        open={isDialog}
        isSubmitting={isSubmitting}
        handleClose={handleCloseClone}
        submitText="Importar"
        cancelText="Fechar"
        onSubmit={handleSubmitClone(onSubmitClone)}
      >
        <Box sx={{ marginBottom: 3 }}>
          <AutocompleteAsync
            name="product"
            control={controlClone}
            variant="standard"
            shrink
            title="Produto de Origem"
            size="small"
            url="products?type=compound"
            getLabel={(option: IProduct) => option.name}
            disabled={isSubmitting}
            fullWidth
            required
            autoFocus
          />
          <FormControl variant="standard" sx={{ mt: 2 }} fullWidth>
            <InputLabel htmlFor="type-required" shrink>
              Tipo
            </InputLabel>
            <Controller
              name="type"
              control={controlClone}
              defaultValue="keep_and_upsert"
              render={({ field }) => (
                <Select
                  {...field}
                  sx={{ textAlign: 'left' }}
                  id="type"
                  variant="standard"
                  color="primary"
                  required
                  disabled={isSubmitting}
                  value={field.value || 'keep_and_upsert'}
                >
                  {typeCloneCompProduct.map(type => (
                    <MenuItem key={type.id} value={type.id}>
                      <Typography>{type.description}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Box>
      </DialogForm>
      <DialogX
        titleDiag={`${affiliateProductComplements.product_name} - ${affiliateProductComplements.category_description}`}
        open={isDialogX}
        onOk={() => onOk()}
        captionOk="Confirmar"
        captionCancel="Fechar"
        handleClose={handleCloseAffiliateProductComp}
        isLoading={isSubmitting}
        autoFocus
      >
        {isSubmitting ? (
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          <Typography variant="body2">
            Confirma a exclusão do complemento?
          </Typography>
        )}
      </DialogX>
    </>
  );
}
