import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from './store';
import { IUtility } from '../models';

export const utilitySlice = createSlice({
  name: 'utility',
  initialState: {
    drawerOpen: false,
    dialogOpen: false,
    affiliateSelOpen: false,
    detailOpen: false,
    dialogId: '',
    file: null,
    refresh: false,
  } as IUtility,
  reducers: {
    openDrawer: (state: IUtility) => {
      state.drawerOpen = true;
      return state;
    },
    closeDrawer: (state: IUtility) => {
      state.drawerOpen = false;
      return state;
    },
    openDialog: (state: IUtility, action: PayloadAction<string>) => {
      state.dialogId = action.payload;
      state.dialogOpen = true;
      state.file = null;
      return state;
    },
    closeDialog: (state: IUtility) => {
      state.dialogId = '';
      state.dialogOpen = false;
      state.file = null;
      return state;
    },
    openDetail: (state: IUtility) => {
      state.detailOpen = true;
      return state;
    },
    closeDetail: (state: IUtility) => {
      state.detailOpen = false;
      return state;
    },
    setFile: (state: IUtility, action: PayloadAction<File>) => {
      state.file = action.payload;
      return state;
    },
    removeFile: (state: IUtility) => {
      state.file = null;
      return state;
    },
    openAffiliateSel: (state: IUtility) => {
      state.affiliateSelOpen = true;
      return state;
    },
    closeAffiliateSel: (state: IUtility) => {
      state.affiliateSelOpen = false;
      return state;
    },
    setDialogId: (state: IUtility, action: PayloadAction<string>) => {
      state.dialogId = action.payload;
    },
    setRefresh: (state: IUtility, action: PayloadAction<boolean>) => {
      state.refresh = action.payload;
    },
  },
});

export const {
  openDrawer,
  closeDrawer,
  openDialog,
  closeDialog,
  openDetail,
  closeDetail,
  setFile,
  removeFile,
  openAffiliateSel,
  closeAffiliateSel,
  setDialogId,
  setRefresh,
} = utilitySlice.actions;

// export const utilitySelector = (state: RootState) => state.user;
