/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import NumberFormat from 'react-number-format';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Controller, UseControllerProps } from 'react-hook-form';
import { TextFieldStyled } from './styles';

interface Props {
  decimalScale?: number;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export default function NumberField({
  decimalScale,
  startAdornment,
  endAdornment,
  name,
  control,
  rules,
  defaultValue,
  ...textFieldProps
}: Props & TextFieldProps & UseControllerProps<any>): React.ReactElement {
  const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
    props: CustomProps,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        decimalScale={decimalScale}
        thousandSeparator="."
        decimalSeparator=","
        isNumericString
        allowNegative={false}
        prefix=""
        {...(decimalScale && decimalScale > 0 && { fixedDecimalScale: true })}
      />
    );
  });

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TextFieldStyled
          {...field}
          {...textFieldProps}
          margin="normal"
          onChange={field.onChange}
          value={field.value || 0}
          error={!!error}
          helperText={error ? error.message : null}
          ref={field.ref}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            startAdornment,
            endAdornment,
          }}
        />
      )}
    />
  );
}

NumberField.defaultProps = {
  decimalScale: 0,
  startAdornment: undefined,
  endAdornment: undefined,
};
