import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import {
  openDrawer,
  closeDrawer,
  openDialog,
  closeDialog,
  openDetail,
  closeDetail,
  setFile,
  removeFile,
  openAffiliateSel,
  closeAffiliateSel,
  setDialogId,
  setRefresh,
} from '../store/utility';

export default function useUtility() {
  const utilitySelector = useSelector((state: RootState) => state.utility);

  return {
    utilitySelector,
    openDrawer,
    closeDrawer,
    openDialog,
    closeDialog,
    openDetail,
    closeDetail,
    setFile,
    removeFile,
    openAffiliateSel,
    closeAffiliateSel,
    setDialogId,
    setRefresh,
  };
}
