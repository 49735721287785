import { IView } from '../models';
import useLogin from './useLogin';

export function useView() {
  const { userSelector } = useLogin();

  const reset = (permited: boolean) => {
    return {
      affiliate: permited,
      affiliateDeliveryArea: permited,
      category: permited,
      cashback: permited,
      customer: permited,
      complement: permited,
      product: permited,
      order: permited,
      invoice: permited,
      ordersPerDay: permited,
      topSellingProducts: permited,
      openClose: permited,
    } as IView;
  };

  if (userSelector.access.type === 'supervisor') {
    return reset(true);
  }

  if (
    userSelector.access.permissions &&
    userSelector.access.permissions.length > 0
  ) {
    const view = reset(false);

    userSelector.access.permissions.forEach(x => {
      if (x.slug === 'affiliateDeliveryArea.view' && x.value === 'true') {
        view.affiliateDeliveryArea = true;
      }

      if (x.slug === 'product.view' && x.value === 'true') {
        view.product = true;
      }

      if (x.slug === 'category.view' && x.value === 'true') {
        view.category = true;
      }

      if (x.slug === 'cashback.view' && x.value === 'true') {
        view.cashback = true;
      }

      if (x.slug === 'customer.view' && x.value === 'true') {
        view.customer = true;
      }

      if (x.slug === 'complement.view' && x.value === 'true') {
        view.complement = true;
      }

      if (x.slug === 'order.view' && x.value === 'true') {
        view.order = true;
      }

      if (x.slug === 'invoice.view' && x.value === 'true') {
        view.invoice = true;
      }

      if (x.slug === 'dashboard.ordersPerDay' && x.value === 'true') {
        view.ordersPerDay = true;
      }

      if (x.slug === 'dashboard.topSellingProducts' && x.value === 'true') {
        view.topSellingProducts = true;
      }

      if (x.slug === 'affiliate.update.openClose' && x.value === 'true') {
        view.openClose = true;
      }
    });

    return view;
  }

  return reset(false);
}
