/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  ListItemIcon,
  Typography,
} from '@mui/material';
import {
  AccountCircle,
  Business,
  Settings,
  ExitToApp,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import AvatarBadge from '../AvatarBadge';
import useLogin from '../../hooks/useLogin';
import { getInitials } from '../../utils';
import { Container } from './styles';
import MenuPopover from '../MenuPopover';
import api from '../../services/api';
import { IAffiliate } from '../../models';
import useUtility from '../../hooks/useUtility';
import AffiliateSelect from '../AffiliateSelect';
import snack from '../SnackbarUtilsConfigurator';
import { useCan } from '../../hooks/useCan';

function AvatarMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<IAffiliate[]>([]);
  const [open, setOpen] = useState(false);
  const { userSelector, logout } = useLogin();
  const { openAffiliateSel, closeAffiliateSel } = useUtility();
  const anchorRef = useRef<HTMLLIElement>(null);
  const isOrder = useCan({ view: 'order.view' });

  useEffect(() => {
    if (!open && openAffiliateSel()) {
      dispatch(closeAffiliateSel());
    }
  }, [open, openAffiliateSel, closeAffiliateSel, dispatch]);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleCloseConfiguration = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    navigate('/configuracoes');
  };

  const handleCloseLogout = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    dispatch(logout());
    setOpen(false);
    navigate('/');
  };

  const handleAccount = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    navigate('/perfil');
  };

  const onGetBranches = useCallback(async () => {
    try {
      await api.get<IAffiliate[]>(`affiliates`).then(response => {
        setData(response.data);
        dispatch(openAffiliateSel());
      });
    } catch (error) {
      if (error instanceof Error) {
        snack.error(error.message);
      }
    }
  }, [dispatch, openAffiliateSel]);

  const handleAffiliate = async () => {
    setOpen(false);
    await onGetBranches();
  };

  return (
    <Container>
      {userSelector.user && (
        <>
          <ListItem
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            alignItems="flex-start"
            className="paddingRightZero"
          >
            <ListItemAvatar>
              <AvatarBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
                sx={{ cursor: 'pointer' }}
              >
                <Avatar>{getInitials(userSelector.user.name)}</Avatar>
              </AvatarBadge>
            </ListItemAvatar>
          </ListItem>
          <MenuPopover
            open={open}
            anchorEl={anchorRef.current}
            sx={{ width: 220 }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  <div style={{ marginLeft: 20, marginBottom: 5 }}>
                    <Typography component="span" variant="subtitle2">
                      {userSelector.user.name}
                    </Typography>
                  </div>
                  <Divider />
                  <MenuItem onClick={handleAccount}>
                    <ListItemIcon className="menuIcon">
                      <AccountCircle fontSize="small" />
                    </ListItemIcon>
                    Perfil
                  </MenuItem>
                  {localStorage.getItem('affiliateSelection') && (
                    <MenuItem onClick={handleAffiliate}>
                      <ListItemIcon className="menuIcon">
                        <Business fontSize="small" />
                      </ListItemIcon>
                      Selecionar Filial
                    </MenuItem>
                  )}
                  {isOrder && (
                    <MenuItem onClick={handleCloseConfiguration}>
                      <ListItemIcon className="menuIcon">
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      Configurações
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleCloseLogout}>
                    <ListItemIcon className="menuIcon">
                      <ExitToApp fontSize="small" />
                    </ListItemIcon>
                    Sair
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </MenuPopover>
        </>
      )}
      <AffiliateSelect data={data} />
    </Container>
  );
}

export default AvatarMenu;
