import axios from 'axios';
import snack from '../components/SnackbarUtilsConfigurator';
import { IAffiliate } from '../models';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  const strAffiliate = localStorage.getItem('affiliate');

  if (config.headers) {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (strAffiliate && strAffiliate !== '') {
      const affiliate = JSON.parse(strAffiliate) as IAffiliate;

      if (affiliate && affiliate.id) {
        config.headers['affiliate-id'] = affiliate.id;
      }
    }
  }

  return config;
});

api.interceptors.response.use(
  response => response,
  error => {
    const token = localStorage.getItem('token');

    if (error.response) {
      if (token) {
        if (error.response.status === 401) {
          localStorage.removeItem('affiliate');
          localStorage.removeItem('affiliateSelection');
          localStorage.removeItem('token');
          localStorage.removeItem('access');
          window.location.href = '/';
        } else if (error.response.status === 500) {
          snack.error(error.response?.data?.message);
        } else if (error.response.status === 400) {
          snack.error(error.response?.data?.message);
        }
      } else if (
        error.response.status === 401 &&
        window.location.pathname !== '/'
      ) {
        window.location.href = '/';
      }
    } else {
      snack.error('Erro na comunicação com o servidor');
    }

    return Promise.reject(error);
  }
);

export default api;
