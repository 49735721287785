import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ListAlt,
  FilterAlt,
  ArrowBack,
  DeliveryDining,
  Cancel,
  Timelapse,
  LocalMall,
  CheckCircle,
  WhatsApp,
  LocationOn,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Button as ButtonUI,
  Tooltip,
  Typography,
  Stack,
  Divider,
  CircularProgress,
  Link,
  Rating,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import moment from 'moment';
import useSound from 'use-sound';
import { useSearchParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import ActionsDataTable from '../../components/ActionsDataTable';
import DataTable from '../../components/DataTable';
import DateX from '../../components/DateX';
import Form from '../../components/Form';
import OrderDetail from '../../components/OrderDetail';
import Page from '../../components/Page';
import PaperInfo from '../../components/PaperInfo';
import useLogin from '../../hooks/useLogin';
import { useOrders, getOrders, getOrder } from '../../services/hooks/useOrder';
import {
  IAffiliate,
  IAffiliateNewOrderSocket,
  IConfiguration,
  IFiltersDashboard,
  IGroupComplements,
  IOrder,
  IOrderItem,
  IOrderSituation,
  IPeriod,
} from '../../models';
import {
  formatCurrency,
  formatDate,
  formatDateInfo,
  formatDeliveryTime,
  isBirthday,
  isNotificationSupported,
  maskCPFCNPJ,
  maskPhone,
} from '../../utils';
import useUtility from '../../hooks/useUtility';
import Button from '../../components/Button';
import Label from '../../components/Label';
import snack from '../../components/SnackbarUtilsConfigurator';
import api from '../../services/api';
import { queryClient } from '../../services/queryClient';
import DialogX from '../../components/DialogX';
import notification from '../../sounds/notification.mp3';
import useInterval from '../../hooks/useInterval';
import ButtonPrint from '../../components/ButtonPrint';
import OrderPrint from '../../printers/Order/OrderPrint';
import { useCan } from '../../hooks/useCan';
import { modelPrintOrder } from '../../statics';

const schema = yup
  .object()
  .shape({
    dateInitial: yup.string().required().nullable(),
    dateFinal: yup
      .string()
      .required()
      // eslint-disable-next-line func-names
      .test('dateFinalAfterDateInitial', 'Inválido', function (dateFinal) {
        const { dateInitial } = this.parent;
        return moment(dateFinal).isSameOrAfter(moment(dateInitial));
      }),
  })
  .required();

yup.setLocale(ptForm);

export default function Order() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { userSelector } = useLogin();
  const [period, setPeriod] = useState<IPeriod>({
    start_date: new Date(),
    end_date: new Date(),
  });
  const [value, setValue] = useState(true);
  const [valuePlaced, setValuePlaced] = useState(true);
  const [orders, setOrders] = useState<IOrder[]>();
  const {
    data: ordersQuery,
    isLoading,
    isFetching,
  } = useOrders(userSelector.affiliate?.id || '', period, value);
  const { openDetail, closeDetail, utilitySelector } = useUtility();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isDetailLoading, setIsDetailLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [orderSituation, setOrderSituation] = useState<IOrderSituation>();
  const [modelPrint, setModelPrint] = useState('');
  const [play] = useSound(notification, { volume: 0.75 });
  const componentToPrint = useRef(null);

  const isUpdate = useCan({ view: 'order.update' });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm<IFiltersDashboard>({
    resolver: yupResolver(schema),
    defaultValues: {
      dateInitial: new Date(),
      dateFinal: new Date(),
    },
  });

  const detailOrderClose = () => {
    dispatch(closeDetail());
  };

  const onDetail = async (id: string, verifyDate?: boolean) => {
    if (userSelector.affiliate?.id) {
      dispatch(openDetail());

      setIsDetailLoading(true);
      await getOrder(userSelector.affiliate?.id, id)
        .then(result => {
          setOrder(result);
          setIsDetailLoading(false);

          if (verifyDate) {
            const date = formatDate(result.created_at?.toString());
            const dateInitial = formatDate(getValues('dateInitial').toString());
            const dateFinal = formatDate(getValues('dateFinal').toString());

            if (
              !moment(date).isBetween(dateInitial, dateFinal, undefined, '[]')
            ) {
              snack.info(
                `Pedido se encontra fora do período selecionado (${formatDateInfo(
                  date,
                  'DD/MM/YYYY'
                )})`
              );
            }
          }

          if (!result) {
            snack.warning('Pedido não localizado');
            detailOrderClose();
            setIsDetailLoading(false);
          }
        })
        .catch(() => {
          detailOrderClose();
          setIsDetailLoading(false);
        });
    }
  };

  useEffect(() => {
    const id = searchParams.get('id');

    if (id) {
      onDetail(id, true);
    } else if (utilitySelector.detailOpen) {
      dispatch(closeDetail());
    }

    const strConfigurations = localStorage.getItem('Configurations');

    if (strConfigurations) {
      const configurations = JSON.parse(strConfigurations) as IConfiguration;
      const model = modelPrintOrder.find(
        x => x.id === configurations.order_print_model
      );
      setModelPrint(model?.page_width || '80mm');
    } else {
      const configurations = {
        order_print_model: modelPrintOrder[0].id,
      } as IConfiguration;
      localStorage.setItem('Configurations', JSON.stringify(configurations));
      setModelPrint(modelPrintOrder[0].page_width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ordersQuery) {
      setOrders(ordersQuery);
    }
  }, [ordersQuery]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const jsonAffiliate = localStorage.getItem('affiliate');
    if (token && jsonAffiliate) {
      const affiliate = JSON.parse(jsonAffiliate) as IAffiliate;
      if (affiliate && affiliate.id) {
        if (isNotificationSupported()) {
          if (
            Notification.permission !== 'granted' &&
            Notification.permission !== 'denied'
          ) {
            Notification.requestPermission().then(permission => {
              if (permission === 'granted') {
                // As notificações foram habilitadas
              }
            });
          }
        }

        const socket = io(`${process.env.REACT_APP_BASE_URL}`, {
          auth: {
            token,
            affiliate_id: affiliate.id,
          },
        });

        socket.on(
          'onAffiliateNewOrder',
          async (newOrder: IAffiliateNewOrderSocket) => {
            queryClient.invalidateQueries([
              'orders',
              userSelector.affiliate?.id,
            ]);

            if (
              isNotificationSupported() &&
              Notification.permission === 'granted'
            ) {
              const pushNotification = new Notification(
                // eslint-disable-next-line prettier/prettier
                `Pedido Nº ${newOrder.number} - ${newOrder.delivery_way === 'delivery' ? 'Entrega' : 'Retirada'
                }`,
                {
                  // eslint-disable-next-line prettier/prettier
                  body: `Cliente: ${newOrder.customer_name}\nValor: ${formatCurrency(newOrder.order_value)}`,
                  icon: '/favicon.webp',
                }
              );
              pushNotification.onclick = () => {
                window.focus();
                onDetail(newOrder.id, false);
              };
            }
          }
        );

        return () => {
          socket.close();
        };
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function, prettier/prettier
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelector.affiliate?.id]);

  useInterval(() => {
    if (
      valuePlaced &&
      orders &&
      orders.length > 0 &&
      orders.find(x => x.situation === 'placed') !== undefined
    ) {
      play();
    }
  }, 10000);

  const onSubmit = async (data: IFiltersDashboard) => {
    if (isSubmitting) {
      return;
    }

    const periodX = { start_date: data.dateInitial, end_date: data.dateFinal };
    setPeriod(periodX);
    setIsDataLoading(true);
    try {
      const id = userSelector.affiliate?.id;

      if (!id) {
        snack.warning('Houve um erro ao carregar os dados da filial');
        return;
      }

      await getOrders(id, periodX)
        .then(result => setOrders(result))
        .catch(error => {
          if (error instanceof Error) {
            snack.error(error.message);
          }
        });
    } finally {
      setIsDataLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked);
  };

  const handleChangePlaced = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValuePlaced(event.target.checked);
  };

  const action: React.ReactNode = (
    <>
      <FormControlLabel
        style={{ marginLeft: 3 }}
        control={<Checkbox checked={value} onChange={handleChange} />}
        label="Auto refresh(60s)"
      />
      <FormControlLabel
        style={{ marginLeft: 3 }}
        control={
          <Checkbox checked={valuePlaced} onChange={handleChangePlaced} />
        }
        label="Aviso sonoro "
      />
    </>
  );

  const detailOrderOpen = async (index: number) => {
    if (orders) {
      const { id } = orders[index];

      await onDetail(id);
    } else {
      snack.warning('Erro inesperado da busca do pedido');
    }
  };

  const getDescriptionSituation = (orderX?: IOrder) => {
    const situationX = orderX ? orderX.situation : order.situation;
    const deliveryWayX = orderX ? orderX?.delivery_way : order.delivery_way;

    if (situationX === 'placed') {
      return 'Em Preparo';
    }

    if (situationX === 'in preparation') {
      if (deliveryWayX === 'delivery') {
        return 'Enviado Entrega';
      }
      return 'Disponível Retirada';
    }

    if (
      situationX === 'available for pickup' ||
      situationX === 'shipped for delivery'
    ) {
      return 'Entregue';
    }

    return '';
  };

  const getIconSituation = (orderX?: IOrder) => {
    const situationX = orderX ? orderX.situation : order.situation;
    const deliveryWayX = orderX ? orderX?.delivery_way : order.delivery_way;

    if (situationX === 'placed') {
      return <Timelapse />;
    }

    if (situationX === 'in preparation') {
      if (deliveryWayX === 'delivery') {
        return <DeliveryDining />;
      }
      return <LocalMall />;
    }

    if (
      situationX === 'available for pickup' ||
      situationX === 'shipped for delivery'
    ) {
      return <CheckCircle />;
    }

    return undefined;
  };

  const patchSituationOrder = useMutation(
    async () => {
      setIsStatusLoading(true);

      const obj = {
        situation: orderSituation?.situation,
      };

      const response = await api.patch(
        `order/situation/${orderSituation?.order_id}?affiliate_id=${userSelector.affiliate?.id}`,
        obj
      );

      return response.data;
    },
    {
      onSuccess() {
        snack.success('Processo concluído com sucesso!');
        queryClient.invalidateQueries(['orders', userSelector.affiliate?.id]);

        if (utilitySelector.detailOpen && orderSituation) {
          const orderX = order;
          orderX.situation = orderSituation.situation;
          orderX.situation_description = orderSituation.situation_description;
          setOrder(orderX);
        }

        setIsDialog(false);
        setIsStatusLoading(false);
      },
      onError() {
        setIsStatusLoading(false);
      },
    }
  );

  const getActionSituation = (orderX: IOrder) => {
    if (orderX.situation === 'placed') {
      return 'in preparation';
    }

    if (orderX.situation === 'in preparation') {
      if (orderX.delivery_way === 'delivery') {
        return 'shipped for delivery';
      }
      return 'available for pickup';
    }

    if (
      orderX.situation === 'available for pickup' ||
      orderX.situation === 'shipped for delivery'
    ) {
      return 'delivered';
    }

    return '';
  };

  const getActionDescriptionSituation = (orderX: IOrder) => {
    if (orderX.situation === 'placed') {
      return 'Em preparo';
    }

    if (orderX.situation === 'in preparation') {
      if (orderX.delivery_way === 'delivery') {
        return 'Disponível para entrega';
      }
      return 'Disponível para retirada';
    }

    if (
      orderX.situation === 'available for pickup' ||
      orderX.situation === 'shipped for delivery'
    ) {
      return 'Entregue';
    }

    return '';
  };

  const verifyFinishedOrder = (orderX?: IOrder) => {
    return (
      orderX &&
      orderX.situation !== 'delivered' &&
      orderX.situation !== 'canceled' &&
      orderX.situation !== 'rated'
    );
  };

  const setDialogOrder = (orderSituationX: IOrderSituation) => {
    setOrderSituation(orderSituationX);
    setIsDialog(true);
  };

  const actionDetail = (dataIndex: number) => {
    const orderX = orders ? orders[dataIndex] : undefined;
    const orderSituationX: IOrderSituation = {
      order_id: orderX?.id || '',
      situation: orderX ? getActionSituation(orderX) : '',
      number: orderX ? orderX.number : 0,
      situation_description: orderX
        ? getActionDescriptionSituation(orderX)
        : '',
    };

    return (
      <>
        {isUpdate && orderX && verifyFinishedOrder(orderX) && (
          <Tooltip title={getDescriptionSituation(orderX)} placement="top-end">
            <IconButton
              size="small"
              aria-label="detail data"
              onClick={() => setDialogOrder(orderSituationX)}
            >
              {getIconSituation(orderX)}
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Detalhe" placement="top-end">
          <IconButton
            size="small"
            aria-label="detail data"
            onClick={async () => detailOrderOpen(dataIndex)}
          >
            <ListAlt fontSize="small" />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const phoneMask = (valueX: string) => {
    return <div>{maskPhone(valueX)}</div>;
  };

  const dateFormat = (valueX: string) => {
    return valueX && <div>{formatDateInfo(valueX, 'DD/MM/YYYY HH:mm')}</div>;
  };

  const deliveryFormat = (valueX: string) => {
    return (
      valueX && <div>{valueX === 'delivery' ? 'Entrega' : 'Retirada'}</div>
    );
  };

  const getColorSituation = () => {
    if (order.situation === 'placed') {
      return 'warning';
    }

    if (order.situation === 'in preparation') {
      return 'secondary';
    }

    if (order.situation === 'available for pickup') {
      return 'success';
    }

    if (order.situation === 'shipped for delivery') {
      return 'success';
    }

    return 'secondary';
  };

  const getColorItemSituation = (situation: string) => {
    if (situation === 'Aguardando') {
      return 'info';
    }

    if (situation === 'Em preparo') {
      return 'warning';
    }

    if (situation === 'Cancelado') {
      return 'error';
    }

    if (situation === 'Disponível para entrega') {
      return 'secondary';
    }

    if (situation === 'Disponível para retirada') {
      return 'secondary';
    }

    if (situation === 'Entregue') {
      return 'success';
    }

    if (situation === 'Avaliado') {
      return 'primary';
    }

    return 'secondary';
  };

  const labelSituation = () => {
    return (
      <Label
        variant="ghost"
        color={getColorItemSituation(order.situation_description)}
      >
        {order.situation_description}
      </Label>
    );
  };

  const labelFormat = (situation: string) => {
    return (
      <Label variant="ghost" color={getColorItemSituation(situation)}>
        {situation}
      </Label>
    );
  };

  const actions = ActionsDataTable(
    false,
    orders || [],
    undefined,
    actionDetail,
    true
  );

  const columns = [
    {
      name: 'number',
      label: 'Número',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'situation_description',
      label: 'Situação',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (valueX: string) => labelFormat(valueX),
      },
    },
    {
      name: 'delivery_way',
      label: 'Canal',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (valueX: string) => deliveryFormat(valueX),
      },
    },
    {
      name: 'created_at',
      label: 'Data',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (valueX: string) => dateFormat(valueX),
      },
    },
    {
      name: 'customer_name',
      label: 'Cliente',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'customer_cellphone',
      label: 'Telefone',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (valueX: string) => phoneMask(valueX),
      },
    },
    {
      name: 'payment_description',
      label: 'Pagamento',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'order_value',
      label: 'Total',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (valueX: number) => formatCurrency(valueX),
      },
    },
  ];

  if (actions) {
    columns.push(actions);
  }

  const addressInfo = () => {
    return order.delivery_way === 'withdrawal'
      ? 'Endereço de Retirada'
      : 'Endereço de Entrega';
  };

  const getAddress = (orderX: IOrder) => {
    let address = '';
    const { affiliate } = userSelector;

    if (orderX.delivery_way === 'withdrawal' && affiliate) {
      address += `${affiliate.address}`;

      if (affiliate.address_number) {
        address += ` Nº ${affiliate.address_number}`;
      }

      if (affiliate.neighborhood_name) {
        address += `, ${affiliate.neighborhood_name}`;
      }

      if (affiliate.citie_name) {
        address += `, ${affiliate.citie_name}`;
      }

      if (affiliate.citie_state) {
        address += `-${affiliate.citie_state}`;
      }
    } else {
      if (orderX.address_street) {
        address += `${orderX.address_street}`;
      }

      if (orderX.address_number) {
        address += ` Nº ${orderX.address_number}`;
      }

      if (orderX.neighborhood_name) {
        address += `, ${orderX.neighborhood_name}`;
      }

      if (orderX.city_name) {
        address += `, ${orderX.city_name}`;
      }

      if (orderX.city_state) {
        address += `-${orderX.city_state}`;
      }
    }

    return address !== '' ? address : undefined;
  };

  const getGroupComplements = useCallback((items: IOrderItem[], id: string) => {
    const group: IGroupComplements[] = [];

    if (items) {
      items
        .filter(i => i.complements && i.complements.length > 0 && i.id === id)
        .forEach(x => {
          if (x.complements) {
            x.complements.forEach(c => {
              if (group.find(g => g.id === c.complement_id) === undefined) {
                group.push({
                  id: c.complement_id,
                  description: c.complement_description,
                });
              }
            });
          }
        });
    }

    return group;
  }, []);

  const getColorDeliveryWay = () => {
    return order.delivery_fee_value > 0 && order.delivery_way === 'delivery'
      ? undefined
      : 'green';
  };

  const getButtonSituation = () => {
    const orderSituationX: IOrderSituation = {
      order_id: order.id,
      situation: getActionSituation(order),
      number: order.number,
      situation_description: getActionDescriptionSituation(order),
    };

    return (
      <Button
        sx={{ width: 180 }}
        caption={getDescriptionSituation()}
        color={getColorSituation()}
        startIcon={getIconSituation()}
        onClick={() => setDialogOrder(orderSituationX)}
      />
    );
  };

  const onOk = () => {
    patchSituationOrder.mutateAsync();
  };

  const handleClose = () => setIsDialog(false);

  const getAffiliateInfo = () => {
    if (!userSelector.affiliate) {
      return '';
    }

    if (userSelector.affiliate?.fantasy_name !== null) {
      return userSelector.affiliate?.fantasy_name;
    }

    return userSelector.affiliate?.corporate_name;
  };

  const getDeliveryTime = (orderX: IOrder) => {
    if (orderX.delivery_way === 'delivery') {
      return formatDeliveryTime(userSelector.affiliate?.delivery_time_final);
    }

    return formatDeliveryTime(userSelector.affiliate?.withdrawal_time_final);
  };

  return (
    <Page hideInfoCompany>
      <PaperInfo>
        <Box
          justifyContent="space-between"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            pt: 1,
            pb: 0.5,
          }}
        >
          <Typography
            component="span"
            variant="h5"
            sx={{ color: 'black', mt: 0.5 }}
          >
            {userSelector.affiliate?.fantasy_name}
            {(isDataLoading || isFetching) && (
              <CircularProgress color="primary" size={18} sx={{ ml: 3 }} />
            )}
          </Typography>

          <Form onSubmit={handleSubmit(onSubmit)} hideSubmit>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5} sm={6}>
                <DateX
                  title="Inicial"
                  name="dateInitial"
                  control={control}
                  disableFuture
                />
              </Grid>
              <Grid item xs={12} md={5} sm={6}>
                <DateX
                  title="Final"
                  name="dateFinal"
                  control={control}
                  disableFuture
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  id="submit"
                  caption="Filtrar"
                  type="submit"
                  fullWidth
                  startIcon={<FilterAlt />}
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
      </PaperInfo>

      <DataTable
        title="Pedidos"
        data={orders || []}
        columns={columns}
        action={() => action}
        rowsPerPage={10}
        isLoading={isLoading}
        hideActionAddToolbar
        setRowProps={(row, dataIndex) => {
          return {
            style:
              orders && orders[dataIndex].situation === 'placed'
                ? { backgroundColor: '#FFF7CD' }
                : {},
          };
        }}
      />
      <OrderDetail isLoading={isDetailLoading}>
        <ButtonUI
          sx={{
            mt: 8,
            textTransform: 'none',
            textAlign: 'start',
            justifyContent: 'start',
          }}
          variant="text"
          startIcon={<ArrowBack />}
          onClick={() => detailOrderClose()}
        >
          Voltar para Pedidos
        </ButtonUI>

        <Stack direction="row" mt={1}>
          <Typography component="span" variant="h5" sx={{ color: 'black' }}>
            Pedido Nº {order.number}
          </Typography>

          <Stack sx={{ mt: 0.6, ml: 2 }}>{labelSituation()}</Stack>
        </Stack>

        {order.created_at && (
          <Typography component="span" variant="caption">
            {formatDateInfo(order.created_at.toString(), 'DD/MM/YYYY HH:mm')}
          </Typography>
        )}

        {order.situation === 'rated' && (
          <Rating
            name="assessment"
            value={order.rating}
            precision={0.1}
            size="medium"
            sx={{ mt: 1 }}
            readOnly
          />
        )}

        {isUpdate &&
          order.situation !== 'delivered' &&
          order.situation !== 'canceled' &&
          order.situation !== 'rated' && (
            <Stack direction="row" sx={{ mt: 2 }}>
              {getButtonSituation()}

              <ButtonUI
                sx={{
                  textTransform: 'none',
                  textAlign: 'start',
                  width: 180,
                  ml: 1,
                }}
                variant="outlined"
                startIcon={<Cancel />}
                onClick={() =>
                  setDialogOrder({
                    order_id: order.id,
                    situation: 'canceled',
                    number: order.number,
                    situation_description: 'Cancelado',
                  })
                }
              >
                Cancelar
              </ButtonUI>
            </Stack>
          )}

        <Divider sx={{ mt: 2 }} />

        <Stack>
          <Stack direction="row">
            <Typography
              component="span"
              variant="h6"
              sx={{ color: 'black', mt: 2 }}
            >
              Detalhes do Pedido
            </Typography>

            <ButtonPrint
              componentToPrint={componentToPrint}
              pageStyle={`@page { size: ${modelPrint} 100vh; margin: 0; }`}
            />
            <div style={{ display: 'none' }}>
              <OrderPrint
                componentToPrint={componentToPrint}
                data={order}
                affiliateInfo={getAffiliateInfo()}
                smallFont={modelPrint === '58mm'}
              />
            </div>
          </Stack>

          {isBirthday(order.customer_date_birth) && (
            <Typography
              variant="body2"
              fontWeight="bold"
              mt={1}
              pt={0.5}
              pb={0.5}
              pr={1}
              pl={1}
              borderRadius={2}
              sx={{ backgroundColor: 'primary.lighter' }}
            >
              🎂&nbsp;&nbsp;&nbsp;É aniversário do seu cliente!
            </Typography>
          )}

          {order.first_order && (
            <Typography
              variant="body2"
              fontWeight="bold"
              mt={1}
              pt={0.5}
              pb={0.5}
              pr={1}
              pl={1}
              borderRadius={2}
              sx={{ backgroundColor: 'primary.lighter' }}
            >
              🎉&nbsp;&nbsp;&nbsp;Primeiro pedido na sua loja!
            </Typography>
          )}

          <Typography variant="body1" fontWeight="bold" mt={1}>
            {order.customer_name}
          </Typography>

          <Stack direction="row" sx={{ pb: 2 }}>
            <Typography variant="body1">
              {`CPF: ${maskCPFCNPJ(order.customer_cpf_cnpj)}`}
            </Typography>
            <Typography variant="body1" sx={{ ml: 2 }}>
              {`Celular: ${maskPhone(order.customer_cellphone)}`}
            </Typography>
            <Link
              target="_blank"
              href={`https://wa.me/+55${order.customer_cellphone}?text=Mercurius Delivery | Pedido: ${order.number}`}
              sx={{ ml: 1 }}
            >
              <WhatsApp sx={{ color: '#25d366' }} />
            </Link>
          </Stack>
        </Stack>

        {order.items?.map(item => (
          <Stack key={item.id} spacing={0} pt={0} pl={2} pb={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                {`${item.quantity}x ${item.product_name} `}
              </Typography>
              <Typography variant="body2">
                {formatCurrency(item.gross_value)}
              </Typography>
            </Stack>

            {getGroupComplements(order.items, item.id || '')?.map(g => (
              <Stack key={g.id} spacing={0} pl={2} pr={2}>
                <Typography variant="caption" fontWeight="bold">
                  {g.description}
                </Typography>
                {item.complements &&
                  item.complements
                    .filter(y => y.complement_id === g.id)
                    ?.map(comp => (
                      <Typography variant="caption">
                        {`${comp.quantity}x ${comp.product_name} `}
                      </Typography>
                    ))}
              </Stack>
            ))}
            {item.observation && (
              <Typography variant="caption" fontWeight="bold">
                Observação: {item.observation}
              </Typography>
            )}
            <Divider sx={{ pt: 2 }} />
          </Stack>
        ))}

        {order.product_value !== order.order_value && (
          <Stack direction="row" justifyContent="space-between" mt={1}>
            <Typography variant="body2">Subtotal</Typography>
            <Typography variant="body2">
              {formatCurrency(order.product_value)}
            </Typography>
          </Stack>
        )}

        {order.discount_value > 0 && (
          <Stack direction="row" justifyContent="space-between" mt={1}>
            <Typography variant="body2" color="green">
              {`Desconto (${order.payment_discount_percentage}%)`}
            </Typography>

            <Typography variant="body2" color="green">
              - {formatCurrency(order.discount_value)}
            </Typography>
          </Stack>
        )}

        {order.cashback_rescue_value > 0 && (
          <Stack direction="row" justifyContent="space-between" mt={1}>
            <Typography variant="body2" color="green">
              Resgate de Cashback
            </Typography>

            <Typography variant="body2" color="green">
              - {formatCurrency(order.cashback_rescue_value)}
            </Typography>
          </Stack>
        )}

        {order.delivery_way === 'delivery' && (
          <Stack direction="row" justifyContent="space-between" mt={1}>
            <Typography variant="body2" color={getColorDeliveryWay()}>
              Taxa de Entrega
            </Typography>

            <Typography variant="body2" color={getColorDeliveryWay()}>
              {order.delivery_fee_value > 0 && order.delivery_way === 'delivery'
                ? formatCurrency(order.delivery_fee_value)
                : 'Grátis'}
            </Typography>
          </Stack>
        )}

        <Stack direction="row" justifyContent="space-between" mt={1}>
          <Typography variant="body2" fontWeight="bold">
            Total
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {formatCurrency(order.order_value)}
          </Typography>
        </Stack>

        <Divider sx={{ mt: 2 }} />

        <Typography variant="body1" fontWeight="bold" mt={2}>
          Forma de Pagamento
        </Typography>

        <Typography variant="body2">{order.payment_description}</Typography>

        <Typography variant="body1" fontWeight="bold" mt={2}>
          Tempo Estimado de{' '}
          {order.delivery_way === 'delivery' ? 'Entrega' : 'Retirada'}
        </Typography>

        <Typography variant="body2">{getDeliveryTime(order)}</Typography>

        <Stack direction="row">
          <Typography variant="body1" fontWeight="bold" mt={2}>
            {addressInfo()}
          </Typography>
          {order.delivery_way === 'delivery' &&
            order.address_longitude &&
            order.address_latitude && (
              <Link
                target="_blank"
                href={`https://www.google.com/maps/search/?api=1&query=${order.address_latitude},${order.address_longitude}`}
                sx={{ ml: 1, mt: 1.4 }}
              >
                <LocationOn sx={{ color: 'primary.main' }} />
              </Link>
            )}
        </Stack>

        <Typography variant="body2">{getAddress(order)}</Typography>

        {order.delivery_way === 'delivery' && order.address_complement && (
          <>
            <Typography variant="body1" fontWeight="bold" mt={2}>
              Complemento
            </Typography>

            <Typography variant="body2">{order.address_complement}</Typography>
          </>
        )}

        {order.delivery_way === 'delivery' && order.address_reference_point && (
          <>
            <Typography variant="body1" fontWeight="bold" mt={2}>
              Ponto de Referência
            </Typography>

            <Typography variant="body2">
              {order.address_reference_point}
            </Typography>
          </>
        )}

        {order.delivery_observation && (
          <Stack mt={2}>
            <Typography variant="body1" fontWeight="bold">
              Observação
            </Typography>

            <Typography variant="body2">
              {order.delivery_observation}
            </Typography>
          </Stack>
        )}
      </OrderDetail>
      <DialogX
        titleDiag={`Pedido ${orderSituation?.number}`}
        open={isDialog}
        onOk={() => onOk()}
        captionOk="Confirmar"
        captionCancel="Fechar"
        handleClose={handleClose}
        isLoading={isStatusLoading}
        autoFocus
      >
        {isStatusLoading ? (
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          <Typography variant="body2">
            {`Confirma a alteração do status do pedido para "${orderSituation?.situation_description}"?`}
          </Typography>
        )}
      </DialogX>
    </Page>
  );
}
