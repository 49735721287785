import React from 'react';
import { Grid } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';
import AutocompleteAsync from '../AutocompleteAsync';
import DateX from '../DateX';
import { IAffiliate } from '../../models';
import Button from '../Button';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  isSelAffiliate: boolean;
}

export default function FilterReport({
  control,
  isSelAffiliate,
}: Props): React.ReactElement {
  return (
    <Grid container spacing={2}>
      {isSelAffiliate && (
        <Grid item xs={12} md={4} sm={12}>
          <AutocompleteAsync
            name="affiliate"
            control={control}
            variant="outlined"
            title="Filial"
            size="small"
            url="affiliates"
            getLabel={(option: IAffiliate) => option.fantasy_name}
            fullWidth
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={isSelAffiliate ? 3 : 5}
        sm={6}
        sx={{ mt: isSelAffiliate ? 2 : 0 }}
      >
        <DateX title="Inicial" name="dateInitial" control={control} />
      </Grid>
      <Grid
        item
        xs={12}
        md={isSelAffiliate ? 3 : 5}
        sm={6}
        sx={{ mt: isSelAffiliate ? 2 : 0 }}
      >
        <DateX title="Final" name="dateFinal" control={control} disableFuture />
      </Grid>
      <Grid item xs={12} md={2} sx={{ mt: isSelAffiliate ? 2 : 0 }} sm={12}>
        <Button
          id="submit"
          caption="Filtrar"
          type="submit"
          fullWidth
          startIcon={<FilterAlt />}
        />
      </Grid>
    </Grid>
  );
}
