import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  Stack,
} from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';
import { DeleteOutline } from '@mui/icons-material';
import snack from '../SnackbarUtilsConfigurator';
import useUtility from '../../hooks/useUtility';

interface Props {
  image?: string;
  isSubmitting?: boolean;
  afterDelete?: () => void;
}

export default function ImagePreview({
  image,
  isSubmitting,
  afterDelete,
  ...boxProps
}: Props & BoxProps) {
  const urlNoProduct = 'product-without-image.webp' || '';
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imagePreview, setImagePreview] = useState<string>(
    image || urlNoProduct
  );
  const { setFile, removeFile } = useUtility();
  const dispatch = useDispatch();

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _ = e.target.files && e.target.files[0];

    if (_) {
      if (_.size > 1048576) {
        snack.warning('O tamanho máximo da foto é de 1MB');
        return;
      }

      const ext = _.name.substring(_.name.lastIndexOf('.') + 1);

      if (ext !== 'png' && ext !== 'webp' && ext !== 'jpg' && ext !== 'jpeg') {
        snack.warning(`Não é permitido enviar a image nesse formato (.${ext})`);
        return;
      }

      setImagePreview(URL.createObjectURL(_));

      const fileX = e.target.files?.item(0);

      if (fileX) dispatch(setFile(fileX));
    }
  };

  const handleAttach = () => {
    if (!isSubmitting) {
      fileInputRef.current?.click();
    }
  };

  const onDelete = () => {
    setImagePreview(urlNoProduct);
    dispatch(removeFile());

    if (afterDelete) {
      afterDelete();
    }
  };

  useEffect(() => {
    if (image) {
      setImagePreview(image);
    } else {
      setImagePreview(urlNoProduct);
    }
  }, [image, urlNoProduct]);

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography
          sx={{
            mt: 1,
            mb: imagePreview === urlNoProduct ? 0.5 : 0,
            color: '#637381',
          }}
          component="span"
          variant="caption"
        >
          Máximo de 1MB (.png/.webp/.jpg/.jpeg){' '}
        </Typography>

        {!isSubmitting && imagePreview && imagePreview !== urlNoProduct && (
          <Tooltip title="Excluir" placement="top-end">
            <IconButton size="small" aria-label="excluir" onClick={onDelete}>
              <DeleteOutline
                sx={{
                  color:
                    imagePreview === urlNoProduct
                      ? 'transparent'
                      : 'primary.main',
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <Card>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            {...boxProps}
            component="img"
            onClick={handleAttach}
            alt="Imagem"
            src={imagePreview}
          />
        </CardContent>
        <input
          style={{ display: 'none' }}
          ref={fileInputRef}
          type="file"
          onChange={handleFileInputChange}
          accept="image/png, image/webp, image/jpg, image/jpeg"
        />
      </Card>
    </>
  );
}

ImagePreview.defaultProps = {
  image: undefined,
  isSubmitting: false,
  afterDelete: undefined,
};
