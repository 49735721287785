/* eslint-disable no-empty */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import ActionsDataTable from '../../components/ActionsDataTable';
import useUtility from '../../hooks/useUtility';
import DataTable from '../../components/DataTable';
import DialogForm from '../../components/DialogForm';
import Page from '../../components/Page';
import Label from '../../components/Label';
import { IProduct } from '../../models';
import Form from './Form';
import ImagePreview from '../../components/ImagePreview';
import Title from '../../components/Title';
import {
  getProduct,
  getProducts,
  useProducts,
} from '../../services/hooks/useProduct';
import api from '../../services/api';
import { queryClient } from '../../services/queryClient';
import { useCan } from '../../hooks/useCan';

const schema = yup
  .object()
  .shape({
    category: yup.object().required().nullable(),
    type: yup.string().required().nullable(),
    name: yup.string().required().nullable(),
  })
  .required();

yup.setLocale(ptForm);
yup.setLocale({ mixed: { notType: 'Valor inválido.' } });

export default function Product() {
  const dispatch = useDispatch();
  const { closeDialog, utilitySelector } = useUtility();
  const { data, isLoading, isFetching } = useProducts();
  const [products, setProducts] = useState<IProduct[]>();
  const [isLoadingX, setIsLoadingX] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, reset, getValues } = useForm<IProduct>({
    resolver: yupResolver(schema),
  });
  const [imageUrl, setImageUrl] = useState<string>();

  const isCreate = useCan({ view: 'product.create' });
  const isUpdate = useCan({ view: 'product.update' });

  useEffect(() => {
    if (utilitySelector.dialogOpen) {
      if (utilitySelector.dialogId) {
        setImageUrl(undefined);
        setIsLoadingX(true);
        getProduct(utilitySelector.dialogId)
          .then(response => {
            reset(response);

            if (response?.image_url !== '') {
              setImageUrl(response?.image_url);
            }
          })
          .finally(() => setIsLoadingX(false));
      } else {
        setImageUrl(undefined);
        reset({});
      }
    }
  }, [utilitySelector.dialogOpen, utilitySelector.dialogId, reset]);

  useEffect(() => {
    setProducts(data);
  }, [data]);

  // const activeTemplate = (value: boolean) => {
  //   return (
  //     <Label variant="ghost" color={(!value && 'error') || 'success'}>
  //       {value ? 'Sim' : 'Não'}
  //     </Label>
  //   );
  // };

  const typeTemplate = (value: string) => {
    return (
      <Label
        variant="ghost"
        color={
          (value === 'Simples' && 'primary') ||
          (value === 'Composto' && 'info') ||
          'success'
        }
      >
        {value}
      </Label>
    );
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const mapColumns = (product: IProduct): IProduct => {
    const result: IProduct = {
      category_id: product.category?.id || null,
      type: product.type,
      name: product.name,
      description: product.description !== '' ? product.description : null,
      frosty: product.frosty,
      // active: product.active,
    };

    return result;
  };

  const createOrUpdateProduct = useMutation(
    async (product: IProduct) => {
      const obj = mapColumns(product);

      if (utilitySelector.dialogId) {
        const responseX = await api.put<IProduct>(
          `products/${product.id}`,
          obj
        );

        return responseX.data;
      }

      const response = await api.post<IProduct>(`products`, obj);

      return response.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries('products');
        dispatch(closeDialog());
      },
    }
  );

  const onSubmit = async (formData: IProduct) => {
    const { file } = utilitySelector;
    const urlImageData = getValues('image_url');

    const upload =
      (utilitySelector.dialogId === '' && !file) ||
      (imageUrl && !file) ||
      (!imageUrl && !file && !urlImageData);

    const imageUpload = async (id: string) => {
      if (upload) {
        return;
      }

      const objFile = new FormData();

      if (file) objFile.append('image', file);

      await api.patch(`products/image/${id}`, objFile, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    };

    setIsSubmitting(true);
    await createOrUpdateProduct
      .mutateAsync(formData)
      .then(async result => {
        if (result.id) {
          try {
            await imageUpload(result.id);
            // eslint-disable-next-line prettier/prettier
          } catch { }
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  // const activeProduct = async (product: IProduct) => {
  //   product.active = !product.active;
  //   if (product.id) await update(product.id, mapColumns(product));
  // };

  const actions = ActionsDataTable(
    true,
    products || [],
    undefined,
    undefined,
    !isUpdate
  );

  const columns = [
    {
      name: 'name',
      label: 'Nome do Produto',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Descrição do Produto',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'category_description',
      label: 'Categoria',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'type_description',
      label: 'Tipo do Produto',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => typeTemplate(value),
      },
    },
    // {
    //   name: 'active',
    //   label: 'Ativo',
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value: boolean) => activeTemplate(value),
    //   },
    // },
  ];

  if (actions) {
    columns.push(actions);
  }

  const afterDelete = () => {
    setImageUrl(undefined);
  };

  const refresh = async () => {
    setIsLoadingX(true);
    return getProducts()
      .then(result => setProducts(result))
      .finally(() => setIsLoadingX(false));
  };

  return (
    <Page>
      <DataTable
        title={
          <Title
            description="Produtos"
            isLoading={isLoading || isFetching || isLoadingX}
          />
        }
        data={products || []}
        columns={columns}
        isLoading={isLoading || isFetching}
        refresh={refresh}
        hideActionAddToolbar={!isCreate}
      />
      <DialogForm
        titleDiag="Produto"
        maxForm="lg"
        open={utilitySelector.dialogOpen}
        isLoading={isLoadingX}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form control={control} isSubmitting={isSubmitting}>
          <ImagePreview
            image={imageUrl}
            isSubmitting={isSubmitting}
            afterDelete={afterDelete}
            sx={{
              height: 144,
              // width: '100%',
              maxHeight: { xs: 233, md: 144 },
              // maxWidth: { xs: 350, md: 250 },
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              cursor: 'pointer',
            }}
          />
        </Form>
      </DialogForm>
    </Page>
  );
}
