import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import TextField from '../../components/TextField';
import { orderCategory } from '../../statics';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  isSubmitting?: boolean;
}

export default function Form({ control, isSubmitting }: Props) {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <TextField
          id="description"
          name="description"
          control={control}
          defaultValue=""
          variant="standard"
          fullWidth
          label="Descrição da Categoria"
          required
          autoFocus
          InputLabelProps={{ shrink: true }}
          disabled={isSubmitting}
        />
      </Grid>

      <Grid item md={7} xs={6}>
        <TextField
          id="priority"
          name="priority"
          control={control}
          variant="standard"
          fullWidth
          label="Prioridade"
          required
          InputLabelProps={{ shrink: true }}
          inputProps={{ type: 'number', min: 0, max: 999 }}
          disabled={isSubmitting}
        />
      </Grid>

      <Grid item md={5} xs={6}>
        <FormControl variant="standard" sx={{ mt: 2 }} fullWidth>
          <InputLabel htmlFor="type-required" shrink>
            Ordenar Itens Por
          </InputLabel>
          <Controller
            name="order"
            control={control}
            defaultValue="asc_name"
            render={({ field }) => (
              <Select
                {...field}
                sx={{ textAlign: 'left' }}
                id="order"
                variant="standard"
                color="primary"
                required
                disabled={isSubmitting}
                value={field.value || 'asc_name'}
              >
                {orderCategory.map(category => (
                  <MenuItem key={category.id} value={category.id}>
                    <Typography>{category.description}</Typography>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item md={12} xs={12}>
        <FormControlLabel
          sx={{ width: '100%' }}
          control={
            <Controller
              name="active"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="active"
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                  disabled={isSubmitting}
                />
              )}
            />
          }
          label="Ativo"
        />
      </Grid>
    </Grid>
  );
}

Form.defaultProps = {
  isSubmitting: false,
};
