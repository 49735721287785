import styled from 'styled-components';

export const Container = styled.div`
  .inline {
    display: 'inline';
    color: '#fff';
  }

  .menuIcon {
    min-width: '33px';
  }

  .paddingRightZero {
    padding-right: 0;
  }
`;
