import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Box,
  Hidden,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { ptForm } from 'yup-locale-pt';
import { SelectableRows } from 'mui-datatables';
import { makeStyles } from '@mui/styles';
import DataTable from '../../components/DataTable';
import Page from '../../components/Page';
import { IFiltersDashboard, IReportOrdersPerDay } from '../../models';
import Title from '../../components/Title';
import { getOrdersPerDay } from '../../services/hooks/useDashboard';
import snack from '../../components/SnackbarUtilsConfigurator';
import PaperInfo from '../../components/PaperInfo';
import useLogin from '../../hooks/useLogin';
import Form from '../../components/Form';
import ButtonPrint from '../../components/ButtonPrint';
import FilterReport from '../../components/FilterReport';
import { formatDateInfo } from '../../utils';
import ReportOrdersPerDay from '../../reports/ReportOrdersPerDay';

const schema = yup
  .object()
  .shape({
    dateInitial: yup.string().required().nullable(),
    dateFinal: yup
      .string()
      .required()
      // eslint-disable-next-line func-names
      .test('dateFinalAfterDateInitial', 'Inválido', function (dateFinal) {
        const { dateInitial } = this.parent;
        return moment(dateFinal).isSameOrAfter(moment(dateInitial));
      }),
  })
  .required();

yup.setLocale(ptForm);

const useStyles = makeStyles(() => ({
  footerCell: {
    borderBottom: 'none',
    color: 'black',
    fontSize: 16,
    fontWeight: 'bolder',
  },
}));

export default function OrdersPerDay() {
  const { userSelector } = useLogin();
  const [data, setData] = useState<IReportOrdersPerDay[]>([]);
  const [datePrint, setDatePrint] = useState<Date>();
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  const componentToPrint = useRef(null);

  const theme = useTheme();
  const isBreakMd = useMediaQuery(theme.breakpoints.up('md'));

  const { control, handleSubmit, getValues } = useForm<IFiltersDashboard>({
    resolver: yupResolver(schema),
    defaultValues: {
      dateInitial: moment().startOf('month').toDate(),
      dateFinal: moment().endOf('month').toDate(),
    },
  });

  const fetch = useCallback(async (filterX: IFiltersDashboard) => {
    setIsLoading(true);
    await getOrdersPerDay(filterX)
      .then(result => {
        setData(result);
      })
      .catch(error => {
        if (error instanceof Error) {
          snack.error(error.message);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const filterX: IFiltersDashboard = {} as IFiltersDashboard;
    filterX.dateInitial = getValues('dateInitial');
    filterX.dateFinal = getValues('dateFinal');
    fetch(filterX);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const onSubmit = async (dataX: IFiltersDashboard) => {
    fetch(dataX);
  };

  const isSelAffiliate = localStorage.getItem('affiliateSelection') !== null;

  const formatValue = (value: string) => {
    return parseFloat(value).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const numberTemplate = (value: string) => {
    return <div>{formatValue(value)}</div>;
  };

  const dateFormat = (value: string) => {
    return value && <div>{formatDateInfo(value, 'DD/MM/YYYY')}</div>;
  };

  const columns = [
    {
      name: 'date',
      label: 'Data',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => dateFormat(value),
      },
    },
    {
      name: 'count',
      label: 'Quantidade',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'product_value',
      label: 'Produtos',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'delivery_fee_value',
      label: 'Entrega',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'discount_value',
      label: 'Desconto',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'order_value',
      label: 'Pedidos',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'pm_money_value',
      label: 'Dinheiro',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'pm_pix_value',
      label: 'Pix',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'pm_credit_card_value',
      label: 'Crédito',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'pm_debit_card_value',
      label: 'Débito',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'pm_meal_ticket_value',
      label: 'Tic. Ref.',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'pm_other_value',
      label: 'Outros',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
  ];

  const onBeforeGetContent = () => {
    setDatePrint(new Date());
  };

  const actionPrint = () => {
    if (data.length === 0) {
      return undefined;
    }

    return (
      <ButtonPrint
        componentToPrint={componentToPrint}
        size="small"
        pageStyle="@page { size: landscape; margin: 10mm 0 0 0; }"
        onBeforeGetContent={onBeforeGetContent}
      />
    );
  };

  const customTableBodyFooterRender = (options: {
    data: IReportOrdersPerDay[];
    selectableRows: SelectableRows;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: any[];
  }) => {
    if (data.length === 0) {
      return undefined;
    }

    const countX = data.reduce((accu, item) => {
      return accu + item.count;
    }, 0);

    const productValue = data.reduce((accu, item) => {
      return accu + item.product_value;
    }, 0);

    const deliveryFeeValue = data.reduce((accu, item) => {
      return accu + item.delivery_fee_value;
    }, 0);

    const discountValue = data.reduce((accu, item) => {
      return accu + item.discount_value;
    }, 0);

    const orderValue = data.reduce((accu, item) => {
      return accu + item.order_value;
    }, 0);

    const pmMoneyValue = data.reduce((accu, item) => {
      return accu + item.pm_money_value;
    }, 0);

    const pmPixValue = data.reduce((accu, item) => {
      return accu + item.pm_pix_value;
    }, 0);

    const pmCreditCardValue = data.reduce((accu, item) => {
      return accu + item.pm_credit_card_value;
    }, 0);

    const pmDebitCardValue = data.reduce((accu, item) => {
      return accu + item.pm_debit_card_value;
    }, 0);

    const pmMealTicketValue = data.reduce((accu, item) => {
      return accu + item.pm_meal_ticket_value;
    }, 0);

    const pmOtherValue = data.reduce((accu, item) => {
      return accu + item.pm_other_value;
    }, 0);

    if (!isBreakMd) {
      return (
        <TableFooter>
          <TableRow
            sx={{
              backgroundColor: 'primary.lighter',
            }}
          >
            <TableCell
              className={classes.footerCell}
              sx={{
                textAlign: 'center',
              }}
            >
              Total
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(241,243,244,1)',
            }}
          >
            <TableCell className={classes.footerCell}>Quantidade</TableCell>

            <TableCell className={classes.footerCell}>{countX}</TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(241,243,244,1)',
            }}
          >
            <TableCell className={classes.footerCell}>Valor Produtos</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(productValue.toString())}
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(241,243,244,1)',
            }}
          >
            <TableCell className={classes.footerCell}>Taxa Ent.</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(deliveryFeeValue.toString())}
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(241,243,244,1)',
            }}
          >
            <TableCell className={classes.footerCell}>Desconto</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(discountValue.toString())}
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(241,243,244,1)',
            }}
          >
            <TableCell className={classes.footerCell}>Pedido</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(orderValue.toString())}
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(241,243,244,1)',
            }}
          >
            <TableCell className={classes.footerCell}>Dinheiro</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(pmMoneyValue.toString())}
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(241,243,244,1)',
            }}
          >
            <TableCell className={classes.footerCell}>Pix</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(pmPixValue.toString())}
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(241,243,244,1)',
            }}
          >
            <TableCell className={classes.footerCell}>Cart. Crédito</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(pmCreditCardValue.toString())}
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(241,243,244,1)',
            }}
          >
            <TableCell className={classes.footerCell}>Cart. Débito</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(pmDebitCardValue.toString())}
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(241,243,244,1)',
            }}
          >
            <TableCell className={classes.footerCell}>Ticket Ref.</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(pmMealTicketValue.toString())}
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <TableCell className={classes.footerCell}>Outros</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(pmOtherValue.toString())}
            </TableCell>
          </TableRow>
        </TableFooter>
      );
    }

    return (
      <TableFooter className={classes.footerCell}>
        <TableRow>
          {options.columns.map((col, index) => {
            if (col.display === 'true') {
              if (col.name === 'date') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    Total
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'count') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {countX.toString()}
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'product_value') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(productValue.toString())}
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'delivery_fee_value') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(deliveryFeeValue.toString())}
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'discount_value') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(discountValue.toString())}
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'order_value') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(orderValue.toString())}
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'pm_money_value') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(pmMoneyValue.toString())}
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'pm_pix_value') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(pmPixValue.toString())}
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'pm_credit_card_value') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(pmCreditCardValue.toString())}
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'pm_debit_card_value') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(pmDebitCardValue.toString())}
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'pm_meal_ticket_value') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(pmMealTicketValue.toString())}
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'pm_other_value') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(pmOtherValue.toString())}
                  </TableCell>
                );
              }
            }

            return undefined;
          })}
        </TableRow>
      </TableFooter>
    );
  };

  return (
    <Page hideInfoCompany>
      <PaperInfo>
        <Box
          justifyContent="space-between"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            pt: isSelAffiliate ? 0 : 1,
            pb: isSelAffiliate ? 0 : 0.5,
          }}
        >
          <Hidden mdDown>
            <Typography
              component="span"
              variant="h5"
              sx={{ color: 'black', mt: isSelAffiliate ? 2.4 : 0.5 }}
            >
              {userSelector.affiliate?.fantasy_name}
            </Typography>
          </Hidden>

          <Form onSubmit={handleSubmit(onSubmit)} hideSubmit>
            <FilterReport control={control} isSelAffiliate={isSelAffiliate} />
          </Form>
        </Box>
      </PaperInfo>

      <DataTable
        title={<Title description="Pedidos por Dia" isLoading={isLoading} />}
        data={data}
        columns={columns}
        isLoading={isLoading}
        action={actionPrint}
        pagination={false}
        customTableBodyFooterRender={customTableBodyFooterRender}
        hideActionAddToolbar
      />

      <div style={{ display: 'none' }}>
        <ReportOrdersPerDay
          componentToPrint={componentToPrint}
          data={data}
          filters={getValues()}
          datePrint={datePrint || new Date()}
        />
      </div>
    </Page>
  );
}
