import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import TextField from '../../components/TextField';
import NumberField from '../../components/NumberField';
import { orderCategory, ruleComplement } from '../../statics';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  isSubmitting?: boolean;
}

export default function Form({ control, isSubmitting }: Props) {
  return (
    <>
      <TextField
        id="description"
        name="description"
        control={control}
        defaultValue=""
        variant="standard"
        fullWidth
        label="Descrição do Complemento"
        required
        autoFocus
        InputLabelProps={{ shrink: true }}
        disabled={isSubmitting}
      />

      <Grid container spacing={2}>
        <Grid item md={4} xs={6}>
          <NumberField
            id="minimum_amount"
            name="minimum_amount"
            control={control}
            defaultValue={0}
            variant="standard"
            fullWidth
            label="Quantidade Mínima"
            decimalScale={0}
            InputLabelProps={{ shrink: true }}
            disabled={isSubmitting}
          />
        </Grid>

        <Grid item md={4} xs={6}>
          <NumberField
            id="maximum_amount"
            name="maximum_amount"
            control={control}
            defaultValue={0}
            variant="standard"
            fullWidth
            label="Quantidade Máxima"
            decimalScale={0}
            InputLabelProps={{ shrink: true }}
            disabled={isSubmitting}
          />
        </Grid>

        <Grid item md={4} xs={6}>
          <TextField
            id="priority"
            name="priority"
            control={control}
            variant="standard"
            fullWidth
            label="Prioridade"
            required
            InputLabelProps={{ shrink: true }}
            inputProps={{ type: 'number', min: 0, max: 999 }}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <FormControl variant="standard" sx={{ mt: 2 }} fullWidth>
            <InputLabel htmlFor="type-required" shrink>
              Ordenar Itens Por
            </InputLabel>
            <Controller
              name="order"
              control={control}
              defaultValue="asc_name"
              render={({ field }) => (
                <Select
                  {...field}
                  sx={{ textAlign: 'left' }}
                  id="order"
                  variant="standard"
                  color="primary"
                  required
                  disabled={isSubmitting}
                  value={field.value || 'asc_name'}
                >
                  {orderCategory.map(category => (
                    <MenuItem key={category.id} value={category.id}>
                      <Typography>{category.description}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ mt: 2 }} fullWidth>
            <InputLabel htmlFor="type-required" shrink>
              Regra
            </InputLabel>
            <Controller
              name="rule"
              control={control}
              defaultValue="sum"
              render={({ field }) => (
                <Select
                  {...field}
                  sx={{ textAlign: 'left' }}
                  id="rule"
                  variant="standard"
                  color="primary"
                  required
                  disabled={isSubmitting}
                  value={field.value || 'sum'}
                >
                  {ruleComplement.map(rule => (
                    <MenuItem key={rule.id} value={rule.id}>
                      <Typography>{rule.description}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>

      <FormControlLabel
        sx={{ width: '100%', mt: 2 }}
        control={
          <Controller
            name="active"
            control={control}
            defaultValue
            render={({ field }) => (
              <Checkbox
                {...field}
                id="active"
                color="primary"
                onChange={e => field.onChange(e.target.checked)}
                checked={field.value || false}
                disabled={isSubmitting}
              />
            )}
          />
        }
        label="Ativo"
      />
    </>
  );
}

Form.defaultProps = {
  isSubmitting: false,
};
