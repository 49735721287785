/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Grid, TextField as TextFieldCore } from '@mui/material';
import { useDispatch } from 'react-redux';
import { MUIDataTableColumnDef } from 'mui-datatables';
import ActionsDataTable from '../../components/ActionsDataTable';
import DataTable from '../../components/DataTable';
import DialogForm from '../../components/DialogForm';
import Button from '../../components/Button';
import { IAffiliate, IAffiliateDeliveryCities, ICities } from '../../models';
import { states } from '../../statics';
import AutocompleteAsync from '../../components/AutocompleteAsync';
import useUtility from '../../hooks/useUtility';
import useData from '../../services/hooks/useData';

const schema = yup
  .object()
  .shape({
    city: yup.object().required().nullable(),
  })
  .required();

yup.setLocale(ptForm);
yup.setLocale({ mixed: { notType: 'Valor inválido.' } });

interface Props {
  affiliate: IAffiliate;
  open: boolean;
  close: () => void;
}

export default function Cities({ affiliate, open, close }: Props) {
  const { closeDialog, utilitySelector } = useUtility();
  const { data, getData, isLoading, isSubmitting, create, deleteObject } =
    useData<IAffiliateDeliveryCities>(
      'affiliate/delivery_cities',
      `?affiliate_id=${utilitySelector.dialogId}`,
      true
    );

  const dispatch = useDispatch();
  const { control, handleSubmit, reset, getValues } =
    useForm<IAffiliateDeliveryCities>({
      resolver: yupResolver(schema),
    });

  const [cities, setCities] = useState<IAffiliateDeliveryCities[]>([]);
  const [urlCities, setUrlCities] = useState('');

  function formatAffiliateCities(
    affiliateCitie: IAffiliateDeliveryCities
  ): IAffiliateDeliveryCities {
    return {
      ...affiliateCitie,
      citie_name: affiliateCitie.city?.name,
      citie_state: affiliateCitie.city?.state,
    };
  }

  useEffect(() => {
    if (open && utilitySelector.dialogId !== '') {
      getData();
    }
  }, [getData, open, utilitySelector.dialogId]);

  useEffect(() => {
    const formattedData: IAffiliateDeliveryCities[] = [];

    data.forEach(affiliateCity => {
      formattedData.push(formatAffiliateCities(affiliateCity));
    });

    setCities(formattedData);
  }, [data]);

  const handleClose = () => {
    reset({});
    close();
    dispatch(closeDialog());
  };

  const mapColumns = (
    affiliateCities: IAffiliateDeliveryCities
  ): IAffiliateDeliveryCities => {
    const result: IAffiliateDeliveryCities = {
      affiliate_id: utilitySelector.dialogId,
      city_id: affiliateCities.city?.id || '',
    };

    return result;
  };

  const onSubmit = async (formData: IAffiliateDeliveryCities) => {
    await create(mapColumns(formData)).then(() => {
      reset({ city: null, state: null });
      setUrlCities('');
    });
  };

  const deleteCities = async (affiliateCities: IAffiliateDeliveryCities) => {
    if (affiliateCities.id) {
      await deleteObject(
        affiliateCities.id,
        `?affiliate_id=${utilitySelector.dialogId}`
      );
    }
  };

  const onValidateCitie = () => {
    if (urlCities === '') {
      return 'Por favor selecione a UF para efetuar a pesquisa de cidades';
    }

    return '';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const beforeChangeState = (obj: any) => {
    reset({ ...getValues(), city: null });

    if (obj) {
      setUrlCities(
        `cities?country_id=e9d5b555-b14d-4706-a9b1-6f0c3e7e3fdb&state=${obj.id}`
      );
    } else {
      setUrlCities('');
    }
  };

  const actions = ActionsDataTable(
    false,
    cities,
    deleteCities,
    undefined,
    true
  );

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'citie_name',
      label: 'Cidade',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'citie_state',
      label: 'Estado',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  if (actions) {
    columns.push(actions);
  }

  return (
    <DialogForm
      titleDiag={affiliate?.fantasy_name || 'Filial'}
      open={open}
      maxForm="lg"
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      handleClose={handleClose}
      cancelText="Fechar"
      hideSubmit
      onSubmit={handleSubmit(onSubmit)}
    >
      <div style={{ marginBottom: 15 }}>
        <Grid container spacing={2}>
          <Grid item md={5} xs={12}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  clearOnEscape
                  options={states}
                  value={field.value || null}
                  noOptionsText="Sem opções"
                  openText="Abrir"
                  clearText="Limpar"
                  getOptionLabel={option => option.description}
                  isOptionEqualToValue={(option, valueX) =>
                    option.id === valueX.id
                  }
                  closeText="Fechar"
                  autoComplete={false}
                  renderInput={params => (
                    <TextFieldCore
                      {...params}
                      margin="normal"
                      variant="standard"
                      label="UF"
                      required
                      error={!!error}
                      autoFocus
                      disabled={isSubmitting}
                      helperText={error ? error.message : null}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onChange={(_, obj) => {
                    field.onChange(obj);

                    beforeChangeState(obj);
                  }}
                  ref={field.ref}
                />
              )}
              name="state"
              control={control}
            />
          </Grid>

          <Grid item md={5} xs={12}>
            <AutocompleteAsync
              name="city"
              control={control}
              title="Cidades"
              required
              url={urlCities}
              getLabel={(option: ICities) => option.name}
              onValidate={onValidateCitie}
              disabled={isSubmitting}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <Button
              id="submit"
              sx={{ mt: 3.6 }}
              caption="Adicionar"
              type="submit"
              fullWidth
              loading={isSubmitting}
            />
          </Grid>
        </Grid>
        <DataTable
          title="Cidades"
          data={cities}
          columns={columns}
          isLoading={isLoading}
          refresh={getData}
          hideActionAddToolbar
        />
      </div>
    </DialogForm>
  );
}
