import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useCan } from '../../hooks/useCan';
import useLogin from '../../hooks/useLogin';

interface Props {
  slug: string;
  children: JSX.Element;
  isAdmin?: boolean;
}

export function ProtectedRoute({ slug, children, isAdmin }: Props) {
  const { logout } = useLogin();
  const dispatch = useDispatch();
  const isAllowed = useCan({ view: slug, isAdmin });

  if (!isAllowed) {
    dispatch(logout());
    return <Navigate to="/" />;
  }

  return children;
}

ProtectedRoute.defaultProps = {
  isAdmin: false,
};
