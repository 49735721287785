import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { Difference } from '@mui/icons-material';
import ActionsDataTable from '../../components/ActionsDataTable';
import useData from '../../services/hooks/useData';
import DataTable from '../../components/DataTable';
import DialogForm from '../../components/DialogForm';
import snack from '../../components/SnackbarUtilsConfigurator';
import {
  IComplementItems,
  IComplement,
  IProduct,
  IItemClone,
} from '../../models';
import AutocompleteAsync from '../../components/AutocompleteAsync';
import Button from '../../components/Button';
import { useCan } from '../../hooks/useCan';
import api from '../../services/api';
import { typeCloneCompProduct } from '../../statics';
import Title from '../../components/Title';
import DialogX from '../../components/DialogX';

interface Props {
  complement: IComplement;
  open: boolean;
  onClose: () => void;
}

const schema = yup
  .object()
  .shape({
    product: yup.object().required().nullable(),
  })
  .required();

const schemaClone = yup
  .object()
  .shape({
    complement: yup.object().required().nullable(),
    type: yup.string().required().nullable(),
  })
  .required();

yup.setLocale(ptForm);
yup.setLocale({ mixed: { notType: 'Valor inválido.' } });

export default function Item({ complement, open, onClose }: Props) {
  const {
    data,
    isLoading,
    isFetch,
    isSubmitting,
    create,
    deleteObject,
    getData,
  } = useData<IComplementItems>(
    'complement/Items',
    `?complement_id=${complement.id}`,
    true
  );

  const isCreate = useCan({ view: 'complementItem.create' });
  const isDelete = useCan({ view: 'complementItem.delete' });

  const [isCloneSubmitting, setIsCloneSubmitting] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  const [isSubmittingX, setIsSubmittingX] = useState(false);
  const [isDialogX, setIsDialogX] = useState(false);
  const [complementItems, setComplementItems] = useState<IComplementItems>(
    {} as IComplementItems
  );

  useEffect(() => {
    if (open) {
      (async function get() {
        await getData();
      })();
    }
  }, [open, getData]);

  const { control, handleSubmit, reset } = useForm<IComplementItems>({
    resolver: yupResolver(schema),
  });

  const {
    control: controlClone,
    handleSubmit: handleSubmitClone,
    reset: resetClone,
  } = useForm<IItemClone>({
    resolver: yupResolver(schemaClone),
  });

  const productTemplate = (value: IProduct) => {
    return <div>{value.name}</div>;
  };

  const handleClose = () => {
    onClose();
    reset({});
  };

  const mapColumns = (obj: IComplementItems): IComplementItems => {
    const result: IComplementItems = {
      product_id: obj.product?.id || null,
      complement_id: complement?.id || null,
    };

    return result;
  };

  const onSubmit = async (formData: IComplementItems) => {
    const obj = mapColumns(formData);

    await create(obj);

    reset({});
  };

  const onSubmitClone = async (formData: IItemClone) => {
    setIsCloneSubmitting(true);

    const obj = {
      origin_complement_id: formData.complement?.id || '',
      complement_id: complement.id || '',
      type: formData.type,
    } as IItemClone;

    await api
      .patch<IItemClone>(`complement/items/clone`, obj)
      .then(async result => {
        if (result.status === 204) {
          snack.success('Importação realizada com sucesso');
          await getData();
          setIsDialog(false);
          resetClone({});
        }
      })
      .finally(() => setIsCloneSubmitting(false));
  };

  const deleteComplementItems = async (obj: IComplementItems) => {
    if (obj.id) {
      setIsSubmittingX(true);
      await deleteObject(obj.id)
        .then(() => setIsDialogX(false))
        .finally(() => setIsSubmittingX(false));
    }
  };

  const productCategoryTemplate = (productX: IProduct) => {
    return <div>{productX.category?.description}</div>;
  };

  const handleCloseProduct = () => setIsDialogX(false);

  const setDialogProduct = (obj: IComplementItems) => {
    setComplementItems(obj);
    setIsDialogX(true);
  };

  const onOk = async () => {
    await deleteComplementItems(complementItems);
  };

  const actions = ActionsDataTable(
    false,
    data,
    isDelete ? setDialogProduct : undefined,
    undefined,
    true,
    undefined,
    !isDelete
  );

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'product',
      label: 'Produto',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: IProduct) => productTemplate(value),
      },
    },
    {
      name: 'product',
      label: 'Categoria',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: IProduct) => productCategoryTemplate(value),
      },
    },
  ];

  if (actions) {
    columns.push(actions);
  }

  const handleCloseClone = () => setIsDialog(false);

  const openClone = () => {
    resetClone({});
    setIsDialog(true);
  };

  const cloneItem = () => {
    return (
      <Tooltip title="Importar Itens" sx={{ marginLeft: 1 }}>
        <IconButton onClick={() => openClone()} size="small">
          <Difference fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <>
      <DialogForm
        sx={{ minHeight: 450 }}
        titleDiag={complement.description}
        open={open}
        maxForm="sm"
        isLoading={isFetch}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
        hideSubmit
        cancelText="Fechar"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div style={{ marginBottom: 15 }}>
          {isCreate && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <AutocompleteAsync
                  name="product"
                  control={control}
                  title="Produto"
                  required
                  url="products?type=additional"
                  getLabel={(option: IProduct) => option.full_name || ''}
                  disabled={isSubmitting}
                  autoFocus
                  onChange={item => {
                    if (item) {
                      handleSubmit(onSubmit);
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Button
                  id="submit"
                  sx={{ mt: 3.6 }}
                  caption="Adicionar"
                  type="submit"
                  fullWidth
                  loading={isSubmitting}
                />
              </Grid>
            </Grid>
          )}
          <DataTable
            title={<Title description="Itens" isLoading={isLoading} />}
            data={data}
            columns={columns}
            isLoading={isLoading}
            refresh={getData}
            action={isCreate ? cloneItem : undefined}
            hideActionAddToolbar
          />
        </div>
      </DialogForm>
      <DialogForm
        titleDiag="Importar Itens"
        open={isDialog}
        isSubmitting={isCloneSubmitting}
        handleClose={handleCloseClone}
        submitText="Importar"
        cancelText="Fechar"
        onSubmit={handleSubmitClone(onSubmitClone)}
      >
        <Box sx={{ marginBottom: 3 }}>
          <AutocompleteAsync
            name="complement"
            control={controlClone}
            variant="standard"
            shrink
            title="Complemento de Origem"
            size="small"
            url="complements"
            getLabel={(option: IComplement) => option.description}
            disabled={isSubmitting}
            fullWidth
            required
            autoFocus
          />
          <FormControl variant="standard" sx={{ mt: 2 }} fullWidth>
            <InputLabel htmlFor="type-required" shrink>
              Tipo
            </InputLabel>
            <Controller
              name="type"
              control={controlClone}
              defaultValue="keep_and_upsert"
              render={({ field }) => (
                <Select
                  {...field}
                  sx={{ textAlign: 'left' }}
                  id="type"
                  variant="standard"
                  color="primary"
                  required
                  // disabled={isSubmitting}
                  disabled
                  value={field.value || 'keep_and_upsert'}
                >
                  {typeCloneCompProduct.map(type => (
                    <MenuItem key={type.id} value={type.id}>
                      <Typography>{type.description}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Box>
      </DialogForm>
      <DialogX
        titleDiag={complementItems.full_name || 'Produto'}
        open={isDialogX}
        onOk={() => onOk()}
        captionOk="Confirmar"
        captionCancel="Fechar"
        handleClose={handleCloseProduct}
        isLoading={isSubmittingX}
        autoFocus
      >
        {isSubmitting ? (
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          <Typography variant="body2">
            Confirma a exclusão do produto?
          </Typography>
        )}
      </DialogX>
    </>
  );
}
