import React from 'react';
import { useForm } from 'react-hook-form';
import { ptForm } from 'yup-locale-pt';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, TextField, Typography } from '@mui/material';
import Page from '../../components/Page';
import PaperInfo from '../../components/PaperInfo';
import PasswordField from '../../components/PasswordField';
import Form from '../../components/Form';
import useLogin from '../../hooks/useLogin';
import snack from '../../components/SnackbarUtilsConfigurator';
import api from '../../services/api';

interface IChangePassword {
  current_password: string;
  new_password: string;
  new_password_confirm: string;
}

yup.setLocale(ptForm);

const schema = yup
  .object()
  .shape({
    current_password: yup.string().required().nullable(),
    new_password: yup.string().required().nullable(),
    new_password_confirm: yup
      .string()
      .oneOf(
        [yup.ref('new_password'), null],
        'Senha de confirmação não confere'
      )
      .required()
      .nullable(),
  })
  .required();

function Account() {
  const { userSelector } = useLogin();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<IChangePassword>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData: IChangePassword) => {
    const obj = {
      current_password: formData.current_password,
      new_password: formData.new_password,
    };

    await api
      .patch<IChangePassword>(`user/change_password`, obj)
      .then(result => {
        if (result.status === 204) {
          snack.success('Informações atualizadas com sucesso');
          reset({});
        }
      });
  };

  return (
    <Page>
      <Form onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting}>
        <PaperInfo>
          <Typography component="span" variant="h6">
            Conta
          </Typography>

          <Grid sx={{ pt: 3 }} container spacing={2}>
            <Grid item md={4} xs={12}>
              <TextField
                id="name"
                name="name"
                value={userSelector.user.name}
                variant="standard"
                fullWidth
                label="Nome Completo"
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                id="username"
                name="username"
                value={userSelector.user.username}
                variant="standard"
                fullWidth
                label="Nome do Usuário"
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                id="type"
                name="type"
                value={
                  userSelector.access.type === 'supervisor'
                    ? 'supervisor'
                    : 'usuário'
                }
                variant="standard"
                fullWidth
                label="Tipo de Usuário"
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <PasswordField
                name="current_password"
                control={control}
                defaultValue=""
                variant="standard"
                fullWidth
                label="Senha Atual"
                type="password"
                disabled={isSubmitting}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <PasswordField
                name="new_password"
                control={control}
                defaultValue=""
                variant="standard"
                fullWidth
                label="Senha Nova"
                type="password"
                disabled={isSubmitting}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <PasswordField
                name="new_password_confirm"
                control={control}
                defaultValue=""
                variant="standard"
                fullWidth
                label="Confirmar Senha"
                type="password"
                disabled={isSubmitting}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </PaperInfo>
      </Form>
    </Page>
  );
}

export default Account;
