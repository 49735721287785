import { useQuery, QueryOptions } from 'react-query';
import { IInvoices } from '../../models';
import api from '../api';
import { queryClient } from '../queryClient';

function formatInvoice(invoice: IInvoices): IInvoices {
  return {
    ...invoice,
    affiliate_fantasy_name: invoice.affiliate?.fantasy_name,
  };
}

export async function getInvoice(id: string): Promise<IInvoices> {
  const { data } = await api.get<IInvoices>(`invoices/${id}`);

  return formatInvoice(data);
}

export async function getInvoices(isAdmin: boolean): Promise<IInvoices[]> {
  const { data } = await api.get<IInvoices[]>(
    isAdmin ? 'invoices/all' : 'invoices'
  );

  const formattedData: IInvoices[] = [];

  data.forEach(invoice => {
    formattedData.push(formatInvoice(invoice));
  });

  return formattedData;
}

export function useInvoices(
  isAdmin: boolean,
  options?: QueryOptions<IInvoices[]>
) {
  return useQuery(['invoices'], () => getInvoices(isAdmin), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...options,
  });
}

export async function fetchInvoices(isAdmin: boolean) {
  return queryClient.fetchQuery(['invoices'], () => getInvoices(isAdmin));
}
