import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Box,
  Hidden,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { ptForm } from 'yup-locale-pt';
import { SelectableRows } from 'mui-datatables';
import { makeStyles } from '@mui/styles';
import DataTable from '../../components/DataTable';
import Page from '../../components/Page';
import { IReportTopSellingProducts, IFiltersDashboard } from '../../models';
import Title from '../../components/Title';
import { getTopSellingProducts } from '../../services/hooks/useDashboard';
import snack from '../../components/SnackbarUtilsConfigurator';
import PaperInfo from '../../components/PaperInfo';
import useLogin from '../../hooks/useLogin';
import Form from '../../components/Form';
import ButtonPrint from '../../components/ButtonPrint';
import ReportTopSellingProducts from '../../reports/ReportTopSellingProducts';
import FilterReport from '../../components/FilterReport';

const schema = yup
  .object()
  .shape({
    dateInitial: yup.string().required().nullable(),
    dateFinal: yup
      .string()
      .required()
      // eslint-disable-next-line func-names
      .test('dateFinalAfterDateInitial', 'Inválido', function (dateFinal) {
        const { dateInitial } = this.parent;
        return moment(dateFinal).isSameOrAfter(moment(dateInitial));
      }),
  })
  .required();

yup.setLocale(ptForm);

const useStyles = makeStyles(() => ({
  footerCell: {
    borderBottom: 'none',
    color: 'black',
    fontSize: 16,
    fontWeight: 'bolder',
  },
}));

export default function TopSellingProducts() {
  const { userSelector } = useLogin();
  const [data, setData] = useState<IReportTopSellingProducts[]>([]);
  const [datePrint, setDatePrint] = useState<Date>();
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  const componentToPrint = useRef(null);

  const theme = useTheme();
  const isBreakMd = useMediaQuery(theme.breakpoints.up('md'));

  const { control, handleSubmit, getValues } = useForm<IFiltersDashboard>({
    resolver: yupResolver(schema),
    defaultValues: {
      dateInitial: moment().startOf('month').toDate(),
      dateFinal: moment().endOf('month').toDate(),
    },
  });

  const fetch = useCallback(async (filterX: IFiltersDashboard) => {
    setIsLoading(true);
    await getTopSellingProducts(filterX)
      .then(result => {
        setData(result);
      })
      .catch(error => {
        if (error instanceof Error) {
          snack.error(error.message);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const filterX: IFiltersDashboard = {} as IFiltersDashboard;
    filterX.dateInitial = getValues('dateInitial');
    filterX.dateFinal = getValues('dateFinal');
    fetch(filterX);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const onSubmit = async (dataX: IFiltersDashboard) => {
    fetch(dataX);
  };

  const isSelAffiliate = localStorage.getItem('affiliateSelection') !== null;

  const formatValue = (value: string) => {
    return parseFloat(value).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const numberTemplate = (value: string) => {
    return <div>{formatValue(value)}</div>;
  };

  const columns = [
    {
      name: 'name',
      label: 'Produto',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'quantity',
      label: 'Quantidade',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'gross_value',
      label: 'Valor Bruto',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'discount_value',
      label: 'Desconto',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'liquid_value',
      label: 'Valor Líquido',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
  ];

  const onBeforeGetContent = () => {
    setDatePrint(new Date());
  };

  const actionPrint = () => {
    if (data.length === 0) {
      return undefined;
    }

    return (
      <ButtonPrint
        componentToPrint={componentToPrint}
        size="small"
        pageStyle="@page { size: A4; margin: 10mm 0 0 0; }"
        onBeforeGetContent={onBeforeGetContent}
      />
    );
  };

  const customTableBodyFooterRender = (options: {
    data: IReportTopSellingProducts[];
    selectableRows: SelectableRows;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: any[];
  }) => {
    if (data.length === 0) {
      return undefined;
    }

    const quantity = data.reduce((accu, item) => {
      return accu + item.quantity;
    }, 0);

    const grossValue = data.reduce((accu, item) => {
      return accu + item.gross_value;
    }, 0);

    const discountValue = data.reduce((accu, item) => {
      return accu + item.discount_value;
    }, 0);

    const liquidValue = data.reduce((accu, item) => {
      return accu + item.liquid_value;
    }, 0);

    if (!isBreakMd) {
      return (
        <TableFooter>
          <TableRow
            sx={{
              backgroundColor: 'primary.lighter',
            }}
          >
            <TableCell
              className={classes.footerCell}
              sx={{
                textAlign: 'center',
              }}
            >
              Total
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(241,243,244,1)',
            }}
          >
            <TableCell className={classes.footerCell}>Quantidade</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(quantity.toString())}
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(241,243,244,1)',
            }}
          >
            <TableCell className={classes.footerCell}>Valor Bruto</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(grossValue.toString())}
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgba(241,243,244,1)',
            }}
          >
            <TableCell className={classes.footerCell}>Desconto</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(discountValue.toString())}
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <TableCell className={classes.footerCell}>Valor Líquido</TableCell>

            <TableCell className={classes.footerCell}>
              {formatValue(liquidValue.toString())}
            </TableCell>
          </TableRow>
        </TableFooter>
      );
    }

    return (
      <TableFooter className={classes.footerCell}>
        <TableRow>
          {options.columns.map((col, index) => {
            if (col.display === 'true') {
              if (col.name === 'name') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    Total
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'quantity') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(quantity.toString())}
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'gross_value') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(grossValue.toString())}
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'discount_value') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(discountValue.toString())}
                  </TableCell>
                );
              }
            }

            if (col.display === 'true') {
              if (col.name === 'liquid_value') {
                return (
                  <TableCell key={index} className={classes.footerCell}>
                    {formatValue(liquidValue.toString())}
                  </TableCell>
                );
              }
            }
            return undefined;
          })}
        </TableRow>
      </TableFooter>
    );
  };

  return (
    <Page hideInfoCompany>
      <PaperInfo>
        <Box
          justifyContent="space-between"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            pt: isSelAffiliate ? 0 : 1,
            pb: isSelAffiliate ? 0 : 0.5,
          }}
        >
          <Hidden mdDown>
            <Typography
              component="span"
              variant="h5"
              sx={{ color: 'black', mt: isSelAffiliate ? 2.4 : 0.5 }}
            >
              {userSelector.affiliate?.fantasy_name}
            </Typography>
          </Hidden>

          <Form onSubmit={handleSubmit(onSubmit)} hideSubmit>
            <FilterReport control={control} isSelAffiliate={isSelAffiliate} />
          </Form>
        </Box>
      </PaperInfo>

      <DataTable
        title={
          <Title description="Produtos Mais Vendidos" isLoading={isLoading} />
        }
        data={data}
        columns={columns}
        isLoading={isLoading}
        action={actionPrint}
        pagination={false}
        customTableBodyFooterRender={customTableBodyFooterRender}
        hideActionAddToolbar
      />

      <div style={{ display: 'none' }}>
        <ReportTopSellingProducts
          componentToPrint={componentToPrint}
          data={data}
          filters={getValues()}
          datePrint={datePrint || new Date()}
        />
      </div>
    </Page>
  );
}
