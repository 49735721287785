import { useQuery, QueryOptions } from 'react-query';
import { IOrder, IPeriod } from '../../models';
import { formatDate } from '../../utils';
import api from '../api';
import { queryClient } from '../queryClient';

export async function getOrders(
  affiliateId: string,
  period: IPeriod
): Promise<IOrder[]> {
  const { data } = await api.get<IOrder[]>(
    `orders?affiliate_id=${affiliateId}`,
    {
      params: {
        start_date: formatDate(period.start_date.toString()),
        end_date: formatDate(period.end_date.toString()),
      },
    }
  );

  return data;
}

export async function getOrder(
  affiliateId: string,
  idOrder: string
): Promise<IOrder> {
  const { data } = await api.get<IOrder>(
    `orders/${idOrder}?affiliate_id=${affiliateId}`
  );

  return data;
}

export function useOrder(
  affiliateId: string,
  id: string,
  options?: QueryOptions<IOrder>
) {
  return useQuery(['order', affiliateId, id], () => getOrder(affiliateId, id), {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    enabled: true,
    refetchInterval: 60000,
    ...options,
  });
}

export function useOrders(
  affiliateId: string,
  period: IPeriod,
  autoRefresh: boolean,
  options?: QueryOptions<IOrder[]>
) {
  return useQuery(
    ['orders', affiliateId, period],
    () => getOrders(affiliateId, period),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      enabled: autoRefresh ? true : undefined,
      refetchInterval: 60000,
      ...options,
    }
  );
}

export async function fetchOrder(affiliateId: string, period: IPeriod) {
  return queryClient.fetchQuery(['orders'], () =>
    getOrders(affiliateId, period)
  );
}
