import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Skeleton,
  Stack,
} from '@mui/material';
import { CardProps } from '@mui/material/Card/Card';

type IParams = CardProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  loading?: boolean;
  verifyEmpty: () => boolean;
};

export default function RecurringCustomers({
  data,
  loading,
  verifyEmpty,
  ...rest
}: IParams) {
  return (
    <Card {...rest}>
      <CardHeader
        title={
          loading ? (
            <Skeleton
              animation="wave"
              height={18}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          ) : (
            'Clientes Recorrentes'
          )
        }
        titleTypographyProps={{ variant: 'body1' }}
      />
      <Divider />
      <CardContent>
        <Box height={270} position="relative">
          {loading && (
            <Skeleton
              sx={{ height: 270 }}
              animation="wave"
              variant="rectangular"
            />
          )}

          {!loading &&
            (!verifyEmpty() ? (
              <ResponsivePie
                data={data}
                margin={{ top: 30, right: 20, bottom: 30, left: 60 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                colors={{ scheme: 'pastel2' }}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.8]],
                }}
                arcLinkLabel={e => {
                  return `${e.label}`;
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]],
                }}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: 'Período Atual',
                    },
                    id: 'dots',
                  },
                  {
                    match: {
                      id: 'Período Anterior',
                    },
                    id: 'dots',
                  },
                ]}
                legends={[
                  {
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -30,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemTextColor: '#000',
                        },
                      },
                    ],
                  },
                ]}
              />
            ) : (
              <Box sx={{ flexGrow: 1, p: 3 }}>
                <Stack
                  sx={{
                    maring: 0,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    color="textSecondary"
                  >
                    Sem Informações
                  </Typography>
                </Stack>
              </Box>
            ))}
        </Box>
      </CardContent>
    </Card>
  );
}

RecurringCustomers.defaultProps = {
  loading: false,
};
