import React from 'react';
import { Paper } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export default function PaperInfo({ children }: Props) {
  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        listStyle: 'none',
        mb: 1,
        pl: 3,
        pr: 3,
        pt: 1,
        pb: 1,
      }}
    >
      {children}
    </Paper>
  );
}
