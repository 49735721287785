import { useQuery, QueryOptions } from 'react-query';
import { IProduct } from '../../models';
import api from '../api';
import { queryClient } from '../queryClient';

function formatProduct(product: IProduct): IProduct {
  return {
    ...product,
    category_description: product.category?.description,
  };
}

export async function getProduct(id: string): Promise<IProduct> {
  const { data } = await api.get<IProduct>(`products/${id}`);

  return formatProduct(data);
}

export async function getProducts(): Promise<IProduct[]> {
  const { data } = await api.get<IProduct[]>('products');

  const formattedData: IProduct[] = [];

  data.forEach(product => {
    formattedData.push(formatProduct(product));
  });

  return formattedData;
}

export function useProducts(options?: QueryOptions<IProduct[]>) {
  return useQuery(['products'], () => getProducts(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...options,
  });
}

export async function fetchProducts() {
  return queryClient.fetchQuery(['products'], () => getProducts());
}
