/* eslint-disable prettier/prettier */
import React from 'react';
import { ButtonProps } from '@mui/material/Button/Button';
import { CircularProgress } from '@mui/material';
import { Container } from './styles';

interface Props {
  caption: string;
  loading?: boolean;
  color?:
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning'
  | undefined;
}

export default function Button({
  caption,
  loading,
  color,
  ...buttonProps
}: Props & ButtonProps): React.ReactElement {
  return (
    <Container
      {...buttonProps}
      variant="contained"
      color={color}
      {...(loading && { disabled: true })}
    >
      {!loading && caption}
      {loading && <CircularProgress color="secondary" size={25} />}
    </Container>
  );
}

Button.defaultProps = {
  loading: false,
  color: 'primary',
};
