import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  Grid,
  Hidden,
  Link,
  Rating,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DoNotDisturbAlt,
  DoneAll,
  FilterAlt,
  Moped,
  AccessTime,
  RotateRight,
  ShoppingCart,
  OpenInBrowser,
  Star,
  ShoppingBag,
} from '@mui/icons-material';
import moment from 'moment';
import { ptForm } from 'yup-locale-pt';
import Form from '../../components/Form';
import Page from '../../components/Page';
import PaperInfo from '../../components/PaperInfo';
import {
  IAffiliate,
  IDashboardAllowed,
  IFiltersDashboard,
  IInvoices,
  IRecurringCustomer,
  IResume,
  IDataPaymentDashboard,
  IDashboardDaysOfWeek,
  IAdminAffiliates,
  IAdminResume,
} from '../../models';
import AutocompleteAsync from '../../components/AutocompleteAsync';
import DateX from '../../components/DateX';
import CardX from './Card';
import OrdersByDayOfWeek from './Graphs/OrdersByDayOfWeek';
import BillingDayOfWeek from './Graphs/BillingDayOfWeek';
import RecurringCustomers from './Graphs/RecurringCustomers';
import PaymentMethods from './Graphs/PaymentMethods';
import Button from '../../components/Button';
import Title from '../../components/Title';
import useLogin from '../../hooks/useLogin';
import {
  getResume,
  getDaysOfWeek,
  getRecurringCustomer,
  getPaymentMethods,
  getAdminAffiliates,
  getAdminResume,
  getInvoice,
} from '../../services/hooks/useDashboard';
import snack from '../../components/SnackbarUtilsConfigurator';
import { useCan } from '../../hooks/useCan';
import DataTable from '../../components/DataTable';
import Label from '../../components/Label';
import CurrentInvoice from './Graphs/CurrentInvoice';

const schema = yup
  .object()
  .shape({
    dateInitial: yup.string().required().nullable(),
    dateFinal: yup
      .string()
      .required()
      // eslint-disable-next-line func-names
      .test('dateFinalAfterDateInitial', 'Inválido', function (dateFinal) {
        const { dateInitial } = this.parent;
        return moment(dateFinal).isSameOrAfter(moment(dateInitial));
      }),
  })
  .required();

yup.setLocale(ptForm);

export default function Dashboard() {
  const { userSelector } = useLogin();
  const { control, handleSubmit } = useForm<IFiltersDashboard>({
    resolver: yupResolver(schema),
    defaultValues: {
      dateInitial: new Date(),
      dateFinal: new Date(),
    },
  });

  const [isLoadingResume, setIsLoadingResume] = useState(false);
  const [isLoadingDays, setIsLoadingDays] = useState(false);
  const [isLoadingRecurring, setIsLoadingRecurring] = useState(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [isLoadingAdmin, setIsLoadingAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const allowed = {
    allowed_resume: useCan({ view: 'dashboard.resume' }),
    allowed_payment_method: useCan({ view: 'dashboard.paymentMethod' }),
    allowed_day_of_week: useCan({ view: 'dashboard.dayOfWeek' }),
    allowed_recurring_customer: useCan({ view: 'dashboard.recurringCustomer' }),
  } as IDashboardAllowed;

  const [resume, setResume] = useState<IResume>();
  const [daysOfWeek, setDaysOfWeek] = useState<IDashboardDaysOfWeek>({
    billing: [],
    order: [],
  } as IDashboardDaysOfWeek);
  const [recurringCustomer, setRecurringCustomer] =
    useState<IRecurringCustomer>();
  const [dataPaymentDashboard, setDataPaymentDashboard] = useState<
    IDataPaymentDashboard[]
  >([]);
  const [adminAfiiliates, setAdminAffiliates] = useState<IAdminAffiliates[]>(
    []
  );
  const [adminResume, setAdminResume] = useState<IAdminResume>();
  const [invoice, setInvoice] = useState<IInvoices>({} as IInvoices);

  const isSelAffiliate = localStorage.getItem('affiliateSelection') !== null;

  const fetchDashboard = useCallback(
    async (filterX: IFiltersDashboard) => {
      if (!userSelector.isFetching) {
        if (userSelector.access.type === 'admin') {
          setIsLoadingAdmin(true);
          setIsLoadingResume(true);
        } else {
          setIsLoading(true);
          setIsLoadingResume(true);
          setIsLoadingDays(true);
          setIsLoadingRecurring(true);
          setIsLoadingPayment(true);
          setIsLoadingInvoice(true);
        }

        try {
          if (userSelector.access.type === 'supervisor') {
            await getInvoice()
              .then(result => {
                setInvoice(result);
              })
              .catch(error => {
                if (error instanceof Error) {
                  snack.error(error.message);
                }
              })
              .finally(() => setIsLoadingInvoice(false));
          }

          if (userSelector.access.type === 'admin') {
            await getAdminAffiliates(filterX)
              .then(result => setAdminAffiliates(result))
              .catch(error => {
                if (error instanceof Error) {
                  snack.error(error.message);
                }
              })
              .finally(() => setIsLoadingAdmin(false));

            await getAdminResume(filterX)
              .then(result => setAdminResume(result))
              .catch(error => {
                if (error instanceof Error) {
                  snack.error(error.message);
                }
              })
              .finally(() => setIsLoadingResume(false));
          }

          if (allowed.allowed_resume) {
            await getResume(filterX)
              .then(result => setResume(result))
              .catch(error => {
                if (error instanceof Error) {
                  snack.error(error.message);
                }
              })
              .finally(() => setIsLoadingResume(false));
          }

          if (allowed.allowed_day_of_week) {
            await getDaysOfWeek(filterX.affiliate?.id)
              .then(result => {
                setDaysOfWeek(result);
              })
              .catch(error => {
                if (error instanceof Error) {
                  snack.error(error.message);
                }
              })
              .finally(() => setIsLoadingDays(false));
          }

          if (allowed.allowed_recurring_customer) {
            await getRecurringCustomer(filterX)
              .then(result => {
                setRecurringCustomer(result);
              })
              .catch(error => {
                if (error instanceof Error) {
                  snack.error(error.message);
                }
              })
              .finally(() => setIsLoadingRecurring(false));
          }

          if (allowed.allowed_payment_method) {
            await getPaymentMethods(filterX)
              .then(result => {
                setDataPaymentDashboard(result);
              })
              .catch(error => {
                if (error instanceof Error) {
                  snack.error(error.message);
                }
              })
              .finally(() => setIsLoadingPayment(false));
          }
        } finally {
          setIsLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSelector.isFetching]
  );

  useEffect(() => {
    const filterX: IFiltersDashboard = {} as IFiltersDashboard;
    filterX.dateInitial = new Date();
    filterX.dateFinal = new Date();
    fetchDashboard(filterX);
  }, [fetchDashboard]);

  const onSubmit = async (data: IFiltersDashboard) => {
    fetchDashboard(data);
  };

  const dataRecurringCustomers = [
    {
      id: 'Período Atual',
      label: 'Período Atual',
      value: recurringCustomer?.total || 0,
    },
    {
      id: 'Período Anterior',
      label: 'Período Anterior',
      value: recurringCustomer?.recurrent || 0,
    },
  ];

  const verifyEmptyRecurringCustomer = () => {
    const total = recurringCustomer?.total || 0;
    const recurrent = recurringCustomer?.recurrent || 0;
    return total === 0 && recurrent === 0;
  };

  const situationTemplate = (value: string) => {
    return (
      <Label variant="ghost" color={value === 'Aberto' ? 'success' : 'error'}>
        {value}
      </Label>
    );
  };

  const aliasTemplate = (dataIndex: number) => {
    if (!adminAfiiliates) {
      return undefined;
    }

    return (
      <Tooltip title="Site" placement="top-end">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
          }}
        >
          <Link
            target="_blank"
            sx={{ color: '#637381' }}
            href={`https://mercurius.delivery/${adminAfiiliates[dataIndex].alias}`}
          >
            <OpenInBrowser fontSize="small" />
          </Link>
        </div>
      </Tooltip>
    );
  };

  const dateFormat = (value: Date) => {
    return value && <div>{moment(value).format('DD/MM/YYYY')}</div>;
  };

  const columnsAdmin = [
    {
      name: 'licence_expiration_at',
      label: 'Data Licença',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: Date) => dateFormat(value),
      },
    },
    {
      name: 'fantasy_name',
      label: 'Nome Fantasia',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'situation_description',
      label: 'Situação',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => situationTemplate(value),
      },
    },
    {
      name: 'order',
      label: 'Total',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'placed',
      label: 'Realizado',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'in_preparation',
      label: 'Em Preparo',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'awaiting_delivery_or_pickup',
      label: 'Ent/Ret',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'canceled',
      label: 'Cancelado',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'delivered',
      label: 'Entregue',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'rated',
      label: 'Avaliado',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'alias',
      label: 'Site',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number) => aliasTemplate(dataIndex),
        setCellProps: () =>
          window.innerWidth < 900
            ? { style: {} }
            : { style: { whiteSpace: 'nowrap', width: '3%' } },
      },
    },
  ];

  const dashAdminResume = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={1.75} sm={6}>
          <CardX
            icon={
              <ShoppingCart
                fontSize="large"
                sx={{
                  color: '#637381',
                }}
              />
            }
            value={adminResume?.order || 0}
            subTitle="Total"
            loading={isLoadingResume}
          />
        </Grid>
        <Grid item xs={12} md={1.7} sm={6}>
          <CardX
            icon={
              <DoneAll
                fontSize="large"
                sx={{
                  color: '#637381',
                }}
              />
            }
            value={adminResume?.placed || 0}
            subTitle="Realizado"
            loading={isLoadingResume}
          />
        </Grid>
        <Grid item xs={12} md={1.7} sm={6}>
          <CardX
            icon={
              <RotateRight
                fontSize="large"
                sx={{
                  color: '#637381',
                }}
              />
            }
            value={adminResume?.in_preparation || 0}
            subTitle="Em Preparo"
            loading={isLoadingResume}
          />
        </Grid>
        <Grid item xs={12} md={1.7} sm={6}>
          <CardX
            icon={
              <AccessTime
                fontSize="large"
                sx={{
                  color: '#637381',
                }}
              />
            }
            value={adminResume?.awaiting_delivery_or_pickup || 0}
            subTitle="Entrega/Retirada"
            loading={isLoadingResume}
          />
        </Grid>
        <Grid item xs={12} md={1.7} sm={6}>
          <CardX
            icon={
              <DoNotDisturbAlt
                fontSize="large"
                sx={{
                  color: '#637381',
                }}
              />
            }
            value={adminResume?.canceled || 0}
            subTitle="Cancelado"
            loading={isLoadingResume}
          />
        </Grid>
        <Grid item xs={12} md={1.7} sm={6}>
          <CardX
            icon={
              <ShoppingBag
                fontSize="large"
                sx={{
                  color: '#637381',
                }}
              />
            }
            value={adminResume?.delivered || 0}
            subTitle="Entregue"
            loading={isLoadingResume}
          />
        </Grid>
        <Grid item xs={12} md={1.75} sm={12}>
          <CardX
            icon={
              <Star
                fontSize="large"
                sx={{
                  color: '#637381',
                }}
              />
            }
            value={adminResume?.rated || 0}
            subTitle="Avaliado"
            loading={isLoadingResume}
          />
        </Grid>
      </Grid>
    );
  };

  const dashResume = () => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={2} sm={6}>
          <CardX
            icon={
              <ShoppingCart
                fontSize="large"
                sx={{
                  color: '#637381',
                }}
              />
            }
            value={resume?.order || 0}
            subTitle="Total de Pedidos"
            loading={isLoadingResume}
          />
        </Grid>
        <Grid item xs={12} md={2} sm={6}>
          <CardX
            icon={
              <Moped
                fontSize="large"
                sx={{
                  color: '#637381',
                }}
              />
            }
            value={resume?.delivery || 0}
            subTitle="Delivery"
            loading={isLoadingResume}
          />
        </Grid>
        <Grid item xs={12} md={2} sm={6}>
          <CardX
            icon={
              <ShoppingBag
                fontSize="large"
                sx={{
                  color: '#637381',
                }}
              />
            }
            value={resume?.withdrawal || 0}
            subTitle="Retirada"
            loading={isLoadingResume}
          />
        </Grid>
        <Grid item xs={12} md={2} sm={6}>
          <CardX
            icon={
              <DoNotDisturbAlt
                fontSize="large"
                sx={{
                  color: '#637381',
                }}
              />
            }
            value={resume?.canceled || 0}
            subTitle="Cancelado"
            loading={isLoadingResume}
          />
        </Grid>
        <Grid item xs={12} md={2} sm={6}>
          <CardX
            icon={
              <Star
                fontSize="large"
                sx={{
                  color: '#637381',
                }}
              />
            }
            infoX={
              <>
                <Rating
                  name="assessment"
                  value={resume?.average_rating}
                  precision={0.1}
                  size="medium"
                  readOnly
                />

                <Typography variant="h4" color="text.primary" sx={{ mt: 0.1 }}>
                  {resume?.average_rating}
                </Typography>
              </>
            }
            value={100}
            subTitle="Avaliação"
            loading={isLoadingResume}
          />
        </Grid>
        <Grid item xs={12} md={2} sm={6}>
          <CardX
            icon={
              <AccessTime
                fontSize="large"
                sx={{
                  color: '#637381',
                }}
              />
            }
            value={resume?.average_preparation_time || '00:00:00'}
            subTitle="Tempo de Preparo"
            loading={isLoadingResume}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Page hideInfoCompany>
      <PaperInfo>
        <Box
          justifyContent="space-between"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            pt: isSelAffiliate ? 0 : 1,
            pb: isSelAffiliate ? 0 : 0.5,
          }}
        >
          <Hidden mdDown>
            <Typography
              component="span"
              variant="h5"
              sx={{ color: 'black', mt: isSelAffiliate ? 2.4 : 0.5 }}
            >
              {userSelector.affiliate?.fantasy_name}
              {isLoading && (
                <CircularProgress color="primary" size={18} sx={{ ml: 3 }} />
              )}
            </Typography>
          </Hidden>

          <Form onSubmit={handleSubmit(onSubmit)} hideSubmit>
            <Grid container spacing={2}>
              {isSelAffiliate && (
                <Grid item xs={12} md={4} sm={12}>
                  <AutocompleteAsync
                    name="affiliate"
                    control={control}
                    variant="outlined"
                    title="Filial"
                    size="small"
                    url="affiliates"
                    getLabel={(option: IAffiliate) => option.fantasy_name}
                    fullWidth
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={isSelAffiliate ? 3 : 5}
                sm={6}
                sx={{ mt: isSelAffiliate ? 2 : 0 }}
              >
                <DateX title="Inicial" name="dateInitial" control={control} />
              </Grid>
              <Grid
                item
                xs={12}
                md={isSelAffiliate ? 3 : 5}
                sm={6}
                sx={{ mt: isSelAffiliate ? 2 : 0 }}
              >
                <DateX
                  title="Final"
                  name="dateFinal"
                  control={control}
                  disableFuture
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                sx={{ mt: isSelAffiliate ? 2 : 0 }}
                sm={12}
              >
                <Button
                  id="submit"
                  caption="Filtrar"
                  type="submit"
                  fullWidth
                  startIcon={<FilterAlt />}
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
      </PaperInfo>

      {userSelector.access.type === 'supervisor' && (
        <Box sx={{ width: '100%', mb: 3, mt: 3 }}>
          <CurrentInvoice data={invoice} loading={isLoadingInvoice} />
        </Box>
      )}

      <Box sx={{ mt: 2 }}>
        {userSelector.access.type === 'admin'
          ? dashAdminResume()
          : allowed.allowed_resume && dashResume()}

        <Grid container spacing={2} sx={{ mt: 1 }}>
          {userSelector.access.type === 'admin' && (
            <Box width="100%" pl={2.1} pt={1.1}>
              <DataTable
                title={
                  <Title description="Filiais" isLoading={isLoadingAdmin} />
                }
                data={adminAfiiliates}
                columns={columnsAdmin}
                isLoading={isLoadingAdmin}
                hideActionAddToolbar
              />
            </Box>
          )}
          {allowed.allowed_day_of_week && (
            <>
              <Grid item xs={12} md={6}>
                <BillingDayOfWeek
                  data={daysOfWeek.billing}
                  loading={isLoadingDays}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <OrdersByDayOfWeek
                  data={daysOfWeek.order}
                  loading={isLoadingDays}
                />
              </Grid>
            </>
          )}
          {allowed.allowed_recurring_customer && (
            <Grid item xs={12} md={6}>
              <RecurringCustomers
                data={dataRecurringCustomers}
                loading={isLoadingRecurring}
                verifyEmpty={verifyEmptyRecurringCustomer}
              />
            </Grid>
          )}
          {allowed.allowed_payment_method && (
            <Grid item xs={12} md={6}>
              <PaymentMethods
                data={dataPaymentDashboard}
                loading={isLoadingPayment}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Page>
  );
}
