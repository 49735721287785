import { useQuery, QueryOptions } from 'react-query';
import { IAffiliateProducts } from '../../models';
import api from '../api';
import { queryClient } from '../queryClient';

function formatAffiliateProduct(
  affiliateProduct: IAffiliateProducts
): IAffiliateProducts {
  return {
    ...affiliateProduct,
    category_description: affiliateProduct.product?.category?.description,
    product_name: affiliateProduct.product?.name,
    type_product: affiliateProduct.product?.type_description,
    allow_sale_delivery_desc: affiliateProduct.allow_sale_delivery
      ? 'Sim'
      : 'Não',
    allow_sale_local_desc: affiliateProduct.allow_sale_local ? 'Sim' : 'Não',
    allow_sale_withdrawal_desc: affiliateProduct.allow_sale_withdrawal
      ? 'Sim'
      : 'Não',
    active_description: affiliateProduct.active ? 'Sim' : 'Não',
  };
}

export async function getAffiliateProduct(
  id: string
): Promise<IAffiliateProducts> {
  const { data } = await api.get<IAffiliateProducts>(
    `affiliate/products/${id}`
  );

  return formatAffiliateProduct(data);
}

export async function getAffiliateProducts(): Promise<IAffiliateProducts[]> {
  const { data } = await api.get<IAffiliateProducts[]>('affiliate/products');

  const formattedData: IAffiliateProducts[] = [];

  data.forEach(affiliateProduct => {
    formattedData.push(formatAffiliateProduct(affiliateProduct));
  });

  return formattedData;
}

export function useAffiliateProducts(
  options?: QueryOptions<IAffiliateProducts[]>
) {
  return useQuery(['affiliate_products'], () => getAffiliateProducts(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...options,
  });
}

export async function fetchAffiliateProducts() {
  return queryClient.fetchQuery(['affiliate_products'], () =>
    getAffiliateProducts()
  );
}
