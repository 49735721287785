/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { IAddressLocation } from '../../models';

interface Props {
  onSelected: (addresLocation: IAddressLocation) => void;
}

export default function PlacesAutocomplete({
  onSelected,
  ...textFieldProps
}: Props & TextFieldProps) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly google.maps.places.AutocompletePrediction[]>([]);
  const loading = open && options.length === 0;

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }: { description: string }) => {
      setValue(description, false);
      clearSuggestions();

      getGeocode({ address: description }).then(results => {
        const { lat, lng } = getLatLng(results[0]);
        onSelected({ lat, lng });
      });
    };

  useEffect(() => {
    if (status) {
      setOptions(data);
      setOpen(true);
    }
  }, [status, data]);

  return (
    <Autocomplete
      open={open}
      clearOnEscape
      onOpen={() => {
        if (options.length === 0) {
          return;
        }

        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(_, obj) => {
        if (obj) {
          handleSelect({ description: obj.description });
        }
      }}
      options={options}
      loading={loading}
      value={options.find(x => x.description === value) || null}
      disabled={!ready}
      fullWidth
      loadingText="Carregando..."
      noOptionsText="Sem opções"
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      getOptionLabel={option => option.description}
      renderInput={params => (
        <TextField
          {...params}
          {...textFieldProps}
          margin="normal"
          variant="standard"
          label='Localização'
          autoComplete="new-password"
          onChange={handleInput}
          placeholder="Localize sua empresa..."
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )
      }
    />
  );
}
