import React from 'react';
import { Stack, Typography } from '@mui/material';

interface Props {
  text: string;
}

export default function Subtitle({ text }: Props) {
  return (
    <Stack direction="row" sx={{ mt: 2 }}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        {text}
      </Typography>
    </Stack>
  );
}
