import styled from 'styled-components';

export const Container = styled.div`
  .page {
    font-family: Arial, Helvetica, sans-serif;
    color: #000000;
    // width: 80mm;
    padding: 0mm 8mm 0mm 8mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .title {
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }

  .title-small {
    line-height: 14px;
    text-align: center;
    font-weight: bold;
    font-size: 11px;
  }

  .title-small-nb {
    line-height: 11px;
    text-align: center;
    font-size: 9px;
  }

  .text {
    line-height: 20px;
    font-size: 14px;
  }

  .text-small {
    line-height: 16px;
    font-size: 10px;
  }

  .text-info {
    line-height: 20px;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .text-info-small {
    line-height: 14px;
    margin-bottom: 9px;
    font-size: 10px;
  }

  .text-sub {
    font-size: 14px;
    line-height: 15px;
  }

  .text-sub-small {
    font-size: 10px;
    line-height: 11px;
  }

  .text-rigth {
    text-align: right;
    font-size: 14px;
  }

  .text-rigth-small {
    text-align: right;
    font-size: 10px;
  }

  .info {
    border: 1px black solid;
    border-radius: 5px;
    padding-top: 1mm;
    padding-bottom: 1mm;
    margin-left: 10mm;
    margin-right: 10mm;
    margin-top: 1mm;
  }

  .line {
    position: relative;
    clear: both;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 5px;
    line-height: 20px;
    font-size: 14px;
  }

  .line .value {
    position: relative;
    right: 0;
    float: right;
    word-break: break-all;
    word-wrap: break-word;
    text-align: left;
  }

  .line-small {
    position: relative;
    clear: both;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 4px;
    line-height: 17px;
    font-size: 10px;
  }

  .line-small .value {
    position: relative;
    right: 0;
    float: right;
    word-break: break-all;
    word-wrap: break-word;
    text-align: left;
  }

  .text-item {
    font-size: 14px;
  }

  .text-item-small {
    font-size: 10px;
    line-height: 15px;
  }

  .sub-item {
    font-size: 12px;
    font-weight: bold;
    padding-left: 15px;
    line-height: 14px;
  }

  .sub-item-small {
    font-size: 9px;
    font-weight: bold;
    padding-left: 12px;
    line-height: 11px;
    word-break: break-all;
    word-wrap: break-word;
  }

  .item {
    font-size: 12px;
    padding-left: 15px;
    line-height: 14px;
  }

  .item-small {
    font-size: 9px;
    padding-left: 12px;
    line-height: 11px;
  }

  table {
    line-height: 20px;
    width: 100%;
    font-size: 14px;
  }

  table.formatTable tr td {
    font-size: 5px;
    font-weight: bold;
  }

  .line .value-important {
    font-weight: bold;
    font-size: 6pt;
  }

  .print-hr {
    border-top: 1px solid #000;
    margin: 5px 0;
  }

  /* @page {
    size: 58mm 100%;
    margin: 0;
  } */

  @media print {
    html,
    body {
      // width: 80mm;
      height: 100vh;
    }

    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }
  }
`;
