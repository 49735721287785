/* eslint-disable prettier/prettier */
import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Skeleton,
} from '@mui/material';

interface Props {
  icon: React.ReactNode;
  infoX?: React.ReactNode;
  value: number | string;
  subTitle: string;
  loading?: boolean;
}

// interface PropsProgress {
//   colorx: string;
// }

// const BorderLinearProgressStyled = styled(LinearProgress)(({ colorx }: PropsProgress) => ({
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: colorx,
//   },
// }));

// const BorderLinearProgress = React.forwardRef<HTMLElement, PropsProgress>(
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   function BorderLinearProgress(props, ref) {
//     const { colorx, ...other } = props;

//     return (
//       <BorderLinearProgressStyled {...other} colorx={colorx} variant="determinate" value={100} ref={ref} />
//     );
//   }
// );

export default function CardX({ icon, infoX, value, subTitle, loading }: Props) {
  return (
    <Card>
      <CardContent>
        <div>
          {loading ? <Skeleton animation="wave" variant="circular" width={40} height={40} /> : icon}
        </div>

        <Typography variant="subtitle2" color="text.secondary">
          {loading ? <Skeleton /> : subTitle}
        </Typography>

        {loading ? (
          <Skeleton sx={{ mt: 5.5 }} />
        ) : infoX || <Typography variant="h4" color="text.primary" sx={{ mt: 4 }}>
          {value}
        </Typography>
        }
      </CardContent >
    </Card>
  );
}

CardX.defaultProps = {
  infoX: undefined,
  loading: false,
};
