import React from 'react';
import { useSnackbar, SnackbarKey } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  keySnack: SnackbarKey;
}

export default function CloseButton({ keySnack }: Props): React.ReactElement {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      aria-label="Fechar notificação"
      color="inherit"
      onClick={() => closeSnackbar(keySnack)}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
}
