import { Print } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import ReactToPrint from 'react-to-print';

type ActionButtonProps = {
  componentToPrint: React.MutableRefObject<null>;
  size?: 'small' | 'medium' | 'large' | undefined;
  pageStyle?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBeforeGetContent?: () => void | Promise<any>;
};

export default function ButtonPrint({
  componentToPrint,
  size,
  pageStyle,
  onBeforeGetContent,
}: ActionButtonProps) {
  const buttonPrint = () => (
    <Tooltip title="Imprimir" placement="bottom">
      <IconButton
        id="print"
        size={size}
        sx={{ mt: size === 'medium' ? 1.2 : 0, ml: size === 'medium' ? 1 : 0 }}
      >
        <Print fontSize="medium" />
      </IconButton>
    </Tooltip>
  );

  return (
    <ReactToPrint
      trigger={buttonPrint}
      pageStyle={pageStyle}
      content={() => componentToPrint.current}
      onBeforeGetContent={onBeforeGetContent}
    />
  );
}

ButtonPrint.defaultProps = {
  size: 'medium',
  pageStyle: undefined,
  onBeforeGetContent: undefined,
};
