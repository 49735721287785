import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { List } from '@mui/icons-material';
import TextField from '../../components/TextField';
import NumberField from '../../components/NumberField';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  isSubmitting?: boolean;
  isError: boolean;
  isLoadingCustomer: boolean;
  onList?: () => void;
  onBlurCustomer: (
    event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export default function Form({
  control,
  isSubmitting,
  isError,
  isLoadingCustomer,
  onList,
  onBlurCustomer,
}: Props) {
  const adornmentValue: JSX.Element = (
    <InputAdornment position="start">
      <Typography component="span" variant="caption">
        R$
      </Typography>
    </InputAdornment>
  );

  return (
    <>
      {isError && (
        <Card sx={{ border: '1px solid #AF3335 !important', height: 40 }}>
          <CardHeader
            sx={{ p: 1 }}
            title={
              <Typography
                variant="body2"
                color="primary.main"
                fontWeight="bold"
                textAlign="center"
              >
                Cliente não localizado
              </Typography>
            }
          />
        </Card>
      )}
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <FormControl variant="standard" sx={{ mt: 2 }} fullWidth>
            <InputLabel htmlFor="type-required" shrink>
              Tipo de Lançamento
            </InputLabel>
            <Controller
              name="type"
              control={control}
              defaultValue="credit"
              render={({ field }) => (
                <Select
                  {...field}
                  id="type"
                  variant="standard"
                  color="primary"
                  label=" Tipo de Lançamento"
                  required
                  autoFocus
                  disabled={isSubmitting || isLoadingCustomer}
                  value={field.value || 'credit'}
                >
                  <MenuItem value="debit">
                    <Typography>Débito</Typography>
                  </MenuItem>
                  <MenuItem value="credit">
                    <Typography>Crédito</Typography>
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            id="cpf_cnpj"
            name="cpf_cnpj"
            control={control}
            defaultValue=""
            variant="standard"
            mask="000.000.000-00"
            fullWidth
            label="CPF"
            required
            onBlur={e => onBlurCustomer(e)}
            InputLabelProps={{ shrink: true }}
            disabled={isSubmitting || isLoadingCustomer}
          />
        </Grid>
        {isLoadingCustomer ? (
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              w: '100%',
            }}
          >
            <CircularProgress color="primary" size={20} />
          </Grid>
        ) : (
          <>
            <Grid item md={onList ? 10 : 12} xs={onList ? 10 : 12}>
              <TextField
                id="customer_info"
                name="customer.name"
                control={control}
                defaultValue=""
                variant="standard"
                fullWidth
                label="Cliente"
                InputLabelProps={{ shrink: true }}
                disabled
                focused={false}
              />
            </Grid>
            {onList && (
              <Grid
                item
                md={2}
                xs={2}
                sx={{ display: 'flex', alignItems: 'end' }}
              >
                <IconButton aria-label="list" onClick={onList}>
                  <List />
                </IconButton>
              </Grid>
            )}
          </>
        )}
        <Grid item md={6} xs={6}>
          <NumberField
            id="value"
            name="value"
            control={control}
            variant="standard"
            defaultValue={0}
            fullWidth
            label="Valor"
            decimalScale={2}
            InputLabelProps={{ shrink: true }}
            disabled={isSubmitting || isLoadingCustomer}
            startAdornment={adornmentValue}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <NumberField
            id="balance"
            name="balance"
            control={control}
            variant="standard"
            defaultValue={0}
            fullWidth
            label="Saldo"
            disabled
            focused={false}
            decimalScale={2}
            InputLabelProps={{ shrink: true }}
            startAdornment={adornmentValue}
          />
        </Grid>
        <Grid item md={12} xs={12} sx={{ mb: 2 }}>
          <TextField
            id="observation"
            name="observation"
            control={control}
            defaultValue=""
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            maxRows={3}
            label="Observação"
            InputLabelProps={{ shrink: true }}
            disabled={isSubmitting || isLoadingCustomer}
          />
        </Grid>
      </Grid>
    </>
  );
}

Form.defaultProps = {
  isSubmitting: false,
  onList: undefined,
};
