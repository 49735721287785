/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import NumberFormat from 'react-number-format';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { TextFieldStyled } from './styles';

interface Props {
  decimalScale?: number;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export default function NumberX({
  decimalScale,
  startAdornment,
  endAdornment,
  ...textFieldProps
}: Props & TextFieldProps): React.ReactElement {
  const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
    props: CustomProps,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        decimalScale={decimalScale}
        thousandSeparator="."
        decimalSeparator=","
        isNumericString
        allowNegative={false}
        prefix=""
        {...(decimalScale && decimalScale > 0 && { fixedDecimalScale: true })}
      />
    );
  });

  return (
    <TextFieldStyled
      {...textFieldProps}
      margin="normal"
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        startAdornment,
        endAdornment,
      }}
    />
  );
}

NumberX.defaultProps = {
  decimalScale: 0,
  startAdornment: undefined,
  endAdornment: undefined,
};
