/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Controller, FormState } from 'react-hook-form';
import {
  Autocomplete,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
  TextField as TextFieldCore,
  FormControlLabel,
  Checkbox,
  Box,
  AppBar,
  Tabs,
  Tab,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import TextField from '../../components/TextField';
import NumberField from '../../components/NumberField';
import AutocompleteAsync from '../../components/AutocompleteAsync';
import { ICities } from '../../models';
import TabPanel from '../../components/TabPanel';
import Map from '../../components/Map';

interface Props {
  latitude?: number;
  longitude?: number;
  control: any;
  setValueLocation: (latitude: number, longitude: number) => void;
  formState: FormState<Record<string, any>>;
  isSubmitting?: boolean;
  states: any[];
  urlCities: string;
  urlNeighborhoods: string;
  beforeChangeState: (obj: any) => void;
  beforeChangeCities: (obj: ICities) => void;
  action: () => Promise<void>;
  isLoadingAction: boolean;
  localMenu: boolean;
  cashback: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  disabledCheck: {
    color: 'red',
  },
}));

export default function Form({
  latitude,
  longitude,
  control,
  setValueLocation,
  formState,
  isSubmitting,
  states,
  urlCities,
  urlNeighborhoods,
  beforeChangeState,
  beforeChangeCities,
  action,
  isLoadingAction,
  localMenu,
  cashback,
  children,
}: Props) {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const classes = useStyles();

  const maskCPFCNPJ = [
    {
      mask: '000.000.000-00',
      overwrite: true,
    },
    {
      mask: '00.000.000/0000-00',
      overwrite: true,
    },
  ];

  const onValidateCitie = () => {
    if (urlCities === '') {
      return 'Por favor selecione a UF para efetuar a pesquisa de cidades';
    }

    return '';
  };

  const onValidateNeighborhoods = () => {
    if (urlNeighborhoods === '') {
      return 'Por favor selecione a cidade para efetuar a pesquisa dos bairros';
    }

    return '';
  };

  const adornmentMinute: JSX.Element = (
    <InputAdornment position="end">
      <Tooltip title="Minutos" placement="top-start">
        <Typography component="span" variant="caption">
          min.
        </Typography>
      </Tooltip>
    </InputAdornment>
  );

  const adornmentValue: JSX.Element = (
    <InputAdornment position="start">
      <Typography component="span" variant="caption">
        R$
      </Typography>
    </InputAdornment>
  );

  const adornmentPercentual: JSX.Element = (
    <InputAdornment position="start">
      <Typography component="span" variant="caption">
        %
      </Typography>
    </InputAdornment>
  );

  function a11yProps(index: number) {
    return {
      id: `tab-info-${index}`,
      'aria-controls': `tab-info-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const verifyErros = () => {
    let descriptionVerify: string | undefined;

    if (
      (formState.errors.corporate_name ||
        formState.errors.fantasy_name ||
        formState.errors.cpf_cnpj ||
        formState.errors.email ||
        formState.errors.cellphone ||
        formState.errors.phone) &&
      value !== 0
    ) {
      descriptionVerify = '* Verifique a aba "Informações"';
    } else if (
      (formState.errors.zip_code ||
        formState.errors.state ||
        formState.errors.citie ||
        formState.errors.neighborhood ||
        formState.errors.address) &&
      value !== 1
    ) {
      descriptionVerify = '* Verifique a aba "Endereço"';
    } else if (
      (formState.errors.delivery_time_initial ||
        formState.errors.delivery_time_final ||
        formState.errors.withdrawal_time_initial ||
        formState.errors.withdrawal_time_final ||
        formState.errors.minimum_order_value ||
        formState.errors.delivery_rate_value ||
        formState.errors.allow_delivery ||
        formState.errors.allow_withdrawal) &&
      value !== 2
    ) {
      descriptionVerify = '* Verifique a aba "Pedido"';
    } else if (
      (formState.errors.cashback_module_enabled ||
        formState.errors.cashback_percentage) &&
      value !== 3
    ) {
      descriptionVerify = '* Verifique a aba "Módulos"';
    }

    if (descriptionVerify === undefined) {
      return undefined;
    }

    return (
      <Typography component="span" variant="caption" color="#FF4842" ml={3}>
        {descriptionVerify}
      </Typography>
    );
  };

  return (
    <Box sx={{ width: '100%', minHeight: 450 }}>
      <AppBar position="static">
        <Tabs
          sx={{ bgcolor: '#f9ebeb' }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            sx={{ textTransform: 'none' }}
            label="Informações"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ textTransform: 'none' }}
            label="Endereço"
            {...a11yProps(1)}
          />
          <Tab
            sx={{ textTransform: 'none' }}
            label="Pedido"
            {...a11yProps(2)}
          />
          <Tab
            sx={{ textTransform: 'none' }}
            label="Módulos"
            {...a11yProps(3)}
          />
        </Tabs>{' '}
      </AppBar>

      {verifyErros()}

      <TabPanel value={value} index={0} dir={theme.direction}>
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  id="corporate_name"
                  name="corporate_name"
                  control={control}
                  defaultValue=""
                  variant="standard"
                  fullWidth
                  label="Razão Social"
                  required
                  autoFocus
                  InputLabelProps={{ shrink: true }}
                  disabled={isSubmitting}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  id="fantasy_name"
                  name="fantasy_name"
                  control={control}
                  defaultValue=""
                  variant="standard"
                  fullWidth
                  label="Nome Fantasia"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={isSubmitting}
                />
              </Grid>

              <Grid item md={8} xs={12}>
                <TextField
                  id="about"
                  name="about"
                  control={control}
                  defaultValue=""
                  variant="standard"
                  fullWidth
                  label="Sobre"
                  InputLabelProps={{ shrink: true }}
                  disabled={isSubmitting}
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <TextField
                  id="alias"
                  name="alias"
                  control={control}
                  defaultValue=""
                  variant="standard"
                  fullWidth
                  label="URL Amigável"
                  InputLabelProps={{ shrink: true }}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} xs={12}>
            {children}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <TextField
              id="cpf_cnpj"
              name="cpf_cnpj"
              control={control}
              defaultValue=""
              variant="standard"
              mask={maskCPFCNPJ}
              fullWidth
              label="CPF/CNPJ"
              required
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <TextField
              id="email"
              name="email"
              control={control}
              defaultValue=""
              variant="standard"
              fullWidth
              label="Email"
              required
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <TextField
              id="phone"
              name="phone"
              control={control}
              defaultValue=""
              variant="standard"
              mask="(00) 0000-0000"
              fullWidth
              label="Telefone"
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
            />
          </Grid>

          <Grid item md={2} xs={6}>
            <TextField
              id="cellphone"
              name="cellphone"
              control={control}
              defaultValue=""
              variant="standard"
              mask="(00) 00000-0000"
              fullWidth
              label="Celular"
              required
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <>
          <Grid container spacing={2}>
            <Grid item md={2} xs={12}>
              <TextField
                id="zip_code"
                name="zip_code"
                control={control}
                defaultValue=""
                variant="standard"
                mask="00000-000"
                fullWidth
                label="CEP"
                required
                InputLabelProps={{ shrink: true }}
                disabled={isSubmitting}
                action={action}
                isLoadingAction={isLoadingAction}
              />
            </Grid>

            <Grid item md={3} xs={12}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    clearOnEscape
                    options={states}
                    value={field.value || null}
                    noOptionsText="Sem opções"
                    openText="Abrir"
                    clearText="Limpar"
                    getOptionLabel={option => option.description}
                    isOptionEqualToValue={(option, valueX) =>
                      option.id === valueX.id
                    }
                    closeText="Fechar"
                    autoComplete={false}
                    renderInput={params => (
                      <TextFieldCore
                        {...params}
                        margin="normal"
                        variant="standard"
                        label="UF"
                        required
                        error={!!error}
                        disabled={isSubmitting}
                        helperText={error ? error.message : null}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    onChange={(_, obj) => {
                      field.onChange(obj);

                      beforeChangeState(obj);
                    }}
                    ref={field.ref}
                  />
                )}
                name="state"
                control={control}
              />
            </Grid>

            <Grid item md={3} xs={12}>
              <AutocompleteAsync
                name="citie"
                control={control}
                title="Cidades"
                required
                url={urlCities}
                getLabel={(option: ICities) => option.name}
                onValidate={onValidateCitie}
                disabled={isSubmitting}
                beforeChange={beforeChangeCities}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <AutocompleteAsync
                name="neighborhood"
                control={control}
                title="Bairros"
                required
                url={urlNeighborhoods}
                getLabel={(option: ICities) => option.name}
                onValidate={onValidateNeighborhoods}
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={9} xs={12}>
              <TextField
                id="address"
                name="address"
                control={control}
                defaultValue=""
                variant="standard"
                fullWidth
                label="Endereço"
                required
                InputLabelProps={{ shrink: true }}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                id="address_number"
                name="address_number"
                control={control}
                defaultValue=""
                variant="standard"
                mask="0000"
                fullWidth
                label="Número"
                InputLabelProps={{ shrink: true }}
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>
          <Map
            latitude={latitude}
            longitude={longitude}
            setValueLocation={setValueLocation}
          />
        </>
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <Grid container spacing={2}>
          <Grid item md={2} xs={6}>
            <NumberField
              id="delivery_time_initial"
              name="delivery_time_initial"
              control={control}
              defaultValue={0}
              variant="standard"
              fullWidth
              label="Prazo de Entrega Inicial"
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
              endAdornment={adornmentMinute}
            />
          </Grid>

          <Grid item md={2} xs={6}>
            <NumberField
              id="delivery_time_final"
              name="delivery_time_final"
              control={control}
              defaultValue={0}
              variant="standard"
              fullWidth
              label="Prazo de Entrega Final"
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
              endAdornment={adornmentMinute}
            />
          </Grid>

          <Grid item md={2} xs={12}>
            <NumberField
              id="delivery_rate_value"
              name="delivery_rate_value"
              control={control}
              defaultValue={0}
              variant="standard"
              fullWidth
              label="Taxa de Entrega"
              decimalScale={2}
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
              startAdornment={adornmentValue}
            />
          </Grid>

          <Grid item md={2} xs={6}>
            <NumberField
              id="withdrawal_time_initial"
              name="withdrawal_time_initial"
              control={control}
              defaultValue={0}
              variant="standard"
              fullWidth
              label="Tempo de Retirada Inicial"
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
              endAdornment={adornmentMinute}
            />
          </Grid>

          <Grid item md={2} xs={6}>
            <NumberField
              id="withdrawal_time_final"
              name="withdrawal_time_final"
              control={control}
              defaultValue={0}
              variant="standard"
              fullWidth
              label="Tempo de Retirada Final"
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
              endAdornment={adornmentMinute}
            />
          </Grid>

          <Grid item md={2} xs={12}>
            <NumberField
              id="minimum_order_value"
              name="minimum_order_value"
              control={control}
              defaultValue={0}
              variant="standard"
              fullWidth
              label="Valor Minímo"
              required
              decimalScale={2}
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
              startAdornment={adornmentValue}
            />
          </Grid>

          <Grid item md={6} xs={6}>
            <TextField
              id="pix_key"
              name="pix_key"
              control={control}
              defaultValue=""
              variant="standard"
              fullWidth
              label="Chave Pix"
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
            />
          </Grid>

          <Grid item md={6} xs={6}>
            <TextField
              id="facebook_pixel_id"
              name="facebook_pixel_id"
              control={control}
              defaultValue=""
              variant="standard"
              fullWidth
              label="Facebook Pixel ID"
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <TextField
              id="cart_observation"
              name="cart_observation"
              control={control}
              defaultValue=""
              variant="standard"
              fullWidth
              label="Observação do Carrinho"
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
            />
          </Grid>

          <Grid container sx={{ marginLeft: 2 }}>
            <Grid item md={2} xs={12}>
              <FormControlLabel
                sx={{ width: '100%' }}
                control={
                  <Controller
                    name="allow_delivery"
                    control={control}
                    defaultValue
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        id="allow_delivery"
                        color="primary"
                        onChange={e => field.onChange(e.target.checked)}
                        checked={field.value || false}
                        disabled={isSubmitting}
                      />
                    )}
                  />
                }
                label="Permite Entrega"
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <FormControlLabel
                sx={{ width: '100%' }}
                control={
                  <Controller
                    name="allow_withdrawal"
                    control={control}
                    defaultValue
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        id="allow_withdrawal"
                        color="primary"
                        onChange={e => field.onChange(e.target.checked)}
                        checked={field.value || false}
                        disabled={isSubmitting}
                      />
                    )}
                  />
                }
                label="Permite Retirada"
              />
            </Grid>
            <Grid item md={8} xs={12}>
              <FormControlLabel
                sx={{ width: '100%' }}
                control={
                  <Controller
                    name="notify_new_order_by_whatsapp"
                    control={control}
                    defaultValue
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        id="notify_new_order_by_whatsapp"
                        color="primary"
                        onChange={e => field.onChange(e.target.checked)}
                        checked={field.value || false}
                        disabled={isSubmitting}
                      />
                    )}
                  />
                }
                label="Notifique-me de Novos Pedidos pelo WhatsApp"
              />
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <Card sx={{ border: '1px solid #cccccc !important' }}>
          <CardHeader
            sx={{
              padding: '0.3rem 1.0rem 0.3rem 1.0rem',
              bgcolor: localMenu ? '#f9ebeb' : 'white',
            }}
            title={
              <FormControlLabel
                sx={{ width: '100%' }}
                control={
                  <Controller
                    name="local_menu_module_enabled"
                    control={control}
                    defaultValue
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        id="local_menu_module_enabled"
                        color="primary"
                        checked={field.value || false}
                        size="small"
                        disabled
                      />
                    )}
                  />
                }
                label="Cardápio Local"
              />
            }
            titleTypographyProps={{ variant: 'body1' }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  id="local_menu_observation"
                  name="local_menu_observation"
                  control={control}
                  defaultValue=""
                  variant="standard"
                  fullWidth
                  label="Observação"
                  InputLabelProps={{ shrink: true }}
                  disabled={!localMenu || isSubmitting}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ border: '1px solid #cccccc !important', mt: 2 }}>
          <CardHeader
            sx={{
              padding: '0.3rem 1.0rem 0.3rem 1.0rem',
              bgcolor: cashback ? '#f9ebeb' : 'white',
            }}
            title={
              <FormControlLabel
                sx={{ width: '100%' }}
                control={
                  <Controller
                    name="cashback_module_enabled"
                    control={control}
                    defaultValue
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        id="cashback_module_enabled"
                        color="primary"
                        checked={field.value || false}
                        size="small"
                        disabled
                      />
                    )}
                  />
                }
                label="Cashback"
              />
            }
            titleTypographyProps={{ variant: 'body1' }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={3} xs={6}>
                <NumberField
                  id="cashback_percentage"
                  name="cashback_percentage"
                  control={control}
                  defaultValue={0}
                  variant="standard"
                  fullWidth
                  label="Cashback"
                  decimalScale={0}
                  InputLabelProps={{ shrink: true }}
                  disabled={!cashback || isSubmitting}
                  startAdornment={adornmentPercentual}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <NumberField
                  id="cashback_rescue_minimum_value"
                  name="cashback_rescue_minimum_value"
                  control={control}
                  defaultValue={0}
                  variant="standard"
                  fullWidth
                  label="Valor Minímo de Resgate"
                  decimalScale={2}
                  InputLabelProps={{ shrink: true }}
                  disabled={!cashback || isSubmitting}
                  startAdornment={adornmentValue}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </TabPanel>
    </Box>
  );
}

Form.defaultProps = {
  latitude: 0,
  longitude: 0,
  isSubmitting: false,
};
