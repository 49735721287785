import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import ActionsDataTable from '../../components/ActionsDataTable';
import useData from '../../services/hooks/useData';
import useUtility from '../../hooks/useUtility';
import DataTable from '../../components/DataTable';
import DialogForm from '../../components/DialogForm';
import Page from '../../components/Page';
import Label from '../../components/Label';
import { ICategory } from '../../models';
import Form from './Form';
import Title from '../../components/Title';
import { useCan } from '../../hooks/useCan';
import { orderCategory } from '../../statics';

const schema = yup
  .object()
  .shape({
    description: yup.string().required().nullable(),
    priority: yup
      .number()
      .required()
      .typeError('Este campo é obrigatório')
      .min(0)
      .max(999),
    order: yup.string().required().nullable(),
    active: yup.boolean().required(),
  })
  .required();

yup.setLocale(ptForm);

export default function Category() {
  const {
    data,
    isLoading,
    isFetch,
    isSubmitting,
    create,
    update,
    getData,
    fetchData,
  } = useData<ICategory>('categories');
  const dispatch = useDispatch();
  const { closeDialog, utilitySelector } = useUtility();
  const { control, handleSubmit, reset } = useForm<ICategory>({
    resolver: yupResolver(schema),
  });

  const [categories, setCategories] = useState<ICategory[]>([]);

  const isCreate = useCan({ view: 'category.create' });
  const isUpdate = useCan({ view: 'category.update' });

  useEffect(() => {
    if (utilitySelector.dialogOpen) {
      if (utilitySelector.dialogId) {
        fetchData(utilitySelector.dialogId).then(response => {
          reset(response);
        });
      } else {
        reset({});
      }
    }
  }, [utilitySelector.dialogOpen, utilitySelector.dialogId, fetchData, reset]);

  function formatCategories(category: ICategory): ICategory {
    return {
      ...category,
      order_description: orderCategory.find(c => c.id === category.order)
        ?.description,
    };
  }

  useEffect(() => {
    const formattedData: ICategory[] = [];

    data.forEach(category => {
      formattedData.push(formatCategories(category));
    });

    setCategories(formattedData);
  }, [data]);

  const activeTemplate = (value: boolean) => {
    return (
      <Label variant="ghost" color={(!value && 'error') || 'success'}>
        {value ? 'Sim' : 'Não'}
      </Label>
    );
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const mapColumns = (category: ICategory): ICategory => {
    const result: ICategory = {
      description: category.description,
      active: category.active,
      priority: category.priority,
      order: category.order,
    };

    return result;
  };

  const onSubmit = async (formData: ICategory) => {
    if (utilitySelector.dialogId) {
      if (formData.id) await update(formData.id, mapColumns(formData));
    } else {
      await create(formData);
    }

    dispatch(closeDialog());
  };

  const activeCategory = async (category: ICategory) => {
    category.active = !category.active;
    if (category.id) await update(category.id, mapColumns(category));
  };

  const actions = ActionsDataTable(
    true,
    categories,
    activeCategory,
    undefined,
    !isUpdate
  );

  const columns = [
    {
      name: 'description',
      label: 'Descrição',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'priority',
      label: 'Prioridade',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'order_description',
      label: 'Ordenar Itens Por',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'active',
      label: 'Ativo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: boolean) => activeTemplate(value),
      },
    },
  ];

  if (actions) {
    columns.push(actions);
  }

  return (
    <Page>
      <DataTable
        title={<Title description="Categorias" isLoading={isLoading} />}
        data={categories}
        columns={columns}
        isLoading={isLoading}
        refresh={getData}
        hideActionAddToolbar={!isCreate}
      />

      <DialogForm
        titleDiag="Categoria"
        open={utilitySelector.dialogOpen}
        isLoading={isFetch}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form control={control} isSubmitting={isSubmitting} />
      </DialogForm>
    </Page>
  );
}
