import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import {
  Business,
  DeveloperBoard,
  CurrencyExchange,
  ReceiptLong,
  Home,
  PeopleAlt,
  // Person,
  Map,
  Category,
  Bento,
  LibraryBooks,
  LocalMall,
  Article,
  FormatListNumbered,
  DateRange,
} from '@mui/icons-material';
import ListItem from '@mui/material/ListItem';
import { Badge, Divider, ListSubheader, Stack, Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { IView } from '../../models';

interface Props {
  unified: boolean;
  views: IView;
  showChangeLog: boolean;
  isAdmin: boolean;
  openMenu: boolean;
  cashbackModuleEnabled: boolean;
}

export function MenuItems({
  unified,
  views,
  showChangeLog,
  isAdmin,
  openMenu,
  cashbackModuleEnabled,
}: Props) {
  const location = useLocation();

  return (
    <>
      {openMenu && <ListSubheader inset>Dashboard</ListSubheader>}
      <ListItem
        button
        selected={location.pathname === '/inicio'}
        component={Link}
        to="/inicio"
      >
        <ListItemIcon>
          <Tooltip title="Início" placement="right-start">
            <Home />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Início" />
      </ListItem>

      {views.topSellingProducts && (
        <ListItem
          button
          selected={location.pathname === '/produtosmaisvendidos'}
          component={Link}
          to="/produtosmaisvendidos"
        >
          <ListItemIcon>
            <Tooltip title="Produtos Mais Vendidos" placement="right-start">
              <FormatListNumbered />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Prod. Mais Vendidos" />
        </ListItem>
      )}

      {views.ordersPerDay && (
        <ListItem
          button
          selected={location.pathname === '/pedidospordia'}
          component={Link}
          to="/pedidospordia"
        >
          <ListItemIcon>
            <Tooltip title="Pedidos por Dia" placement="right-start">
              <DateRange />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Pedidos por Dia" />
        </ListItem>
      )}

      <Divider />

      <Stack sx={{ mt: 1 }}>
        {views.product && (
          <ListItem
            button
            selected={location.pathname === '/catalogos'}
            component={Link}
            to="/catalogos"
          >
            <ListItemIcon>
              <Tooltip title="Catálogos" placement="right-start">
                <DeveloperBoard />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Catálogos" />
          </ListItem>
        )}

        {views.category && (
          <ListItem
            button
            selected={location.pathname === '/categorias'}
            component={Link}
            to="/categorias"
          >
            <ListItemIcon>
              <Tooltip title="Categorias" placement="right-start">
                <Category />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Categorias" />
          </ListItem>
        )}

        {cashbackModuleEnabled && views.cashback && (
          <ListItem
            button
            selected={location.pathname === '/cashbacks'}
            component={Link}
            to="/cashbacks"
          >
            <ListItemIcon>
              <Tooltip title="Cashbacks" placement="right-start">
                <CurrencyExchange />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Cashbacks" />
          </ListItem>
        )}

        {views.customer && (
          <ListItem
            button
            selected={location.pathname === '/clientes'}
            component={Link}
            to="/clientes"
          >
            <ListItemIcon>
              <Tooltip title="Clientes" placement="right-start">
                <PeopleAlt />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Clientes" />
          </ListItem>
        )}

        {views.complement && (
          <ListItem
            button
            selected={location.pathname === '/complementos'}
            component={Link}
            to="/complementos"
          >
            <ListItemIcon>
              <Tooltip title="Complementos" placement="right-start">
                <LibraryBooks />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Complementos" />
          </ListItem>
        )}

        {views.affiliate && (
          <ListItem
            button
            selected={location.pathname === '/filiais'}
            component={Link}
            to="/filiais"
          >
            <ListItemIcon>
              <Tooltip title="Filiais" placement="right-start">
                <Business />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Filiais" />
          </ListItem>
        )}

        {views.product && !unified && (
          <ListItem
            button
            selected={location.pathname === '/produtos'}
            component={Link}
            to="/produtos"
          >
            <ListItemIcon>
              <Tooltip title="Produtos" placement="right-start">
                <Bento />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Produtos" />
          </ListItem>
        )}

        {views.affiliateDeliveryArea && (
          <ListItem
            button
            selected={location.pathname === '/areasentrega'}
            component={Link}
            to="/areasentrega"
          >
            <ListItemIcon>
              <Tooltip title="Áreas de Entrega" placement="right-start">
                <Map />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Áreas de Entrega" />
          </ListItem>
        )}

        {(isAdmin || views.invoice) && (
          <ListItem
            button
            selected={location.pathname === '/faturas'}
            component={Link}
            to="/faturas"
          >
            <ListItemIcon>
              <Tooltip title="Faturas" placement="right-start">
                <ReceiptLong />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Faturas" />
          </ListItem>
        )}

        <Divider />

        <ListItem
          button
          selected={location.pathname === '/novidades'}
          component={Link}
          to="/novidades"
        >
          <ListItemIcon>
            <Tooltip title="Novidades" placement="right-start">
              <Badge color="primary" {...(showChangeLog && { variant: 'dot' })}>
                <Article />
              </Badge>
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Novidades" />
        </ListItem>

        {/* <ListItem
          button
          selected={location.pathname === '/usuarios'}
          component={Link}
          to="/usuarios"
        >
          <ListItemIcon>
            <Tooltip title="Usuários" placement="right-start">
              <Person />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Usuários" />
        </ListItem> */}

        {views.order && (
          <>
            <Divider />
            {openMenu && <ListSubheader inset>Central</ListSubheader>}
            <ListItem
              button
              selected={location.pathname === '/pedidos'}
              component={Link}
              to="/pedidos"
            >
              <ListItemIcon>
                <Tooltip title="Pedidos" placement="right-start">
                  <LocalMall />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Pedidos" />
            </ListItem>
          </>
        )}
      </Stack>
    </>
  );
}
