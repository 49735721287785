/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { Home, Work, WhereToVote } from '@mui/icons-material';
import DataTable from '../../components/DataTable';
import { getCustomerAddresses } from '../../services/hooks/useCustomerAddresses';
import DialogX from '../../components/DialogX';
import { ICustomer, ICustomerAddress, INeighborhood } from '../../models';
import Title from '../../components/Title';

interface Props {
  customer: ICustomer;
  open: boolean;
  close: () => void;
}

export default function Addresses({ customer, open, close }: Props) {
  const [isLoadingX, setIsLoadingX] = useState(false);
  const [addresses, setAddresses] = useState<ICustomerAddress[]>([]);
  const [customerX, setCustomerX] = useState<ICustomer>({} as ICustomer);

  const typeAddressTemplate = (type: string) => {
    if (type === 'home') {
      return <Home fontSize="small" />;
    }

    if (type === 'work') {
      return <Work sx={{ fontSize: '1.05rem' }} fontSize="small" />;
    }

    return <WhereToVote fontSize="small" />;
  };

  const neighborhoodTemplate = (value: INeighborhood) => {
    return <div>{value.name}</div>;
  };

  const citieTemplate = (value: INeighborhood) => {
    return (
      <div>
        {value.city?.name}-{value.city?.state}
      </div>
    );
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'type',
      label: 'Tipo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (type: string) => typeAddressTemplate(type),
      },
    },
    {
      name: 'title',
      label: 'Descrição',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'address',
      label: 'Endereço',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'number',
      label: 'Número',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'neighborhood',
      label: 'Bairro',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: INeighborhood) => neighborhoodTemplate(value),
      },
    },
    {
      name: 'neighborhood',
      label: 'Cidade',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: INeighborhood) => citieTemplate(value),
      },
    },
    {
      name: 'complement',
      label: 'Complemento',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const handleClose = () => {
    close();
    setAddresses([]);
  };

  const refresh = async () => {
    setIsLoadingX(true);
    await getCustomerAddresses(customer.id ?? '')
      .then(result => setAddresses(result))
      .finally(() => {
        setIsLoadingX(false);
      });
  };

  useEffect(() => {
    if (customer.id) {
      setCustomerX(customer);
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer.id]);

  return (
    <DialogX
      titleDiag={customerX.name}
      open={open}
      maxWidth="lg"
      isLoading={isLoadingX}
      captionCancel="Fechar"
      handleClose={handleClose}
    >
      <DataTable
        title={<Title description="Endereços" isLoading={isLoadingX} />}
        data={addresses || []}
        columns={columns}
        isLoading={isLoadingX}
        refresh={refresh}
        hideActionAddToolbar
      />
    </DialogX>
  );
}
