import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Circle, Square, PanoramaFishEye } from '@mui/icons-material';

interface Props {
  text?: string;
  nivel: 1 | 2 | 3;
  children?: React.ReactNode;
}

export default function Item({ text, nivel, children }: Props) {
  const nivelItem = () => {
    if (nivel === 1) {
      return <Circle sx={{ fontSize: 8, mt: 1 }} />;
    }

    if (nivel === 2) {
      return <Square sx={{ fontSize: 8, mt: 1 }} />;
    }

    return <PanoramaFishEye sx={{ fontSize: 8, mt: 1 }} />;
  };

  return (
    <Stack direction="row" sx={{ mt: 2, ml: 4 * nivel }}>
      {nivelItem()}
      {children || (
        <Typography variant="body1" sx={{ ml: 2 }}>
          {text}
        </Typography>
      )}
    </Stack>
  );
}

Item.defaultProps = {
  text: undefined,
  children: undefined,
};
