import React, { useState, useEffect } from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Hidden from '@mui/material/Hidden';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// import MailIcon from '@mui/icons-material/Mail';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import Badge from '@mui/material/Badge';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  List,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AvatarMenu from '../AvatarMenu';
import { MenuItems } from '../MenuItems';
import useUtility from '../../hooks/useUtility';
import { IAffiliate, IView } from '../../models';
import useData from '../../services/hooks/useData';
import useLogin from '../../hooks/useLogin';
import Label from '../Label';
import DialogX from '../DialogX';

const drawerWidth = 240;

interface Props {
  children: React.ReactNode;
  views: IView;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function AppMenu({ children, views }: Props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openDrawer, closeDrawer, utilitySelector } = useUtility();
  const { userSelector, setSituationAffiliate } = useLogin();
  const [checked, setChecked] = useState(false);
  const { patchJson } = useData<IAffiliate>('', undefined, true);
  const [isDialog, setIsDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerResponsive = () => {
    if (window.innerWidth < 900) {
      return false;
    }

    return true;
  };

  const handleDrawerOpen = () => {
    dispatch(openDrawer());
  };

  const handleDrawerClose = () => {
    dispatch(closeDrawer());
  };

  useEffect(() => {
    const resizeChecker = () => {
      if (handleDrawerResponsive()) {
        dispatch(openDrawer());
      } else {
        dispatch(closeDrawer());
      }
    };

    window.addEventListener('resize', resizeChecker);

    return () => {
      window.addEventListener('resize', resizeChecker);
    };
  });

  const handleChange = async () => {
    setIsDialog(true);
  };

  useEffect(() => {
    if (userSelector.affiliate?.situation === 'opened') {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [userSelector.affiliate?.situation]);

  const redirectHomePage = () => {
    navigate('/inicio');
  };

  const messageConfSituation = () => {
    let message = 'o fechamento';

    if (!checked) {
      message = 'a abertura';
    }

    return `Confirma ${message} do estabelecimento?`;
  };

  const handleClose = () => setIsDialog(false);

  const onOk = async () => {
    setIsSubmitting(true);
    await patchJson(`affiliate/situation/${userSelector.affiliate?.id}`, {
      situation: !checked ? 'opened' : 'closed',
    })
      .then(() => {
        dispatch(setSituationAffiliate(!checked ? 'opened' : 'closed'));
        setIsDialog(false);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={utilitySelector.drawerOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginLeft: '-16px',
              marginRight: '36px',
              ...(utilitySelector.drawerOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              width: {
                x: 'auto',
                md: '300px',
                cursor: 'pointer',
              },
            }}
            onClick={redirectHomePage}
          >
            <Stack direction="row">
              <img src="app-logo-transp.webp" alt="Mercurius" width="120" />
            </Stack>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {/* for Tablet */}
          <Box sx={{ display: { md: 'flex', xs: 'flex' } }}>
            {/* <IconButton size="large" aria-label="show new mails">
              <Badge badgeContent={4} color="secondary">
                <MailIcon sx={{ color: '#FFFF' }} />
              </Badge>
            </IconButton>
            <IconButton size="large" aria-label="show new notifications">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon sx={{ color: '#FFFF' }} />
              </Badge>
          </IconButton> */}
            {views.openClose && (
              <FormGroup sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      color="success"
                      checked={checked}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <Hidden smDown>
                      <Label variant="outlined" color="white">
                        {checked ? 'Aberto' : 'Fechado'}
                      </Label>
                    </Hidden>
                  }
                />
              </FormGroup>
            )}
            <AvatarMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Hidden mdDown={!utilitySelector.drawerOpen && true}>
        <Drawer variant="permanent" open={utilitySelector.drawerOpen}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List
            sx={{
              overflowX: 'hidden',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '4px',
                borderRadius: '4px',
                backgroundColor: 'grey.100',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '4px',
                backgroundColor: 'grey.400',
              },
            }}
          >
            <MenuItems
              unified={!userSelector.enable_multiple_affiliate}
              views={views}
              showChangeLog={userSelector.user.show_changelog}
              isAdmin={userSelector.access.type === 'admin'}
              cashbackModuleEnabled={
                userSelector.affiliate.cashback_module_enabled
              }
              openMenu={utilitySelector.drawerOpen}
            />
          </List>
        </Drawer>
      </Hidden>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
      <DialogX
        titleDiag={userSelector.affiliate.fantasy_name}
        open={isDialog}
        onOk={() => onOk()}
        captionOk="Confirmar"
        captionCancel="Cancelar"
        handleClose={handleClose}
        isLoading={isSubmitting}
        autoFocus
      >
        {isSubmitting ? (
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          <Typography variant="body2">{messageConfSituation()}</Typography>
        )}
      </DialogX>
    </Box>
  );
}
