import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { IConfiguration } from '../../models';
import { modelPrintOrder } from '../../statics';
import Page from '../../components/Page';
import Form from '../../components/Form';
import PaperInfo from '../../components/PaperInfo';
import Title from '../../components/Title';
import snack from '../../components/SnackbarUtilsConfigurator';

const schema = yup
  .object()
  .shape({
    order_print_model: yup.string().required().nullable(),
  })
  .required();

yup.setLocale(ptForm);

export default function Configure() {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<IConfiguration>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const strConfigurations = localStorage.getItem('Configurations');

    if (strConfigurations) {
      const configurations = JSON.parse(strConfigurations) as IConfiguration;
      reset({ order_print_model: configurations.order_print_model });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (formData: IConfiguration) => {
    localStorage.setItem('Configurations', JSON.stringify(formData));
    snack.success('Configurações atualizadas com sucesso');
  };

  return (
    <Page>
      <PaperInfo>
        <Box sx={{ width: '100%', pt: 1, pb: 2 }}>
          <Title description="Configurações" />
          <Form onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting}>
            <FormControl variant="standard" sx={{ mt: 2, mb: 4 }} fullWidth>
              <InputLabel htmlFor="type-required" shrink>
                Modelo de Impressão de Pedido
              </InputLabel>
              <Controller
                name="order_print_model"
                control={control}
                defaultValue="model1_80mm"
                render={({ field }) => (
                  <Select
                    {...field}
                    sx={{ textAlign: 'left' }}
                    id="order_print_model"
                    variant="standard"
                    color="primary"
                    label="Impressora"
                    required
                    value={field.value || 'model1_80mm'}
                  >
                    {modelPrintOrder.map(model => (
                      <MenuItem key={model.id} value={model.id}>
                        <Typography>{model.description}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Form>
        </Box>
      </PaperInfo>
    </Page>
  );
}
