import useLogin from './useLogin';

type UseCanParams = {
  view?: string;
  isAdmin?: boolean;
};

export function useCan({ view, isAdmin }: UseCanParams) {
  const { userSelector } = useLogin();

  if (isAdmin && userSelector.access.type === 'admin') {
    return true;
  }

  if (userSelector.access.type === 'supervisor') {
    return true;
  }

  if (!view) {
    return true;
  }

  if (
    userSelector.access.permissions &&
    userSelector.access.permissions.length > 0
  ) {
    const hasRole = userSelector.access.permissions.some(
      x => x.slug === view && x.value === 'true'
    );

    return hasRole;
  }

  return false;
}

useCan.defaultProps = {
  view: undefined,
  isAdmin: false,
};
