/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  validateEmail,
  validateCPF,
  validateCNPJ,
  validatePhone,
  validateCep,
} from 'validations-br';
import { useDispatch } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import { Payments, LocationCity, Smartphone } from '@mui/icons-material';
import ActionsDataTable from '../../components/ActionsDataTable';
import useData from '../../services/hooks/useData';
import useUtility from '../../hooks/useUtility';
import useLogin from '../../hooks/useLogin';
import DataTable from '../../components/DataTable';
import DialogForm from '../../components/DialogForm';
import Page from '../../components/Page';
import Label from '../../components/Label';
import { IAffiliate, ICities, INeighborhood, IZipCode } from '../../models';
import Form from './Form';
import ImagePreview from '../../components/ImagePreview';
import { states } from '../../statics/index';
import { maskCPFCNPJ, maskCEP } from '../../utils/index';
import PaymentMethods from './PaymentMethods';
import Title from '../../components/Title';
import snack from '../../components/SnackbarUtilsConfigurator';
import Cities from './Cities';
import Phones from './Phones';

const phoneValidate = (value: string | null | undefined) => {
  if (!value) {
    return true;
  }

  return validatePhone(value);
};

const cepValidate = (value: string | undefined) => {
  if (!value || value === '') {
    return true;
  }

  return validateCep(maskCEP(value));
};

yup.setLocale(ptForm);
yup.setLocale({ mixed: { notType: 'Valor inválido.' } });

const schema = yup
  .object()
  .shape({
    corporate_name: yup.string().required().nullable(),
    fantasy_name: yup.string().required().nullable(),
    cpf_cnpj: yup
      .string()
      .test('is-cpfcnpj', 'CPF/CNPJ inválido', value =>
        value && value?.length > 14
          ? validateCNPJ(value || '')
          : validateCPF(value || '')
      )
      .required()
      .nullable(),
    email: yup
      .string()
      .required()
      .test('is-email', 'Email inválido', value => validateEmail(value || ''))
      .nullable(),
    cellphone: yup
      .string()
      .required()
      .test('is-cellphone', 'Celular inválido', value =>
        validatePhone(value || '')
      )
      .nullable(),
    phone: yup
      .string()
      .notRequired()
      .nullable()
      .test('is-phone', 'Telefone inválido', value => phoneValidate(value)),
    zip_code: yup
      .string()
      .test('is-zipcode', 'CEP inválido', value => cepValidate(value))
      .nullable(),
    state: yup.object().required().nullable(),
    citie: yup.object().required().nullable(),
    neighborhood: yup.object().required().nullable(),
    address: yup.string().required().nullable(),
    delivery_time_initial: yup.number().when('allow_delivery', {
      is: true,
      then: yup.number().required(),
    }),
    delivery_time_final: yup
      .number()
      .min(
        yup.ref('delivery_time_initial'),
        'O campo deve ser maior ou igual ao "Prazo de Entrega Inicial"'
      )
      .when('allow_delivery', {
        is: true,
        then: yup.number().required(),
      }),
    withdrawal_time_initial: yup.number().when('allow_withdrawal', {
      is: true,
      then: yup.number().required(),
    }),
    withdrawal_time_final: yup
      .number()
      .min(
        yup.ref('withdrawal_time_initial'),
        'O campo deve ser maior ou igual ao "Tempo de Retirada Inicial"'
      )
      .when('allow_withdrawal', {
        is: true,
        then: yup.number().required(),
      }),
    minimum_order_value: yup.number().required().nullable(),
    delivery_rate_value: yup.number().when('allow_delivery', {
      is: true,
      then: yup.number().required(),
    }),
    allow_delivery: yup.boolean().required(),
    allow_withdrawal: yup.boolean().required(),
  })
  .required();

export default function Affiliate() {
  const {
    data,
    isLoading,
    isFetch,
    isSubmitting,
    create,
    update,
    get,
    patch,
    getData,
    fetchData,
  } = useData<IAffiliate>('affiliates');
  const dispatch = useDispatch();
  const { closeDialog, utilitySelector, setDialogId } = useUtility();
  const { setAffiliate, userSelector } = useLogin();
  const { control, handleSubmit, reset, getValues, setValue, formState } =
    useForm<IAffiliate>({
      resolver: yupResolver(schema),
    });
  const [imageUrl, setImageUrl] = useState<string>();
  const [urlCities, setUrlCities] = useState('');
  const [urlNeighborhoods, setUrlNeighborhoods] = useState('');
  const stateX = states;
  const [openPayments, setOpenPayments] = useState(false);
  const [openCities, setOpenCities] = useState(false);
  const [openPhones, setOpenPhones] = useState(false);
  const [affiliateX, setAffiliateX] = useState<IAffiliate>();

  useEffect(() => {
    if (utilitySelector.dialogOpen) {
      if (utilitySelector.dialogId) {
        setImageUrl(undefined);
        fetchData(utilitySelector.dialogId).then(response => {
          const obj = response;

          if (obj) {
            const state = obj.neighborhood?.city?.state;

            if (state) {
              obj.state = stateX.find(x => x.id === state);
              if (obj.state) {
                setUrlCities(
                  `cities?country_id=e9d5b555-b14d-4706-a9b1-6f0c3e7e3fdb&state=${obj.state?.id}`
                );
              }
            }

            const citie = obj.neighborhood?.city;

            if (citie) {
              obj.citie = citie;
              setUrlNeighborhoods(`neighborhoods?city_id=${obj.citie.id}`);
            }
          }

          reset(obj);

          if (response?.logo_image_url !== '') {
            setImageUrl(response?.logo_image_url);
          }
        });
      } else {
        setUrlCities('');
        setUrlNeighborhoods('');
        setImageUrl(undefined);
        reset({});
      }
    }
  }, [
    utilitySelector.dialogOpen,
    utilitySelector.dialogId,
    fetchData,
    reset,
    stateX,
  ]);

  const cpfCnpjTemplate = (value: string) => {
    return <div>{maskCPFCNPJ(value)}</div>;
  };

  const citieTemplate = (value: INeighborhood) => {
    return <div>{value.city?.name}</div>;
  };

  const stateTemplate = (value: INeighborhood) => {
    return <div>{value.city?.state}</div>;
  };

  const situationTemplate = (value: string) => {
    return (
      <Label variant="ghost" color={value === 'opened' ? 'success' : 'error'}>
        {value === 'opened' ? 'Aberto' : 'Fechado'}
      </Label>
    );
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const mapColumns = (affiliate: IAffiliate): IAffiliate => {
    const result: IAffiliate = {
      neighborhood_id: affiliate.neighborhood_id,
      cpf_cnpj: affiliate.cpf_cnpj.replace(/\D+/g, ''),
      corporate_name: affiliate.corporate_name,
      fantasy_name: affiliate.fantasy_name,
      about: affiliate.about !== '' ? affiliate.about : null,
      phone:
        affiliate.phone !== '' ? affiliate.phone?.replace(/\D+/g, '') : null,
      cellphone: affiliate.cellphone.replace(/\D+/g, ''),
      email: affiliate.email,
      zip_code: affiliate.zip_code.replace(/\D+/g, ''),
      address: affiliate.address,
      address_number:
        affiliate.address_number !== '' ? affiliate.address_number : null,
      delivery_time_initial: affiliate.delivery_time_initial,
      delivery_time_final: affiliate.delivery_time_final,
      delivery_rate_value: affiliate.delivery_rate_value,
      withdrawal_time_initial: affiliate.withdrawal_time_initial,
      withdrawal_time_final: affiliate.withdrawal_time_final,
      minimum_order_value: affiliate.minimum_order_value,
      allow_delivery: affiliate.allow_delivery,
      allow_withdrawal: affiliate.allow_withdrawal,
      // local_menu_module_enabled: affiliate.local_menu_module_enabled,
      notify_new_order_by_whatsapp: affiliate.notify_new_order_by_whatsapp,
      latitude: affiliate.latitude,
      longitude: affiliate.longitude,
      facebook_pixel_id: affiliate.facebook_pixel_id,
      pix_key: affiliate.pix_key !== '' ? affiliate.pix_key : null,
      cart_observation:
        affiliate.cart_observation !== '' ? affiliate.cart_observation : null,
      local_menu_observation:
        affiliate.local_menu_observation !== ''
          ? affiliate.local_menu_observation
          : null,
      cashback_percentage: affiliate.cashback_percentage ?? 0,
      cashback_rescue_minimum_value:
        affiliate.cashback_rescue_minimum_value ?? 0,
    };

    if (utilitySelector.dialogId === '') {
      result.alias = affiliate.alias;
    }

    return result;
  };

  const onSubmit = async (formData: IAffiliate) => {
    if (getValues('latitude') === null || getValues('longitude') === null) {
      snack.warning('Por favor informe a localização da empresa');
      return;
    }

    const obj = mapColumns(formData);
    const { file } = utilitySelector;
    const urlImageData = getValues('logo_image_url');

    const upload =
      (utilitySelector.dialogId === '' && !file) ||
      (imageUrl && !file) ||
      (!imageUrl && !file && !urlImageData);

    const imageUpload = async (id: string) => {
      if (upload) {
        return;
      }

      const objFile = new FormData();

      if (file) objFile.append('image', file);

      await patch(`affiliate/logo_image/${id}`, objFile).then(response => {
        if (response) {
          if (userSelector.affiliate?.id === response.data.id) {
            dispatch(setAffiliate(response.data));
          }
        }
      });
    };

    const afterExecute = (response: IAffiliate) => {
      if (!upload) {
        return;
      }

      if (response) {
        if (userSelector.affiliate?.id === response.id) {
          dispatch(setAffiliate(response));
        }
      }
    };

    if (utilitySelector.dialogId) {
      if (formData.id)
        await update(formData.id, obj, imageUpload, afterExecute);
    } else {
      await create(obj, imageUpload, afterExecute);
    }

    dispatch(closeDialog());
  };

  const afterDelete = () => {
    setImageUrl(undefined);
  };

  const openPaymentsMethods = (dataIndex: number) => {
    const { id } = data[dataIndex];
    if (id) {
      dispatch(setDialogId(id));
      setOpenPayments(true);
    }
  };

  const closePaymentMethods = () => {
    setOpenPayments(false);
  };

  const openCitiesMethods = (dataIndex: number) => {
    const { id } = data[dataIndex];
    if (id) {
      dispatch(setDialogId(id));
      setAffiliateX(data[dataIndex]);
      setOpenCities(true);
    }
  };

  const openPhonesMethods = (dataIndex: number) => {
    const { id } = data[dataIndex];
    if (id) {
      dispatch(setDialogId(id));
      setAffiliateX(data[dataIndex]);
      setOpenPhones(true);
    }
  };

  const closeCitiesMethods = () => {
    setAffiliateX(undefined);
    setOpenCities(false);
  };

  const closePhonesMethods = () => {
    setAffiliateX(undefined);
    setOpenPhones(false);
  };

  const methods = (dataIndex: number) => {
    return (
      <>
        <Tooltip title="Formas de Pagamento" placement="top-start">
          <IconButton
            size="small"
            aria-label="payment method"
            onClick={() => openPaymentsMethods(dataIndex)}
          >
            <Payments fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cidades" placement="top-start">
          <IconButton
            size="small"
            aria-label="city method"
            onClick={() => openCitiesMethods(dataIndex)}
          >
            <LocationCity fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Telefones" placement="top-start">
          <IconButton
            size="small"
            aria-label="phone method"
            onClick={() => openPhonesMethods(dataIndex)}
          >
            <Smartphone fontSize="small" />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const activeTemplate = (value: boolean) => {
    return (
      <Label variant="ghost" color={(!value && 'error') || 'success'}>
        {value ? 'Sim' : 'Não'}
      </Label>
    );
  };

  const actions = ActionsDataTable(true, data, undefined, methods);

  const columns = [
    {
      name: 'corporate_name',
      label: 'Razão Social',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'fantasy_name',
      label: 'Nome Fantasia',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'cpf_cnpj',
      label: 'CPF/CNPJ',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => cpfCnpjTemplate(value),
      },
    },
    {
      name: 'neighborhood',
      label: 'Cidade',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: INeighborhood) => citieTemplate(value),
      },
    },
    {
      name: 'neighborhood',
      label: 'UF',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: INeighborhood) => stateTemplate(value),
      },
    },
    {
      name: 'allow_delivery',
      label: 'Permite Entrega',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: boolean) => activeTemplate(value),
      },
    },
    {
      name: 'allow_withdrawal',
      label: 'Permite Retirada',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: boolean) => activeTemplate(value),
      },
    },
    {
      name: 'situation',
      label: 'Situação',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => situationTemplate(value),
      },
    },
  ];

  if (actions) {
    columns.push(actions);
  }

  const beforeChangeCities = (obj: ICities) => {
    reset({ ...getValues(), neighborhood: null });

    if (obj) {
      setUrlNeighborhoods(`neighborhoods?city_id=${obj.id}`);
    } else {
      setUrlNeighborhoods('');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const beforeChangeState = (obj: any) => {
    reset({ ...getValues(), citie: null, neighborhood: null });

    if (obj) {
      setUrlCities(
        `cities?country_id=e9d5b555-b14d-4706-a9b1-6f0c3e7e3fdb&state=${obj.id}`
      );
    } else {
      setUrlCities('');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionZipCode = async () => {
    const zipCode = getValues('zip_code').replace(/\D+/g, '');

    if (zipCode) {
      const { data: response } = await get(`location/cep/${zipCode}`);
      const zipCodeData = response as IZipCode;

      reset({
        ...getValues(),
        state: stateX.find(x => x.id === zipCodeData.city.state),
        citie: zipCodeData.city,
        neighborhood: zipCodeData.neighborhood ?? null,
        neighborhood_id: zipCodeData.neighborhood
          ? zipCodeData.neighborhood.id
          : undefined,
        address: zipCodeData.address ?? null,
      });
    }
  };

  const setValueLocation = (latitude: number, longitude: number) => {
    setValue('latitude', latitude);
    setValue('longitude', longitude);
  };

  return (
    <Page>
      <DataTable
        title={<Title description="Filial" isLoading={isLoading} />}
        data={data}
        columns={columns}
        isLoading={isLoading}
        hideActionAddToolbar={
          !userSelector.enable_multiple_affiliate && data.length === 1
        }
        refresh={getData}
      />

      <DialogForm
        titleDiag="Filial"
        maxForm="lg"
        open={utilitySelector.dialogOpen}
        isLoading={isFetch}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form
          latitude={getValues('latitude')}
          longitude={getValues('longitude')}
          setValueLocation={setValueLocation}
          control={control}
          formState={formState}
          isSubmitting={isSubmitting}
          states={stateX}
          urlCities={urlCities}
          urlNeighborhoods={urlNeighborhoods}
          beforeChangeCities={beforeChangeCities}
          beforeChangeState={beforeChangeState}
          action={actionZipCode}
          isLoadingAction={isLoading}
          localMenu={getValues('local_menu_module_enabled') || false}
          cashback={getValues('cashback_module_enabled') || false}
        >
          <ImagePreview
            image={imageUrl}
            isSubmitting={isSubmitting}
            afterDelete={afterDelete}
            sx={{
              height: 175,
              // width: '100%',
              maxHeight: { xs: 233, md: 175 },
              // maxWidth: { xs: 350, md: 250 },
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              cursor: 'pointer',
            }}
          />
        </Form>
      </DialogForm>

      <PaymentMethods open={openPayments} close={closePaymentMethods} />
      <Cities
        affiliate={affiliateX || ({} as IAffiliate)}
        open={openCities}
        close={closeCitiesMethods}
      />
      <Phones
        affiliate={affiliateX || ({} as IAffiliate)}
        open={openPhones}
        close={closePhonesMethods}
      />
    </Page>
  );
}
