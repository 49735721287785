/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Clear, DoneAll, Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import useUtility from '../../hooks/useUtility';

export default function ActionsDataTable(
  activeInactive: boolean,
  data: any[],
  onDelete?: (data: any) => Promise<void> | void,
  action?: (dataIndex: number) => React.ReactNode,
  hideEdit?: boolean,
  onEdit?: (data: any) => void,
  hideDelete?: boolean,
  disableEdit?: (data: any) => boolean
) {
  const { openDialog } = useUtility();
  const dispatch = useDispatch();

  const edit = (obj: any) => {
    if (onEdit) {
      onEdit(obj);
    } else {
      dispatch(openDialog(obj.id));
    }
  };

  if (
    ((hideEdit && activeInactive) || (hideDelete && !activeInactive)) &&
    !action
  ) {
    return undefined;
  }

  const columns = {
    name: 'actions',
    label: 'Ações',
    options: {
      filter: false,
      sort: false,
      empty: true,
      setCellProps: () =>
        window.innerWidth < 900
          ? { style: {} }
          : { style: { whiteSpace: 'nowrap', width: '10%' } },
      customHeadLabelRender: () => {
        return <div style={{ textAlign: 'center' }}>Ações</div>;
      },
      customBodyRenderLite: (dataIndex: number) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {!hideEdit && (
              <Tooltip title="Editar" placement="top-end">
                <span>
                  <IconButton
                    size="small"
                    aria-label="edit data"
                    style={{ order: -1 }}
                    {...(disableEdit && {
                      disabled: disableEdit(data[dataIndex]),
                    })}
                    onClick={() => edit(data[dataIndex])}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {((!hideEdit && activeInactive) ||
              (!hideDelete && !activeInactive)) &&
              onDelete && (
                <Tooltip
                  title={activeInactive ? 'Ativar/Inativar' : 'Excluir'}
                  placement="top-end"
                >
                  <IconButton
                    size="small"
                    aria-label={
                      activeInactive ? 'active/inactive data' : 'delete data'
                    }
                    style={{ order: 0 }}
                    onClick={() => onDelete(data[dataIndex])}
                  >
                    {activeInactive ? (
                      <DoneAll fontSize="small" />
                    ) : (
                      <Clear fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            {action ? action(dataIndex) : undefined}
          </div>
        );
      },
    },
  };

  return columns;
}
