import {
  Routes as Routing,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';
import React from 'react';
import Login from './pages/Login';
import Category from './pages/Category';
import Catalog from './pages/Catalog';
import Cashback from './pages/Cashback';
import Complement from './pages/Complement';
import Dashboard from './pages/Dashboard';
import Affiliate from './pages/Affiliate';
import Customer from './pages/Customer';
import Product from './pages/Product';
// import Users from './pages/User';
import Account from './pages/Account';
import Order from './pages/Order';
import DeliveryAreas from './pages/DeliveryAreas';
import PageNotFound from './pages/PageNotFound';
import { ProtectedRoute } from './components/ProtectedRoute';
import News from './pages/News';
import Invoice from './pages/Invoice';
import TopSellingProducts from './pages/TopSellingProducts';
import OrdersPerDay from './pages/OrdersPerDay';
import Configure from './pages/Configure';

function RoutesX(): React.ReactElement {
  return (
    <Router>
      <Routing>
        <Route path="/" element={<Login />} />
        <Route path="/inicio" element={<Dashboard />} />
        <Route
          path="/faturas"
          element={
            <ProtectedRoute slug="invoice.view" isAdmin>
              <Invoice />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pedidos"
          element={
            <ProtectedRoute slug="order.view">
              <Order />
            </ProtectedRoute>
          }
        />
        <Route
          path="/categorias"
          element={
            <ProtectedRoute slug="category.view">
              <Category />
            </ProtectedRoute>
          }
        />
        <Route
          path="/catalogos"
          element={
            <ProtectedRoute slug="product.view">
              <Catalog />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cashbacks"
          element={
            <ProtectedRoute slug="cashback.view">
              <Cashback />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clientes"
          element={
            <ProtectedRoute slug="customer.view">
              <Customer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/complementos"
          element={
            <ProtectedRoute slug="complement.view">
              <Complement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/filiais"
          element={
            <ProtectedRoute slug="affiliate.view">
              <Affiliate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produtos"
          element={
            <ProtectedRoute slug="product.view">
              <Product />
            </ProtectedRoute>
          }
        />
        <Route path="/novidades" element={<News />} />
        {/* <Route
          path="/usuarios"
          element={
            <ProtectedRoute slug="user.view">
              <Users />
            </ProtectedRoute>
          }
        /> */}
        <Route path="/perfil" element={<Account />} />
        <Route
          path="/areasentrega"
          element={
            <ProtectedRoute slug="affiliateDeliveryArea.view">
              <DeliveryAreas />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produtosmaisvendidos"
          element={
            <ProtectedRoute slug="dashboard.topSellingProducts">
              <TopSellingProducts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pedidospordia"
          element={
            <ProtectedRoute slug="dashboard.ordersPerDay">
              <OrdersPerDay />
            </ProtectedRoute>
          }
        />
        <Route
          path="/configuracoes"
          element={
            <ProtectedRoute slug="order.view">
              <Configure />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routing>
    </Router>
  );
}

export default RoutesX;
