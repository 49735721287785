import React from 'react';
import { Typography } from '@mui/material';
import PaperInfo from '../PaperInfo';
import useLogin from '../../hooks/useLogin';

export default function InfoCompany() {
  const { userSelector } = useLogin();

  return (
    <PaperInfo>
      <Typography component="span" variant="h5" sx={{ color: 'black' }}>
        {userSelector.affiliate?.fantasy_name}
      </Typography>
    </PaperInfo>
  );
}
