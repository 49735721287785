import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import CssBaseline from '@mui/material/CssBaseline';
import ContainerUI from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import TextField from '../../components/TextField';
import PasswordField from '../../components/PasswordField';
import Button from '../../components/Button';
import snack from '../../components/SnackbarUtilsConfigurator';
import useLogin from '../../hooks/useLogin';
import { Container } from './styles';
import api from '../../services/api';
import { IAffiliate } from '../../models';
import useUtility from '../../hooks/useUtility';
import AffiliateSelect from '../../components/AffiliateSelect';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'© '}
      {new Date().getFullYear()} - Desenvolvido por{' '}
      <Link color="inherit" href="https://bazzi.solutions/">
        Bazzi Solutions
      </Link>
      .
    </Typography>
  );
}

function Login() {
  const navigate = useNavigate();
  const { onLogin, clearState, userSelector, setAffiliate } = useLogin();
  const [data, setData] = useState<IAffiliate[]>([]);
  const { openAffiliateSel } = useUtility();
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm({});

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/inicio');
    }

    return () => {
      dispatch(clearState());
    };
  }, [clearState, dispatch, navigate]);

  const onGetBranches = useCallback(async () => {
    try {
      await api.get<IAffiliate[]>(`affiliates`).then(response => {
        if (response.data.length > 1) {
          setData(response.data);
          dispatch(openAffiliateSel());
        } else {
          dispatch(setAffiliate(response.data[0]));
          // localStorage.setItem('affiliate', JSON.stringify(response.data[0]));

          if (userSelector.user.show_changelog) {
            navigate('/novidades');
          } else {
            navigate('/inicio');
          }
        }
      });
    } catch (error) {
      if (error instanceof Error) {
        snack.error(error.message);
      }
    }
  }, [
    dispatch,
    navigate,
    openAffiliateSel,
    setAffiliate,
    userSelector.user.show_changelog,
  ]);

  useEffect(() => {
    if (userSelector.isError) {
      snack.warning(userSelector.errorMessage.message);
    }

    const licExp = localStorage.getItem('licExp');

    if (licExp && !userSelector.isError) {
      snack.confirmationError(
        `Sua licença expirou em ${moment(licExp).format(
          'DD/MM/YYYY'
        )}. Por favor entre em contato com o suporte`
      );
    }

    if (userSelector.isSuccess) {
      (async () => onGetBranches())();
    }
  }, [
    userSelector.isError,
    userSelector.isSuccess,
    userSelector.errorMessage.message,
    onGetBranches,
  ]);

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const onSubmit = async (formData: any) => {
    dispatch(clearState());
    await onLogin(formData.username, formData.password);
  };

  return (
    <ContainerUI component="main" maxWidth="xs">
      <CssBaseline />
      <Container>
        <div className="paper">
          <img src="app-logo.webp" alt="Mercurius" width="300" />

          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              id="user"
              name="username"
              control={control}
              defaultValue=""
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Usuário"
              autoFocus
              disabled={userSelector.isLogin}
            />

            <PasswordField
              id="password"
              name="password"
              control={control}
              defaultValue=""
              variant="outlined"
              required
              fullWidth
              label="Senha"
              type="password"
              disabled={userSelector.isLogin}
            />

            <Button
              fullWidth
              caption="Entrar"
              className="button"
              type="submit"
              loading={userSelector.isLogin}
            />
          </form>
        </div>
        <Box mt={4}>
          <Copyright />
        </Box>
        <AffiliateSelect data={data} isLogin />
      </Container>
    </ContainerUI>
  );
}

export default Login;
