import React from 'react';
import { Grid, InputAdornment, Typography } from '@mui/material';
import NumberField from '../../components/NumberField';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  isSubmitting?: boolean;
}

export default function Form({ control, isSubmitting }: Props) {
  const adornmentDistance: JSX.Element = (
    <InputAdornment position="end">
      <Typography component="span" variant="caption">
        m
      </Typography>
    </InputAdornment>
  );

  const adornmentValue: JSX.Element = (
    <InputAdornment position="start">
      <Typography component="span" variant="caption">
        R$
      </Typography>
    </InputAdornment>
  );

  const adornmentTime: JSX.Element = (
    <InputAdornment position="end">
      <Typography component="span" variant="caption">
        min.
      </Typography>
    </InputAdornment>
  );

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <NumberField
          id="distance"
          name="distance"
          control={control}
          defaultValue={0}
          variant="standard"
          fullWidth
          autoFocus
          label="Distância (metros)"
          required
          InputLabelProps={{ shrink: true }}
          disabled={isSubmitting}
          endAdornment={adornmentDistance}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <NumberField
          id="delivery_fee"
          name="delivery_fee"
          control={control}
          defaultValue={0}
          variant="standard"
          fullWidth
          label="Taxa de Entrega"
          required
          decimalScale={2}
          InputLabelProps={{ shrink: true }}
          disabled={isSubmitting}
          startAdornment={adornmentValue}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <NumberField
          id="delivery_time"
          name="delivery_time"
          control={control}
          defaultValue={0}
          variant="standard"
          fullWidth
          label="Tempo Adicional (minutos)"
          required
          InputLabelProps={{ shrink: true }}
          disabled={isSubmitting}
          endAdornment={adornmentTime}
        />
      </Grid>
    </Grid>
  );
}

Form.defaultProps = {
  isSubmitting: false,
};
