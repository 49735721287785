import React from 'react';

import { Box, CircularProgress, Drawer, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import styles from './styles';
import useUtility from '../../hooks/useUtility';

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
}

function OrderDetail({ isLoading, children }: Props) {
  const classes = styles();
  const { utilitySelector, closeDetail } = useUtility();
  const dispatch = useDispatch();

  const handleDrawerClose = () => {
    dispatch(closeDetail());
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={handleDrawerClose}
      open={utilitySelector.detailOpen}
      variant="temporary"
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          p: 3,
        }}
      >
        {isLoading ? (
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 30,
            }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          children
        )}
      </Box>
    </Drawer>
  );
}

export default OrderDetail;
