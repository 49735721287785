import React from 'react';
import { CircularProgress, Typography } from '@mui/material';

interface Props {
  description: string;
  isLoading?: boolean;
}

export default function Title({ description, isLoading }: Props) {
  return (
    <Typography component="span" variant="h6" sx={{ color: 'black' }}>
      {description}
      {isLoading && (
        <CircularProgress color="primary" size={18} sx={{ ml: 3 }} />
      )}
    </Typography>
  );
}

Title.defaultProps = {
  isLoading: false,
};
