import { useQuery, QueryOptions } from 'react-query';
import { IComplement } from '../../models';
import { orderCategory } from '../../statics';
import api from '../api';
import { queryClient } from '../queryClient';

function formatComplement(complement: IComplement): IComplement {
  return {
    ...complement,
    order_description: orderCategory.find(c => c.id === complement.order)
      ?.description,
  };
}

export async function getComplement(id: string): Promise<IComplement> {
  const { data } = await api.get<IComplement>(`complements/${id}`);

  return formatComplement(data);
}

export async function getComplements(): Promise<IComplement[]> {
  const { data } = await api.get<IComplement[]>('complements');

  const formattedData: IComplement[] = [];

  data.forEach(complement => {
    formattedData.push(formatComplement(complement));
  });

  return formattedData;
}

export function useComplements(options?: QueryOptions<IComplement[]>) {
  return useQuery(['complements'], () => getComplements(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...options,
  });
}

export async function fetchComplements() {
  return queryClient.fetchQuery(['complements'], () => getComplements());
}
