import { BarDatum } from '@nivo/bar';
import moment from 'moment';
import {
  IDaysOfWeek,
  IRecurringCustomer,
  IResume,
  IPaymentDashboard,
  IBillingDayOfWeek,
  IDataBillingDayOfWeek,
  IDashboardDaysOfWeek,
  IDataPaymentDashboard,
  IFiltersDashboard,
  IAdminAffiliates,
  IAdminResume,
  IInvoices,
  IReportTopSellingProducts,
  IReportOrdersPerDay,
} from '../../models';
import { formatDate } from '../../utils';
import api from '../api';

const getParams = (filter: IFiltersDashboard) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: Record<string, any> = {};

  params.start_date = formatDate(filter.dateInitial.toString());
  params.end_date = formatDate(filter.dateFinal.toString());

  if (filter.affiliate && filter.affiliate.id) {
    params.affiliate_id = filter.affiliate.id;
  }

  return params;
};

export async function getResume(filter: IFiltersDashboard): Promise<IResume> {
  const params = getParams(filter);

  const { data } = await api.get<IResume>('dashboards/resume', {
    params,
  });

  return data;
}

const billingDayOfWeek = (daysOfWeek: IDaysOfWeek) => {
  const dataX: IBillingDayOfWeek[] = [];

  const itemsCurrent = daysOfWeek.current_period.items.map(d => {
    return {
      x: d.day_of_week_description,
      y: d.value,
    } as IDataBillingDayOfWeek;
  });

  const itemsPrevious = daysOfWeek.previous_period.items.map(d => {
    return {
      x: d.day_of_week_description,
      y: d.value,
    } as IDataBillingDayOfWeek;
  });

  dataX.push({ id: 'Período anterior', data: itemsPrevious });
  dataX.push({ id: 'Período atual', data: itemsCurrent });

  return dataX;
};

const billingDayOfWeek2 = (daysOfWeek: IDaysOfWeek) => {
  const dataX = daysOfWeek.current_period.items.map(d => {
    return {
      day: d.day_of_week_description,
      'Período atual': d.value,
      'Período anterior': daysOfWeek.previous_period.items.find(
        x => x.day_of_week === d.day_of_week
      )?.value,
    } as BarDatum;
  });

  return dataX;
};

const orderDayOfWeek = (daysOfWeek: IDaysOfWeek) => {
  const dataX = daysOfWeek.current_period.items.map(d => {
    return {
      day: d.day_of_week_description,
      'Período atual': d.count,
      'Período anterior': daysOfWeek.previous_period.items.find(
        x => x.day_of_week === d.day_of_week
      )?.count,
    } as BarDatum;
  });

  return dataX;
};

export async function getDaysOfWeek(
  affiliateId?: string
): Promise<IDashboardDaysOfWeek> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: Record<string, any> = {};
  const dateFinal = new Date();
  const dateInitial = moment(dateFinal).add(-6, 'days');

  params.start_date = formatDate(dateInitial.toString());
  params.end_date = formatDate(dateFinal.toString());

  if (affiliateId) {
    params.affiliate_id = affiliateId;
  }

  const { data } = await api.get<IDaysOfWeek>('dashboards/days-of-week', {
    params,
  });

  const result: IDashboardDaysOfWeek = {} as IDashboardDaysOfWeek;
  result.billing = billingDayOfWeek2(data);
  result.order = orderDayOfWeek(data);

  return result;
}

export async function getRecurringCustomer(
  filter: IFiltersDashboard
): Promise<IRecurringCustomer> {
  const params = getParams(filter);

  const { data } = await api.get<IRecurringCustomer>(
    'dashboards/recurring-customer',
    {
      params,
    }
  );

  return data;
}

const methodsPayment = (paymentMethods: IPaymentDashboard[]) => {
  const dataX = paymentMethods.map(p => {
    return {
      id: p.type_description,
      label: p.type_description,
      value: p.count,
    } as IDataPaymentDashboard;
  });

  return dataX;
};

export async function getPaymentMethods(
  filter: IFiltersDashboard
): Promise<IDataPaymentDashboard[]> {
  const params = getParams(filter);

  const { data } = await api.get<IPaymentDashboard[]>(
    'dashboards/payment-methods',
    {
      params,
    }
  );

  const result = methodsPayment(data);

  return result;
}

export async function getAdminAffiliates(
  filter: IFiltersDashboard
): Promise<IAdminAffiliates[]> {
  const params = getParams(filter);

  const { data } = await api.get<IAdminAffiliates[]>(
    'dashboards/admin/affiliates',
    {
      params,
    }
  );

  return data;
}

export async function getAdminResume(
  filter: IFiltersDashboard
): Promise<IAdminResume> {
  const params = getParams(filter);

  const { data } = await api.get<IAdminResume>('dashboards/admin/resume', {
    params,
  });

  return data;
}

export async function getInvoice(): Promise<IInvoices> {
  const { data } = await api.get<IInvoices>('invoice');

  return data;
}

export async function getTopSellingProducts(
  filter: IFiltersDashboard
): Promise<IReportTopSellingProducts[]> {
  const params = getParams(filter);

  const { data } = await api.get<IReportTopSellingProducts[]>(
    'dashboards/top-selling-products',
    {
      params,
    }
  );

  return data;
}

export async function getOrdersPerDay(
  filter: IFiltersDashboard
): Promise<IReportOrdersPerDay[]> {
  const params = getParams(filter);

  const { data } = await api.get<IReportOrdersPerDay[]>(
    'dashboards/orders-per-day',
    {
      params,
    }
  );

  return data;
}
