import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import AutocompleteAsync from '../../components/AutocompleteAsync';
import TextField from '../../components/TextField';
import { ICategory } from '../../models';
import useUtility from '../../hooks/useUtility';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  isSubmitting?: boolean;
  children: React.ReactNode;
}

export default function Form({ control, isSubmitting, children }: Props) {
  const { utilitySelector } = useUtility();

  return (
    <Grid container spacing={2}>
      <Grid item md={9} xs={12}>
        <TextField
          id="name"
          name="name"
          control={control}
          defaultValue=""
          variant="standard"
          fullWidth
          label="Nome do Produto"
          required
          autoFocus
          InputLabelProps={{ shrink: true }}
          disabled={isSubmitting}
        />

        <TextField
          id="description"
          name="description"
          control={control}
          defaultValue=""
          variant="standard"
          fullWidth
          label="Descrição do Produto"
          InputLabelProps={{ shrink: true }}
          disabled={isSubmitting}
        />

        <Grid container spacing={2}>
          <Grid item md={9} xs={12}>
            <AutocompleteAsync
              name="category"
              control={control}
              title="Categoria"
              required
              url="categories"
              getLabel={(option: ICategory) => option.description}
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <FormControl variant="standard" sx={{ mt: 2 }} fullWidth>
              <InputLabel htmlFor="type-required" shrink>
                Tipo do Produto
              </InputLabel>
              <Controller
                name="type"
                control={control}
                defaultValue="simple"
                render={({ field }) => (
                  <Select
                    {...field}
                    sx={{ textAlign: 'left' }}
                    id="type"
                    variant="standard"
                    color="primary"
                    label="Tipo do Produto"
                    required
                    {...(utilitySelector.dialogId !== '' && { disabled: true })}
                    value={field.value || 'simple'}
                  >
                    <MenuItem value="additional">
                      <Typography>Adicional</Typography>
                    </MenuItem>
                    <MenuItem value="compound">
                      <Typography>Composto</Typography>
                    </MenuItem>
                    <MenuItem value="simple">
                      <Typography>Simples</Typography>
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <FormControlLabel
            sx={{ width: '100%' }}
            control={
              <Controller
                name="frosty"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    id="frosty"
                    color="primary"
                    onChange={e => field.onChange(e.target.checked)}
                    checked={field.value || false}
                    disabled={isSubmitting}
                  />
                )}
              />
            }
            label="Gelado(a)"
          />
        </Grid>

        {/* <FormControlLabel
          sx={{ width: '100%' }}
          control={
            <Controller
              name="active"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="active"
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                  disabled={isSubmitting}
                />
              )}
            />
          }
          label="Ativo"
        /> */}
      </Grid>
      <Grid item md={3} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}

Form.defaultProps = {
  isSubmitting: false,
};
