import api from './api';
import { IAffiliate, ILogin, IUserAccess } from '../models/index';

export const getAuthentication = async (username: string, password: string) => {
  return api.post<ILogin>(
    '/user/authenticate',
    { username, password },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getUserInfo = async () => {
  return api.get<IUserAccess>('user');
};

export const getAffiliateInfo = async (id: string) => {
  return api.get<IAffiliate>(`affiliates/${id}`);
};
