/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import React from 'react';
import { IMaskInput } from 'react-imask';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Controller, UseControllerProps } from 'react-hook-form';
import { IconButton, InputAdornment, CircularProgress } from '@mui/material';
import { Start } from '@mui/icons-material';
import { TextFieldStyled } from './styles';

interface Props {
  mask?: any;
  action?: () => Promise<void>;
  isLoadingAction?: boolean;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export default function TextField({
  mask,
  action,
  isLoadingAction,
  name,
  control,
  rules,
  defaultValue,
  ...textFieldProps
}: Props & TextFieldProps & UseControllerProps<any>): React.ReactElement {
  const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function TextMaskCustom(props, ref) {
      const { onChange, ...other } = props;
      return (
        <IMaskInput
          {...other}
          mask={mask}
          definitions={{
            '#': /[1-9]/,
          }}
          onAccept={(value: any) =>
            onChange({ target: { name: props.name, value } })
          }
        />
      );
    }
  );

  const propsInput = {
    name: 'propsInput',
    ...(mask ? { inputComponent: TextMaskCustom as any } : {}),
    ...(action
      ? {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="action" onClick={() => action()}>
              {isLoadingAction ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <Start fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }
      : {}),
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TextFieldStyled
          {...field}
          {...textFieldProps}
          margin="normal"
          onChange={field.onChange}
          value={field.value || ''}
          error={!!error}
          helperText={error ? error.message : null}
          ref={field.ref}
          InputProps={propsInput}
          autoComplete="off"
        />
      )}
    />
  );
}

TextField.defaultProps = {
  mask: undefined,
  action: undefined,
  isLoadingAction: false,
};
