import { useQuery, QueryOptions } from 'react-query';
import { IAffiliateProductComplements } from '../../models';
import api from '../api';
import { queryClient } from '../queryClient';

function formatAffiliateComplement(
  affiliateComplement: IAffiliateProductComplements
): IAffiliateProductComplements {
  return {
    ...affiliateComplement,
    product_name: affiliateComplement.complement_item?.product?.name,
    category_description:
      affiliateComplement.complement_item?.product?.category?.description,
    complement: affiliateComplement.complement_item?.complement?.description,
  };
}

export async function getAffiliateComplement(
  id: string
): Promise<IAffiliateProductComplements> {
  const { data } = await api.get<IAffiliateProductComplements>(
    `affiliate/product/complements?affiliate_product_id=${id}`
  );

  return formatAffiliateComplement(data);
}

export async function getAffiliateComplements(
  id: string
): Promise<IAffiliateProductComplements[]> {
  const { data } = await api.get<IAffiliateProductComplements[]>(
    `affiliate/product/complements?affiliate_product_id=${id}`
  );

  const formattedData: IAffiliateProductComplements[] = [];

  data.forEach(complement => {
    formattedData.push(formatAffiliateComplement(complement));
  });

  return formattedData;
}

export function useAffiliateComplements(
  id: string,
  options?: QueryOptions<IAffiliateProductComplements[]>
) {
  return useQuery(
    ['affiliate_complements', id],
    () => getAffiliateComplements(id),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      ...options,
    }
  );
}

export async function fetchAffiliateComplements(id: string) {
  return queryClient.fetchQuery(['affiliate_complements'], () =>
    getAffiliateComplements(id)
  );
}
