export default function MUIDataTable() {
  return {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: '#f9ebeb !important',
        },
        footer: {
          left: 0,
          bottom: 0, // <-- KEY
          zIndex: 2,
          // position: 'sticky',
        },
      },
    },
  };
}
