import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { Box, CircularProgress, Stack } from '@mui/material';
import PlacesAutocomplete from '../PlacesAutocomplete';
import { IAddressLocation } from '../../models';

interface Props {
  latitude?: number;
  longitude?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValueLocation: (latitude: number, longitude: number) => void;
}

const containerStyle = {
  width: '100%',
  height: '350px',
};

export default function Map({ latitude, longitude, setValueLocation }: Props) {
  const mapRef = useRef<google.maps.Map>();
  const [center, setCenter] = useState<google.maps.LatLngLiteral>();
  const [selected, setSelected] = useState<IAddressLocation | null>();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
    libraries: ['places'],
  });

  const onMapLoad = React.useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(
    ({ lat, lng }) => {
      setValueLocation(lat, lng);
      if (mapRef.current) {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(16);
      }
    },
    [setValueLocation]
  );

  const onSelected = React.useCallback(
    (addresLocation: IAddressLocation) => {
      setSelected(addresLocation);
      panTo({ lat: addresLocation.lat, lng: addresLocation.lng });
    },
    [panTo]
  );

  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      setCenter({
        lat: latitude || 0,
        lng: longitude || 0,
      });
      onSelected({
        lat: latitude,
        lng: longitude,
      } as IAddressLocation);
    } else {
      setCenter({
        lat: -16.4654757,
        lng: -54.6387229,
      });
    }
  }, [latitude, longitude, onSelected]);

  return (
    <Box sx={{ postion: 'absolute' }}>
      <Box sx={{ top: 10 }}>
        <PlacesAutocomplete onSelected={onSelected} />
      </Box>

      {isLoaded ? (
        <GoogleMap
          zoom={latitude !== null && longitude !== null ? 16 : 14}
          center={center}
          onLoad={onMapLoad}
          mapContainerStyle={containerStyle}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
        >
          {selected && <Marker position={selected} />}
        </GoogleMap>
      ) : (
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Stack
            sx={{
              maring: 0,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress color="primary" />
          </Stack>
        </Box>
      )}
    </Box>
  );
}

Map.defaultProps = {
  latitude: 0,
  longitude: 0,
};
