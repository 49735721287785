import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
  Checkbox,
  Divider,
  InputAdornment,
  ListItem,
  ListItemText,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useData from '../../services/hooks/useData';
import useUtility from '../../hooks/useUtility';
import DialogForm from '../../components/DialogForm';
import { IAffiliatePaymentMethods, IPayment } from '../../models';
import NumberX from '../../components/NumberX';

interface Props {
  open: boolean;
  close: () => void;
}

export default function PaymentMethods({ open, close }: Props) {
  const { closeDialog, utilitySelector } = useUtility();
  const { data, getData, isLoading, isSubmitting, createList } =
    useData<IAffiliatePaymentMethods>(
      'affiliate/payment_methods',
      `?affiliate_id=${utilitySelector.dialogId}`,
      true
    );
  const dispatch = useDispatch();
  const { handleSubmit } = useForm<IAffiliatePaymentMethods>();
  const [obj, setObj] = useState<IAffiliatePaymentMethods[]>();
  const [objSlice, setObjSlice] = useState<IAffiliatePaymentMethods[]>();

  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const pageDefault = 8;
  const theme = useTheme();
  const smBreak = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (data) {
      setPage(1);
      const sortObj = data.sort((a, b) => {
        const sa = a.payment_method?.description || '';
        const sb = b.payment_method?.description || '';

        if (sa < sb) {
          return -1;
        }
        if (sa > sb) {
          return 1;
        }
        return 0;
      });
      setObj(sortObj);
      setObjSlice(sortObj.slice(0, pageDefault));

      let calc = data.length / pageDefault;

      if (!Number.isInteger(calc)) {
        calc = Math.trunc(calc) + 1;
      }

      setCount(calc);
    } else {
      setObj(undefined);
      setCount(0);
    }
  }, [data]);

  useEffect(() => {
    if (open && utilitySelector.dialogId !== '') {
      getData();
    }
  }, [getData, open, utilitySelector.dialogId]);

  useEffect(() => {
    if (obj) {
      setObjSlice(obj.slice((page - 1) * pageDefault, page * pageDefault));
    }
  }, [obj, page]);

  const handleClose = () => {
    close();
    dispatch(closeDialog());
  };

  const mapColumns = (
    affiliatePaymentMethods: IAffiliatePaymentMethods[]
  ): IPayment => {
    const methods = affiliatePaymentMethods.map(a => ({
      payment_method_id: a.payment_method_id,
      discount_percentage: a.discount_percentage,
      enable_cashback_generate: true,
      active: a.active,
    }));

    const payments: IPayment = {
      affiliate_id: utilitySelector.dialogId,
      payment_methods: methods,
    };

    return payments;
  };

  const onSubmit = async () => {
    if (obj) {
      await createList(mapColumns(obj));
    }

    close();
    dispatch(closeDialog());
  };

  const handleChange = (id: string, active?: boolean, value?: string) => {
    if (obj) {
      const updateTable = [...obj];
      const index = updateTable.findIndex(x => x.id === id);

      const payment = updateTable[index].payment_method;

      if (value && payment) {
        const valueFormatted = value.replace('.', '').replace(/,/g, '.');
        updateTable[index].discount_percentage = parseFloat(valueFormatted);
      }

      if (active !== undefined) {
        updateTable[index].active = active;
      }
      setObj(updateTable);
    }
  };

  const getDescriptionPayment = (
    affiliatePaymentMethod: IAffiliatePaymentMethods
  ) => {
    if (
      affiliatePaymentMethod.payment_method?.type === 'debit card' ||
      affiliatePaymentMethod.payment_method?.type === 'credit card'
    ) {
      return `${affiliatePaymentMethod.payment_method.description} ${affiliatePaymentMethod.payment_method.type_description}`;
    }

    return affiliatePaymentMethod.payment_method?.description;
  };

  const adornmentValue: JSX.Element = (
    <InputAdornment position="start">
      <Typography component="span" variant="caption">
        %
      </Typography>
    </InputAdornment>
  );

  const adornmentInfo: JSX.Element = (
    <InputAdornment position="end">
      <Typography component="span" variant="caption">
        {smBreak ? '% Desc.' : 'Desconto'}
      </Typography>
    </InputAdornment>
  );

  return (
    <DialogForm
      titleDiag="Formas de Pagamento"
      subTitle="Quais você aceita?"
      open={open}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      handleClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <>
        {objSlice &&
          objSlice.map(
            (
              affiliatePaymentMethods: IAffiliatePaymentMethods,
              key: number
            ) => {
              return (
                <ListItem
                  key={key}
                  disabled={isSubmitting}
                  secondaryAction={
                    <Stack direction="row">
                      <NumberX
                        id="discount_percentage"
                        name="discount_percentage"
                        value={affiliatePaymentMethods.discount_percentage}
                        variant="standard"
                        fullWidth
                        onBlur={e =>
                          handleChange(
                            affiliatePaymentMethods.id || '',
                            undefined,
                            e.target.value
                          )
                        }
                        decimalScale={2}
                        InputLabelProps={{ shrink: true }}
                        disabled={isSubmitting}
                        sx={{ width: smBreak ? 100 : 180, pb: 1.8 }}
                        {...(!smBreak && { startAdornment: adornmentValue })}
                        endAdornment={adornmentInfo}
                      />

                      <Checkbox
                        size="small"
                        sx={{ pb: 1.5 }}
                        checked={affiliatePaymentMethods.active}
                        onChange={e =>
                          handleChange(
                            affiliatePaymentMethods.id || '',
                            e.target.checked
                          )
                        }
                      />
                    </Stack>
                  }
                >
                  <ListItemText
                    primary={getDescriptionPayment(affiliatePaymentMethods)}
                  />
                  <Divider />
                </ListItem>
              );
            }
          )}

        {count > 1 && (
          <div style={{ display: 'flex' }}>
            <Pagination
              sx={{ ml: 'auto', mb: 2, mt: 1 }}
              count={count}
              size="small"
              page={page}
              onChange={handleChangePagination}
              variant="outlined"
              color="primary"
              shape="rounded"
              disabled={isSubmitting}
            />
          </div>
        )}
      </>
    </DialogForm>
  );
}
