import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import { VisibilityOutlined } from '@mui/icons-material';
import { useMutation } from 'react-query';
import ActionsDataTable from '../../components/ActionsDataTable';
import useUtility from '../../hooks/useUtility';
import DataTable from '../../components/DataTable';
import DialogForm from '../../components/DialogForm';
import Page from '../../components/Page';
import Label from '../../components/Label';
import { IComplement } from '../../models';
import Form from './Form';
import Item from './Item';
import Title from '../../components/Title';
import { useCan } from '../../hooks/useCan';
import {
  getComplement,
  getComplements,
  useComplements,
} from '../../services/hooks/useComplement';
import { queryClient } from '../../services/queryClient';
import api from '../../services/api';

const schema = yup
  .object()
  .shape({
    description: yup.string().required().nullable(),
    minimum_amount: yup.number().min(0).max(999),
    maximum_amount: yup
      .number()
      .required()
      .min(
        yup.ref('minimum_amount'),
        'O campo deve ser maior ou igual ao "Prazo de Entrega Inicial"'
      )
      .max(999),
    priority: yup.number().required().min(0).max(999),
    order: yup.string().required().nullable(),
    rule: yup.string().required().nullable(),
    active: yup.boolean().required(),
  })
  .required();

yup.setLocale(ptForm);
yup.setLocale({ mixed: { notType: 'Valor inválido.' } });

export default function Complement() {
  const { data, isLoading, isFetching } = useComplements();
  const dispatch = useDispatch();
  const { closeDialog, utilitySelector } = useUtility();
  const [complement, setComplement] = useState<IComplement>();
  const [complements, setComplements] = useState<IComplement[]>();
  const [openItems, setOpenItems] = useState(false);
  const [isLoadingX, setIsLoadingX] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, reset } = useForm<IComplement>({
    resolver: yupResolver(schema),
  });

  const isCreate = useCan({ view: 'complement.create' });
  const isUpdate = useCan({ view: 'complement.update' });

  const refresh = useCallback(async () => {
    setIsLoadingX(true);
    return getComplements()
      .then(result => setComplements(result))
      .finally(() => setIsLoadingX(false));
  }, []);

  useEffect(() => {
    if (utilitySelector.dialogOpen) {
      if (utilitySelector.dialogId) {
        setIsLoadingX(true);
        getComplement(utilitySelector.dialogId)
          .then(response => {
            reset(response);
          })
          .finally(() => setIsLoadingX(false));
      } else {
        reset({});
      }
    }
  }, [utilitySelector.dialogOpen, utilitySelector.dialogId, reset]);

  useEffect(() => {
    setComplements(data);
  }, [data]);

  const activeTemplate = (value: boolean) => {
    return (
      <Label variant="ghost" color={(!value && 'error') || 'success'}>
        {value ? 'Sim' : 'Não'}
      </Label>
    );
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const closeItems = () => {
    queryClient.invalidateQueries('complements');
    setOpenItems(false);
  };

  const mapColumns = (complementX: IComplement): IComplement => {
    const result: IComplement = {
      description: complementX.description,
      minimum_amount: complementX.minimum_amount,
      maximum_amount: complementX.maximum_amount,
      allow_sorting: false,
      priority: complementX.priority,
      order: complementX.order,
      rule: complementX.rule,
      active: complementX.active,
    };

    return result;
  };

  const createOrUpdateComplement = useMutation(
    async (complementX: IComplement) => {
      const obj = mapColumns(complementX);

      if (complementX.id) {
        const responseX = await api.put<IComplement>(
          `complements/${complementX.id}`,
          obj
        );

        return responseX.data;
      }

      const response = await api.post<IComplement>('complements', obj);

      return response.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries('complements');
        dispatch(closeDialog());
      },
    }
  );

  const onSubmit = async (formData: IComplement) => {
    setIsSubmitting(true);
    await createOrUpdateComplement
      .mutateAsync(formData)
      .finally(() => setIsSubmitting(false));
  };

  const activeCategory = async (complementX: IComplement) => {
    complementX.active = !complementX.active;
    if (complementX.id) {
      setIsSubmitting(true);
      await createOrUpdateComplement
        .mutateAsync(complementX)
        .finally(() => setIsSubmitting(false));
    }
  };

  const openComplementItems = (complementItems: IComplement) => {
    setComplement(complementItems);
    setOpenItems(true);
  };

  const itemsTemplate = (dataIndex: number) => {
    return (
      <Tooltip title="Items" placement="top-end">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {complements
            ? complements[dataIndex]?.items_count?.toString().padStart(3, '0')
            : '000'}
          <IconButton
            size="small"
            aria-label="items data"
            onClick={() =>
              complements
                ? openComplementItems(complements[dataIndex])
                : undefined
            }
          >
            <VisibilityOutlined fontSize="small" />
          </IconButton>
        </div>
      </Tooltip>
    );
  };

  const centerHeadLabelTemplate = (caption: string) => {
    return <div style={{ textAlign: 'center', marginRight: 9 }}>{caption}</div>;
  };

  const actions = ActionsDataTable(
    true,
    complements || [],
    activeCategory,
    undefined,
    !isUpdate
  );

  const columns = [
    {
      name: 'description',
      label: 'Descrição',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'minimum_amount',
      label: 'Quantidade Mínima',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'maximum_amount',
      label: 'Quantidade Máxima',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'priority',
      label: 'Prioridade',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'order_description',
      label: 'Ordenar Itens Por',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'rule_description',
      label: 'Regra',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'active',
      label: 'Ativo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: boolean) => activeTemplate(value),
      },
    },
    {
      name: 'id',
      label: 'Items',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number) => itemsTemplate(dataIndex),
        setCellProps: () =>
          window.innerWidth < 900
            ? { style: {} }
            : { style: { whiteSpace: 'nowrap', width: '3%' } },
        customHeadLabelRender: () => centerHeadLabelTemplate('Itens'),
      },
    },
  ];

  if (actions) {
    columns.push(actions);
  }

  return (
    <Page>
      <DataTable
        title={
          <Title
            description="Complementos"
            isLoading={isLoading || isFetching || isLoadingX}
          />
        }
        data={complements || []}
        columns={columns}
        isLoading={isLoading || isFetching}
        refresh={refresh}
        hideActionAddToolbar={!isCreate}
      />

      <DialogForm
        titleDiag="Complemento"
        open={utilitySelector.dialogOpen}
        isLoading={isLoadingX}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form control={control} isSubmitting={isSubmitting} />
      </DialogForm>

      {complement && (
        <Item complement={complement} open={openItems} onClose={closeItems} />
      )}
    </Page>
  );
}
