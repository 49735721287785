import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import Button from '../Button';

interface Props {
  titleDiag: string;
  subTitle?: string;
  isLoading?: boolean;
  handleClose: () => void;
  onOk?: () => void;
  captionOk?: string;
  captionCancel?: string;
  autoFocus?: boolean;
}

export default function DialogX({
  titleDiag,
  subTitle,
  isLoading,
  handleClose,
  onOk,
  captionOk,
  captionCancel,
  autoFocus,
  children,
  ...dialogProps
}: DialogProps & Props) {
  const onClose = (event: unknown, reason: string) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      handleClose();
    }
  };

  return (
    <Dialog
      {...dialogProps}
      onClose={onClose}
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{titleDiag}</DialogTitle>
      <Divider />
      <DialogContent>
        {subTitle && <DialogContentText>{subTitle}</DialogContentText>}
        {children}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mt: 1, mb: 1 }}>
        {onOk && (
          <Button
            sx={{ width: 100 }}
            caption={captionOk || 'Salvar'}
            {...(autoFocus && { autoFocus: true })}
            onClick={onOk}
            disabled={isLoading}
          />
        )}
        <Button
          sx={{
            width: 100,
            marginLeft: 1,
            background: '#ffffff',
            color: 'primary.main',
            borderColor: 'primary.main',
            border: 0.5,
            '&:hover': {
              backgroundColor: 'primary.main',
              color: '#fff',
            },
          }}
          caption={captionCancel || 'Cancelar'}
          onClick={handleClose}
          disabled={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
}

DialogX.defaultProps = {
  subTitle: undefined,
  isLoading: false,
  onOk: undefined,
  captionOk: undefined,
  captionCancel: undefined,
  autoFocus: false,
};
