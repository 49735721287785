import React from 'react';
import useLogin from '../hooks/useLogin';
import { IReportTopSellingProducts, IFiltersDashboard } from '../models';
import Header from './Components/Header';
import { Container } from './styles';

type Props = {
  componentToPrint: React.MutableRefObject<null>;
  data: IReportTopSellingProducts[];
  filters: IFiltersDashboard;
  datePrint: Date;
};

export default function ReportTopSellingProducts(props: Props) {
  const { userSelector } = useLogin();
  const { componentToPrint, data, datePrint } = props;

  const quantity = data.reduce((accu, item) => {
    return accu + item.quantity;
  }, 0);

  const grossValue = data.reduce((accu, item) => {
    return accu + item.gross_value;
  }, 0);

  const discountValue = data.reduce((accu, item) => {
    return accu + item.discount_value;
  }, 0);

  const liquidValue = data.reduce((accu, item) => {
    return accu + item.liquid_value;
  }, 0);

  const formatValue = (value: string) => {
    return parseFloat(value).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const { filters } = props;

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-return-assign
    <Container ref={el => (componentToPrint.current = el)}>
      <div className="card-print">
        <Header
          title="Produtos Mais Vendidos"
          filters={filters}
          fantasyName={userSelector.affiliate?.fantasy_name}
          datePrint={datePrint}
        />

        <table className="table">
          <thead>
            <tr>
              <th
                scope="col"
                className="column-initial"
                style={{ textAlign: 'left', width: '40%' }}
              >
                Produto
              </th>
              <th scope="col" className="column-left" style={{ width: '18%' }}>
                Quantidade
              </th>
              <th scope="col" className="column-left" style={{ width: '14%' }}>
                Bruto
              </th>
              <th scope="col" className="column-left" style={{ width: '14%' }}>
                Desconto
              </th>
              <th scope="col" className="column-left" style={{ width: '14%' }}>
                Líquido
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="line">
                <td
                  className="column-initial"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '50%',
                  }}
                >
                  {item.name}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.quantity.toString())}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.gross_value.toString())}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.discount_value.toString())}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.liquid_value.toString())}
                </td>
              </tr>
            ))}

            <tr style={{ fontWeight: 'bold' }} className="line">
              <td className="column-initial">Total</td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(quantity.toString())}
              </td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(grossValue.toString())}
              </td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(discountValue.toString())}
              </td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(liquidValue.toString())}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
  );
}
