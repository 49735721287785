import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';

export default function PageNotFound() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Stack sx={{ mt: 5, mb: 8 }}>
        <img src="app-logo.webp" alt="Mercurius" width="200" />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: 3,
          maxWidth: 444,
          textAlign: 'center',
        }}
      >
        <Typography variant="h1" color="primary.main">
          404
        </Typography>
        <Typography variant="h6">PÁGINA NÃO ENCONTRADA</Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Parece que você pegou o caminho errado. Não se preocupe, acontece com
          os melhores.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Aqui está uma pequena dica que pode ajudá-lo(a) a voltar aos trilhos:
        </Typography>
        <Button
          sx={{ mt: 3 }}
          onClick={() => navigate(-1)}
          caption="Voltar"
          fullWidth
        />
      </Box>
    </Box>
  );
}
