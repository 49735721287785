import React from 'react';
import useLogin from '../hooks/useLogin';
import { IReportOrdersPerDay, IFiltersDashboard } from '../models';
import { formatDateInfo } from '../utils';
import Header from './Components/Header';
import { Container } from './styles';

type Props = {
  componentToPrint: React.MutableRefObject<null>;
  data: IReportOrdersPerDay[];
  filters: IFiltersDashboard;
  datePrint: Date;
};

export default function ReportOrdersPerDay(props: Props) {
  const { userSelector } = useLogin();
  const { componentToPrint, data, datePrint } = props;

  const countX = data.reduce((accu, item) => {
    return accu + item.count;
  }, 0);

  const productValue = data.reduce((accu, item) => {
    return accu + item.product_value;
  }, 0);

  const deliveryFeeValue = data.reduce((accu, item) => {
    return accu + item.delivery_fee_value;
  }, 0);

  const discountValue = data.reduce((accu, item) => {
    return accu + item.discount_value;
  }, 0);

  const orderValue = data.reduce((accu, item) => {
    return accu + item.order_value;
  }, 0);

  const pmMoneyValue = data.reduce((accu, item) => {
    return accu + item.pm_money_value;
  }, 0);

  const pmPixValue = data.reduce((accu, item) => {
    return accu + item.pm_pix_value;
  }, 0);

  const pmCreditCardValue = data.reduce((accu, item) => {
    return accu + item.pm_credit_card_value;
  }, 0);

  const pmDebitCardValue = data.reduce((accu, item) => {
    return accu + item.pm_debit_card_value;
  }, 0);

  const pmMealTicketValue = data.reduce((accu, item) => {
    return accu + item.pm_meal_ticket_value;
  }, 0);

  const pmOtherValue = data.reduce((accu, item) => {
    return accu + item.pm_other_value;
  }, 0);

  const formatValue = (value: string) => {
    return parseFloat(value).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const { filters } = props;

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-return-assign
    <Container ref={el => (componentToPrint.current = el)}>
      <div className="card-print">
        <Header
          title="Pedidos por Dia"
          filters={filters}
          fantasyName={userSelector.affiliate?.fantasy_name}
          datePrint={datePrint}
        />

        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="column-initial">
                Data
              </th>
              <th scope="col" className="column-left">
                Qtde
              </th>
              <th scope="col" className="column-left">
                Prod.
              </th>
              <th scope="col" className="column-left">
                Entrega
              </th>
              <th scope="col" className="column-left">
                Desc.
              </th>
              <th scope="col" className="column-left">
                Pedidos
              </th>
              <th scope="col" className="column-left">
                Dinheiro
              </th>
              <th scope="col" className="column-left">
                Pix
              </th>
              <th scope="col" className="column-left">
                Crédito
              </th>
              <th scope="col" className="column-left">
                Débito
              </th>
              <th scope="col" className="column-left">
                Ref.
              </th>
              <th scope="col" className="column-left">
                Outros
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="line">
                <td className="column-initial">
                  {formatDateInfo(item.date.toString(), 'DD/MM/YY')}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.count.toString())}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.product_value.toString())}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.delivery_fee_value.toString())}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.discount_value.toString())}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.order_value.toString())}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.pm_money_value.toString())}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.pm_pix_value.toString())}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.pm_credit_card_value.toString())}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.pm_debit_card_value.toString())}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.pm_meal_ticket_value.toString())}
                </td>
                <td className="column-left" style={{ textAlign: 'right' }}>
                  {formatValue(item.pm_other_value.toString())}
                </td>
              </tr>
            ))}

            <tr style={{ fontWeight: 'bold' }} className="line">
              <td className="column-initial">Total</td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(countX.toString())}
              </td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(productValue.toString())}
              </td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(deliveryFeeValue.toString())}
              </td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(discountValue.toString())}
              </td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(orderValue.toString())}
              </td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(pmMoneyValue.toString())}
              </td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(pmPixValue.toString())}
              </td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(pmCreditCardValue.toString())}
              </td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(pmDebitCardValue.toString())}
              </td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(pmMealTicketValue.toString())}
              </td>
              <td className="column-left" style={{ textAlign: 'right' }}>
                {formatValue(pmOtherValue.toString())}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
  );
}
