import React from 'react';
import { IFiltersDashboard } from '../../models';
import { formatDateInfo, formatDatetime } from '../../utils';

interface Props {
  title: string;
  filters: IFiltersDashboard;
  fantasyName: string;
  datePrint: Date;
}

export default function Header({
  title,
  filters,
  fantasyName,
  datePrint,
}: Props) {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src="app-logo.webp" alt="Mercurius" width={170} height={50} />
      </div>

      <div className="title">{title}</div>

      <b>
        {filters.affiliate !== undefined ? (
          <div className="sub-title">Filial - {fantasyName}</div>
        ) : (
          <div className="sub-title">{fantasyName}</div>
        )}

        <div className="sub-title">
          {formatDateInfo(filters.dateInitial.toString(), 'DD/MM/YYYY')} à{' '}
          {formatDateInfo(filters.dateFinal.toString(), 'DD/MM/YYYY')}
        </div>

        <div className="sub-title-2">{formatDatetime(datePrint)}</div>
      </b>

      <br />
    </>
  );
}
