import { useQuery, QueryOptions } from 'react-query';
import { ICustomerAddress } from '../../models';
import api from '../api';
import { queryClient } from '../queryClient';

export async function getCustomerAddresses(
  customerId: string
): Promise<ICustomerAddress[]> {
  const { data } = await api.get<ICustomerAddress[]>(
    `customer/adresses/all?customer_id=${customerId}`
  );

  return data;
}

export function useCustomerAddresses(
  customerId: string,
  options?: QueryOptions<ICustomerAddress[]>
) {
  return useQuery(
    ['customer_adresses'],
    () => getCustomerAddresses(customerId),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      ...options,
    }
  );
}

export async function fetchCustomerAddresses(customerId: string) {
  return queryClient.fetchQuery(['customer_adresses'], () =>
    getCustomerAddresses(customerId)
  );
}
