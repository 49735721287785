import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
`;

export const Title = styled.h2`
  margin-bottom: 10;
`;
export const Progress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10;
  padding: 30;
`;
