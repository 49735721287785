/* eslint-disable prettier/prettier */
import React from 'react';
import { alpha, styled, Theme } from '@mui/material/styles';

interface Props {
  children: React.ReactNode;
  color:
  | 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'white';
  variant: 'filled' | 'outlined' | 'ghost';
}

type colorType = 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'white';

type variantType = 'filled' | 'outlined' | 'ghost';

interface PropsStyle {
  color: colorType;
  variant: variantType;
}

const RootStyle = styled('span')<{ theme: Theme; ownerState: PropsStyle }>(({ theme, ownerState }) => {
  const { color, variant } = ownerState;

  const getColor = (colorInfo: colorType) => {
    if (colorInfo === 'secondary') {
      return theme.palette.secondary;
    } if (colorInfo === 'error') {
      return theme.palette.error;
    } if (colorInfo === 'info') {
      return theme.palette.info;
    } if (colorInfo === 'success') {
      return theme.palette.success;
    } if (colorInfo === 'warning') {
      return theme.palette.warning;
    } if (colorInfo === 'white') {
      return {
        lighter: '#f2f2f2',
        light: '#cccccc',
        main: '#FFFFFF',
        dark: '#808080',
        darker: '#4d4d4d',
        contrastText: '#fff',
      }
    }

    return theme.palette.primary;
  };

  const styleFilled = (colorInfo: colorType) => ({
    color: getColor(colorInfo).contrastText,
    backgroundColor: getColor(colorInfo).main,
  });

  const styleOutlined = (colorInfo: colorType) => ({
    color: getColor(colorInfo).main,
    backgroundColor: 'transparent',
    border: `1px solid ${getColor(colorInfo).main}`,
  });

  const styleGhost = (colorInfo: colorType) => ({
    color: getColor(colorInfo).dark,
    backgroundColor: alpha(getColor(colorInfo).main, 0.16),
  });

  return {
    height: 22,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 8,
    cursor: 'default',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey[300],
    fontWeight: theme.typography.fontWeightBold,

    ...(color !== 'default'
      ? {
        ...(variant === 'filled' && { ...styleFilled(color) }),
        ...(variant === 'outlined' && { ...styleOutlined(color) }),
        ...(variant === 'ghost' && { ...styleGhost(color) }),
      }
      : {
        ...(variant === 'outlined' && {
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
          border: `1px solid ${alpha('#919EAB', 0.32)}`,
        }),
        ...(variant === 'ghost' && {
          color: theme.palette.text.secondary,
          backgroundColor: alpha('#919EAB', 0.16),
        }),
      }),
  };
});

export default function Label({
  color = 'default',
  variant = 'ghost',
  children,
  ...other
}: Props) {
  return (
    <RootStyle ownerState={{ color, variant }} {...other}>
      {children}
    </RootStyle>
  );
}
