import React, { useCallback, useEffect } from 'react';
import { Box, CircularProgress, CssBaseline } from '@mui/material';
import AppMenu from '../AppMenu';
import useLogin from '../../hooks/useLogin';
import { Container, Title } from './styles';
import InfoCompany from '../InfoCompany';
import { useView } from '../../hooks/useView';

interface Props {
  title?: string;
  hideInfoCompany?: boolean;
  children: React.ReactNode;
}

export default function Page({ title, hideInfoCompany, children }: Props) {
  const { userSelector, onGetUserInfo } = useLogin();
  const views = useView();

  const onGetUser = useCallback(async () => {
    await onGetUserInfo();
  }, [onGetUserInfo]);

  useEffect(() => {
    onGetUser();
  }, [onGetUser]);

  return (
    <Container>
      <CssBaseline />
      <AppMenu views={views}>
        {userSelector.isFetching ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              minHeight: '80vh',
            }}
          >
            <img src="app-logo.webp" alt="Mercurius" width="200" />
            <CircularProgress color="primary" sx={{ marginTop: 5 }} />
          </Box>
        ) : (
          <>
            {!hideInfoCompany && <InfoCompany />}
            {title && <Title>{title}</Title>}
            {children}
          </>
        )}
      </AppMenu>
    </Container>
  );
}

Page.defaultProps = {
  title: '',
  hideInfoCompany: false,
};
