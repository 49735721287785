/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { Theme } from '@mui/material/styles';
import { Collapse } from '@mui/material';
import { Provider } from 'react-redux';
import { SnackbarProvider, SnackbarKey } from 'notistack';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import RoutesX from './routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import store from './store/store';
import CloseButton from './components/CloseButton';
import { SnackbarUtilsConfigurator } from './components/SnackbarUtilsConfigurator';
import { queryClient } from './services/queryClient';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme { }
}

function Closed(key: SnackbarKey): React.ReactElement {
  return <CloseButton keySnack={key} />;
}

function App(): React.ReactElement {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            action={key => Closed(key)}
            TransitionComponent={Collapse}
            preventDuplicate
          >
            <SnackbarUtilsConfigurator />
            <GlobalStyles />
            <RoutesX />
          </SnackbarProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ThemeConfig>
    </Provider>
  );
}

export default App;
