import React, { useCallback } from 'react';
import moment from 'moment';
import { IGroupComplements, IOrder, IOrderItem } from '../../models';
import {
  formatCurrency,
  formatDate,
  formatDateInfo,
  formatDatetime,
  formatHour,
  isBirthday,
  maskCEP,
  maskPhone,
} from '../../utils';
import { Container } from './styles';

type Props = {
  componentToPrint: React.MutableRefObject<null>;
  data: IOrder;
  affiliateInfo: string;
  smallFont: boolean;
};

export default function Order(props: Props) {
  const { componentToPrint, data, affiliateInfo, smallFont } = props;

  const getGroupComplements = useCallback((items: IOrderItem[], id: string) => {
    const group: IGroupComplements[] = [];

    if (items) {
      items
        .filter(i => i.complements && i.complements.length > 0 && i.id === id)
        .forEach(x => {
          if (x.complements) {
            x.complements.forEach(c => {
              if (group.find(g => g.id === c.complement_id) === undefined) {
                group.push({
                  id: c.complement_id,
                  description: c.complement_description,
                });
              }
            });
          }
        });
    }

    return group;
  }, []);

  const returnTime = () => {
    const timeFinal = moment(data?.created_at)
      .add(data.time_final, 'minutes')
      .toDate();

    if (
      formatDate(data?.created_at?.toString()) ===
      formatDate(timeFinal.toString())
    ) {
      return formatHour(timeFinal);
    }

    return formatDatetime(timeFinal);
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-return-assign
    <Container ref={el => (componentToPrint.current = el)}>
      <div>
        <div className="page">
          <div className={smallFont ? 'title-small' : 'title'}>
            Mercurius Plataforma de Delivery
          </div>

          <div className="info">
            <div
              className={smallFont ? 'title-small' : 'title'}
            >{`PEDIDO: ${data.number}`}</div>
          </div>

          {!smallFont && <br />}

          <b>
            <div
              className={smallFont ? 'title-small' : 'title'}
              style={{ marginTop: smallFont ? 8 : 0 }}
            >
              {affiliateInfo}
            </div>
          </b>

          <div
            className={smallFont ? 'title-small-nb' : 'title'}
          >{`Data: ${formatDateInfo(
            data.created_at?.toString(),
            'DD/MM/YYYY HH:mm'
          )}`}</div>
          <div className={smallFont ? 'title-small-nb' : 'title'}>
            {data.delivery_way === 'delivery' ? 'Entrega' : 'Retirada'}
          </div>
          <div
            className={smallFont ? 'title-small-nb' : 'title'}
          >{`Previsão: ${returnTime()}`}</div>

          {!smallFont && <br />}

          <b>
            {isBirthday(data.customer_date_birth) && (
              <div
                className={smallFont ? 'text-info-small' : 'text-info'}
                style={{ marginTop: smallFont ? 8 : 0 }}
              >
                É aniversário do seu cliente!
              </div>
            )}

            {data.first_order && (
              <div
                className={smallFont ? 'text-info-small' : 'text-info'}
                style={{ marginTop: smallFont ? 8 : 0 }}
              >
                Primeiro pedido da sua loja!
              </div>
            )}

            <div className={smallFont ? 'text-small' : 'text'}>Cliente</div>
            <div className={smallFont ? 'text-small' : 'text'}>
              {data.customer_name}
            </div>
          </b>

          <div className={smallFont ? 'text-small' : 'text'}>
            {maskPhone(data.customer_cellphone)}
          </div>
          {data.delivery_way === 'delivery' && (
            <>
              <div className={smallFont ? 'text-small' : 'text'}>
                {`${data.address_street}, ${data.address_number}`}
              </div>
              <div className={smallFont ? 'text-small' : 'text'}>
                {data.neighborhood_name}
              </div>
              <div className={smallFont ? 'text-small' : 'text'}>
                {data.city_name}-{data.city_state}
              </div>
              <div className={smallFont ? 'text-small' : 'text'}>
                CEP: {maskCEP(data.address_zip_code)}
              </div>
              {data.address_complement && (
                <div className={smallFont ? 'text-small' : 'text'}>
                  Complemento: {data.address_complement}
                </div>
              )}
              {data.address_reference_point && (
                <div className={smallFont ? 'text-small' : 'text'}>
                  Referência: {data.address_reference_point}
                </div>
              )}
            </>
          )}

          {!smallFont && <br />}

          <b>
            <div
              className={smallFont ? 'text-small' : 'text'}
              style={{ marginTop: smallFont ? 8 : 0 }}
            >
              Itens do Pedido
            </div>
          </b>

          <table>
            {data.items?.map(item => (
              <tbody key={item.id}>
                <tr>
                  <td
                    className={smallFont ? 'text-item-small' : 'text-item'}
                  >{`${item.quantity}x ${item.product_name} `}</td>
                  <td className={smallFont ? 'text-rigth-small' : 'text-rigth'}>
                    {formatCurrency(item.gross_value)}
                  </td>
                </tr>

                {getGroupComplements(data.items, item.id || '')?.map(g => (
                  <>
                    <tr>
                      <td
                        className={smallFont ? 'sub-item-small' : 'sub-item'}
                        colSpan={2}
                      >
                        {g.description}
                      </td>
                    </tr>
                    {item.complements &&
                      item.complements
                        .filter(y => y.complement_id === g.id)
                        ?.map(comp => (
                          <tr>
                            <td
                              className={smallFont ? 'item-small' : 'item'}
                              colSpan={2}
                            >{`${comp.quantity}x ${comp.product_name} `}</td>
                          </tr>
                        ))}
                  </>
                ))}
                {item.observation && (
                  <tr>
                    <td
                      className={smallFont ? 'sub-item-small' : 'sub-item'}
                    >{`Observação: ${item.observation}`}</td>
                  </tr>
                )}
                <tr>
                  <td className="print-hr" colSpan={2} />
                </tr>
              </tbody>
            ))}
          </table>

          {!smallFont && <br />}

          <b>
            <div
              className={smallFont ? 'line-small' : 'line'}
              style={{ marginTop: smallFont ? 8 : 0 }}
            >
              Valor dos Produtos:
              <span className="value">
                {formatCurrency(data.product_value)}
              </span>
            </div>
          </b>

          {data.discount_value > 0 && (
            <div className={smallFont ? 'line-small' : 'line'}>
              Desconto:
              <span className="value">
                -{formatCurrency(data.discount_value)}
              </span>
            </div>
          )}

          {data.cashback_rescue_value > 0 && (
            <div className={smallFont ? 'line-small' : 'line'}>
              Resgate Cashback:
              <span className="value">
                -{formatCurrency(data.cashback_rescue_value)}
              </span>
            </div>
          )}

          {data.delivery_way === 'delivery' && (
            <div className={smallFont ? 'line-small' : 'line'}>
              Taxa de Entrega:
              <span className="value">
                {data.delivery_fee_value > 0
                  ? formatCurrency(data.delivery_fee_value)
                  : 'Grátis'}
              </span>
            </div>
          )}

          <b>
            <div className={smallFont ? 'line-small' : 'line'}>
              Cobrar do Cliente:
              <span className="value">{formatCurrency(data.order_value)}</span>
            </div>
          </b>

          {!smallFont && <br />}

          <b>
            <div
              className={smallFont ? 'text-sub-small' : 'text-sub'}
              style={{ marginTop: smallFont ? 8 : 0 }}
            >
              Forma de Pagamento
            </div>
          </b>

          <div className={smallFont ? 'text-small' : 'text'}>
            {data.payment_description}
          </div>

          {data.delivery_observation !== null && (
            <>
              {!smallFont && <br />}

              <div
                className={smallFont ? 'text-small' : 'text'}
                style={{ marginTop: smallFont ? 8 : 0 }}
              >
                <b>Observação</b>
                <br />
                <span>{data.delivery_observation}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  );
}
