import styled from 'styled-components';

export const Container = styled.div`
  .card-print {
    font-family: Arial, Helvetica, sans-serif;
    width: 210mm;
    // min-height: 297mm;
    padding: 0;
    padding-left: 10mm;
    padding-right: 10mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .card-body {
    padding-top: 0;
  }

  img {
    max-width: 100%;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 5mm;
  }

  .sub-title {
    font-size: 14px;
    text-align: center;
  }

  .sub-title-2 {
    font-size: 12px;
    text-align: center;
  }

  hr {
    border-top: 1px solid #808080;
  }

  .table {
    border: 1px solid #919eab !important;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  tr > th {
    padding: 0.4rem;
    background: #919eab !important;
    color: #fff;
    border-top: 0;
    text-align: left;
  }

  tr > td {
    border-top: 0;
    padding: 0.15rem;
  }

  .header {
    border-top: 0;
    padding: 0.15rem;
  }

  .column-initial {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .column-left {
    border-left: 1px solid #919eab !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .line {
    border-top: 1px solid #919eab !important;
  }

  .card-body {
    flex-direction: column;
    display: flex;
  }

  .border-bottom {
    border-bottom: 1px solid #919eab !important;
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: end;
    flex: 1;
  }

  /* @page {
    size: A4;
    margin: 10mm 0 0 0;
  } */

  @media print {
    html,
    body {
      width: 210mm;
      // height: 297mm;
    }

    .card-print {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      // height: calc(50mm + 297mm);
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }
  }
`;
