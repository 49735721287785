/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { validatePhone } from 'validations-br';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import ActionsDataTable from '../../components/ActionsDataTable';
import DataTable from '../../components/DataTable';
import DialogForm from '../../components/DialogForm';
import { IAffiliate, IAffiliatePhones } from '../../models';
import useUtility from '../../hooks/useUtility';
import useData from '../../services/hooks/useData';
import TextField from '../../components/TextField';
import Button from '../../components/Button';
import { maskPhone } from '../../utils';
import Label from '../../components/Label';

const schema = yup
  .object()
  .shape({
    type: yup.string().required().nullable(),
    phone: yup
      .string()
      .required()
      .test('is-cellphone', 'Telefone inválido', value =>
        validatePhone(value || '')
      )
      .nullable(),
  })
  .required();

yup.setLocale(ptForm);
yup.setLocale({ mixed: { notType: 'Valor inválido.' } });

interface Props {
  affiliate: IAffiliate;
  open: boolean;
  close: () => void;
}

export default function Phones({ affiliate, open, close }: Props) {
  const { closeDialog, utilitySelector } = useUtility();
  const { data, getData, isLoading, isSubmitting, create, deleteObject } =
    useData<IAffiliatePhones>(
      'affiliate/phones',
      `?affiliate_id=${utilitySelector.dialogId}`,
      true
    );

  const dispatch = useDispatch();
  const { control, handleSubmit, reset } = useForm<IAffiliatePhones>({
    resolver: yupResolver(schema),
  });

  const [phones, setPhones] = useState<IAffiliatePhones[]>([]);

  const maskPhoneX = [
    {
      mask: '(00) 0000-0000',
      overwrite: true,
    },
    {
      mask: '(00) 00000-0000',
      overwrite: true,
    },
  ];

  function formatAffiliatePhones(
    affiliatePhone: IAffiliatePhones
  ): IAffiliatePhones {
    return {
      ...affiliatePhone,
      phone: maskPhone(affiliatePhone.phone || ''),
      type_description:
        affiliatePhone.type === 'order' ? 'Pedido' : 'Financeiro',
    };
  }

  useEffect(() => {
    if (open && utilitySelector.dialogId !== '') {
      getData();
    }
  }, [getData, open, utilitySelector.dialogId]);

  useEffect(() => {
    const formattedData: IAffiliatePhones[] = [];

    data.forEach(affiliatePhone => {
      formattedData.push(formatAffiliatePhones(affiliatePhone));
    });

    setPhones(formattedData);
  }, [data]);

  const handleClose = () => {
    reset({});
    close();
    dispatch(closeDialog());
  };

  const mapColumns = (affiliateCities: IAffiliatePhones): IAffiliatePhones => {
    const result: IAffiliatePhones = {
      affiliate_id: utilitySelector.dialogId,
      type: affiliateCities.type,
      phone: affiliateCities.phone?.replace(/\D+/g, '') || null,
    };

    return result;
  };

  const onSubmit = async (formData: IAffiliatePhones) => {
    await create(mapColumns(formData)).then(() => {
      reset({});
    });
  };

  const deletePhones = async (affiliatePhones: IAffiliatePhones) => {
    if (affiliatePhones.id) {
      await deleteObject(
        affiliatePhones.id,
        `?affiliate_id=${utilitySelector.dialogId}`
      );
    }
  };

  const actions = ActionsDataTable(
    false,
    phones,
    deletePhones,
    undefined,
    true
  );

  const typePhone = (value: string) => {
    return (
      <Label
        variant="ghost"
        color={(value === 'Pedido' && 'warning') || 'info'}
      >
        {value}
      </Label>
    );
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'phone',
      label: 'Celular',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'type_description',
      label: 'Tipo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => typePhone(value),
      },
    },
  ];

  if (actions) {
    columns.push(actions);
  }

  return (
    <DialogForm
      titleDiag={affiliate?.fantasy_name || 'Filial'}
      open={open}
      maxForm="lg"
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      handleClose={handleClose}
      cancelText="Fechar"
      hideSubmit
      onSubmit={handleSubmit(onSubmit)}
    >
      <div style={{ marginBottom: 15 }}>
        <Grid container spacing={2}>
          <Grid item md={7} xs={12}>
            <TextField
              id="phone"
              name="phone"
              control={control}
              defaultValue=""
              variant="standard"
              mask={maskPhoneX}
              fullWidth
              label="Telefone"
              required
              autoFocus
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl variant="standard" sx={{ mt: 2 }} fullWidth>
              <InputLabel htmlFor="type-required" shrink>
                Tipo
              </InputLabel>
              <Controller
                name="type"
                control={control}
                defaultValue="order"
                render={({ field }) => (
                  <Select
                    {...field}
                    id="type"
                    variant="standard"
                    color="primary"
                    label="Tipo"
                    required
                    disabled={isSubmitting}
                    value={field.value || 'order'}
                  >
                    <MenuItem value="order">
                      <Typography>Pedido</Typography>
                    </MenuItem>
                    <MenuItem value="finance">
                      <Typography>Financeiro</Typography>
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              id="submit"
              sx={{ mt: 3.6 }}
              caption="Adicionar"
              type="submit"
              fullWidth
              loading={isSubmitting}
            />
          </Grid>
        </Grid>
        <DataTable
          title="Telefones"
          data={phones}
          columns={columns}
          isLoading={isLoading}
          refresh={getData}
          hideActionAddToolbar
        />
      </div>
    </DialogForm>
  );
}
